export default {
  mini: true,
  field_log: false,
  field: "",
  work_sources: [],
  request_sources: [],
  works_list: [],
  request_class: [],
  rejection_list: [],
  selected_products: [],
  customer_needs: [],
  objections_challenges: [],
  hidden_baskets_for_user : [],
  obstacles: [],
  id_of_mortgage_settings:null,
  open_edit_dialog_mortgage_settings:false,
  salary_sources: [],
  cities: [],
  projects: [],
  app_products: [],
  bcItems: [],
  funding_sources: [],
  madany_works: [],
  request_info: {},
  quality_request_info: {},
  military_ranks: [],
  military_works: [],
  request_statuses: [],
  agent_customers: [],
  search_result: [],
  quality_search: false,
  search_model: false,
  search_rate: false,
  search_rate_result: [],
  selected_customers: [],
  search_result_visibility: false,
  search_rate_result_visibility: false,
  user: "",
  cardsStyle: true,
  elements: [],
  fieldCount: 0,
  navbarUnreadCountNum: 0,
  navbarRooms: [],
  redirect_count_of_rates:3,
  showAlertOfRatingDidntProccess:false,
  showAlertOfTasksDidntProccess:false,
  newSupport: null,
  pageNumber: 1,
  supportCount: 0,
  recievedCount: 0,
  received_tasks_count: 0,
  not_processed_tasks_count: 0,
  first_not_processed_task_id: null,
  recieved_tasks_notifications_count: 0,
  rates_notifications_count: 0,
  rates_in_admin_count: 0,
  latestCount: 0,
  activeSnack:false,
  showCards: true,
  search_admin:false,
  openDialog: false,
  openAdministrationDialog: false,
  administrationRequestId:null,
  openCallsDialog: false,
  openOneCallDialog: false,
  openOneTaskContent: false,
  idOfRequestCalls : null,
  idOfOneCall : null,
  idOfOneTaskContent:null,
  turnuserDialog:false,
  TransferFilterDialog:false,
  turnToQualityDialog: false,
  allRequests: [],
  tableData: [],
  isSearch: false,
  bodySearch: {},
  loadingData: false,
  back: false,
  loggedIn: false,
  salesmanager_agents: [],
  salesmanager_agents_search: [],
  startElement: [],
  needToAddNewFilter: false,
  InfinityPageNumber: 1,
  requestId: null,
  searchedCount: null,
  openDialogMargeUsers: false,
  pagination: {},
  allQualityUser: [],
  dialogQuality: false,
  AgentSendPaymentDialog: false,
  SMSendMorPurDialog: false,
  SMRejectMorPurDialog: false,
  SMSendPaymentDialog: false,
  SMRejectPaymentDialog: false,
  QualityQuestionsDialog: false,
  QualityQuestionsDialogReqID: 0,
  freezingSearch: false,
  url: 'https://api.alwsatarealestate.com/api',
  allStatusAgent: [],
  selectId: null,
  freezeActionIsDone:true,
  waitingNewFilter: false,
  openConditionDialog: false,
  refuseDialog: false,
  refusedId: null,
  NeedToMoveQualityNewSearch: false,
  chatStatus: "all",
  opened: null,
  quality_classifications: [],
  quality_ticket_statuses: [],
  details_active_room: {},
  details_active_room_id: '',
  orderRequestDialog: false,
  orderRequestId: null,
  openOrderRequestSection: false,

  freezeData: [],
  freezeSearch: [],
  freezeLoading: false,
  latestData: [],
  latestSearch: [],
  latestLoading: false,
  allSearch: [],
  allLoading: false,
  allData: [],
  recievedSearch: [],
  recievedLoading: false,
  recievedData: [],
  followSearch: [],
  followLoading: false,
  followData: [],
  featureSearch: [],
  featureLoading: false,
  featureData: [],
  archiveData: [],
  archiveSearch: [],
  archiveLoading: false,
  needNewData: [],
  needNewSearch: [],
  needNewLoading: false,
  waitingNewData: [],
  waitingNewSearch: [],
  waitingNewLoading: false,
  needTurnNewData: [],
  needTurnNewSearch: [],
  needTurnNewLoading: false,
  FilterRateDialog: false,
  ValidationAsDialog: false,
  ValidationAsDialogMessage: '',
  ratesInAdmin: [],
  tasksRequestId: null,
  openTasksRequestSection: false,
  tasksDetailsRequestId: null,
  openTasksDetailsRequestSection: false,
  openTiket: false,
  openAddTaskForRequest : false,
  qualityObjFilter: {},
  counterOfShow :10,
  SMRejectRequestDialog : false,
  userProfileData: {},
  custodies: [],
  isProfile: null,
  employeeFiles: [],
  archivedEmployeeFiles: [],
  userPdf: '',
  views: 'cards',
  admin_search_active: false,
  dialogOfRateNotes : false,
  idOfRateInSalesMAnager : null,
  TaskDetailsDialog:false,
  show_agent_target_dialog : false,
  show_agent_target_dialog_id : '',
  show_agent_target_dialog_count : '',
};
