<template>
  <v-row>
    <div style="width: 70%; display: flex" class="filter">
      <v-col cols="12" sm="3" md="3" style="margin-top:  -5px!important;padding-top: 0 !important;">
        <PickerInputWithOutIcon ref="targetStartDate" label="من تاريخ" @dateValue="targetStartDate($event)" />
      </v-col>

      <v-col cols="12" sm="3" md="3" style="margin-top: -5px!important;padding-top: 0 !important;">
        <PickerInputWithOutIcon ref="targetEndDate" label="الي تاريخ" @dateValue="targetEndDate($event)" />
      </v-col>

      <div style="margin-top: 13px">
        <v-text-field
          label="الايام"
          v-model="filterObj.dayNumber"
          style="width: 80px; margin-right: 10px; height: 48px"
          outlined
          dense
        ></v-text-field>
      </div>

      <v-col cols="12" sm="4" md="4" style="background: #f3f5f7; margin-top: 0px">
        <SelectInput
          @selectedValues="getSelectedValueAdviser"
          :originalItems="agentNames"
          label="اسم الاستشاري"
        />
      </v-col>

      <v-col cols="12" sm="5" md="5">
        <div class="d-flex justify-space-between">
          <div class="d-flex">
            <v-autocomplete
              :items="allStatus"
              style="width: 140px; padding: 2px"
              label="الحالة"
              v-model="filterObj.status"
              outlined
              dense
            >
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-title
                    :id="attrs['aria-labelledby']"
                    v-text="item"
                  ></v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>

            <v-btn
              depressed
              color="primary"
              @click="handleFilter()"
              style="height: 48px; font-size: 16px; font-weight: 700; margin-right: 20px"
            >
              تطبيق
            </v-btn>
          </div>
          <v-btn
            depressed
            color="primary"
            style="min-width: 40px !importent; width: 45px; height: 48px"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
            >
              <path
                d="M20.5076 11.3992H18.5996V5.39922C18.5996 4.73922 18.0596 4.19922 17.3996 4.19922H12.5996C11.9396 4.19922 11.3996 4.73922 11.3996 5.39922V11.3992H9.49161C8.42361 11.3992 7.88361 12.6952 8.63961 13.4512L14.1476 18.9592C14.6156 19.4272 15.3716 19.4272 15.8396 18.9592L21.3476 13.4512C22.1036 12.6952 21.5756 11.3992 20.5076 11.3992ZM6.59961 23.3992C6.59961 24.0592 7.13961 24.5992 7.79961 24.5992H22.1996C22.8596 24.5992 23.3996 24.0592 23.3996 23.3992C23.3996 22.7392 22.8596 22.1992 22.1996 22.1992H7.79961C7.13961 22.1992 6.59961 22.7392 6.59961 23.3992Z"
                fill="white"
              />
            </svg>
          </v-btn>
        </div>
      </v-col>
    </div>
  </v-row>
</template>

<script>
import SelectInput from "@/components/Charts/SelectInput.vue";
import PickerInputWithOutIcon from "@/components/Charts/PickerInputWithOutIcon.vue";
import Axios from "axios";
export default {
  // components: { SearchIconBlack, SelectInput, PickerInput },
  components: { SelectInput, PickerInputWithOutIcon },
  props: ["role"],
  data() {
    return {
      today:
        new Date().getFullYear() +
        "-" +
        ("0" + (new Date().getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + new Date().getDate()).slice(-2),
      allStatus: ["الكل", "استشاري مؤرشف", "استشاري نشط"],
      selectedAgents: [],
      selectedManagers: [],
      managerNames: [],
      agentNames: [],
      selectedManager: [],
      isSalesManager: this.role == 1 ? true : false,
      mangers: [],
      agents: [],
      status: null,
      range: {},
      pickerActive: false,
      filterObj: {
        endDate: "",
        startDate: "",
        status: "استشاري نشط",
        range: "",
        dayNumber: "",
        managerId: [],
        agentId: [],
      },
    };
  },

  watch: {
    selectedManagers(val) {
      this.selectedManager = val;
      this.filterObj.managerId = val;
      this.getMangersAndAgents("agent", this.status);
    },

    selectedAgents(val) {
      this.filterObj.agentId = val;
    },

    "filterObj.status": function (newStatus, oldStatus) {
      this.status = newStatus;
      switch (newStatus) {
        case "استشاري نشط":
          this.getMangersAndAgents("manager", 1);
          this.getMangersAndAgents("agent", 1);
          break;
        case "استشاري مؤرشف":
          this.getMangersAndAgents("manager", 0);
          this.getMangersAndAgents("agent", 0);
          break;
        case "الكل":
          this.getMangersAndAgents("manager");
          this.getMangersAndAgents("agent");
          break;
        default:
          break;
      }
    },
  },

  methods: {
    handleRangeDate(val) {
      this.filterObj.startDate = val.startDate;
      this.filterObj.endDate = val.endDate;
    },
    togglePicker() {
      this.$refs.daterange.toggle();
    },
    handleFilter() {
      this.$emit("handleFilter", this.filterObj);
    },
    getSelectedValueManger(selectedValue) {
      this.selectedManagers = selectedValue;
    },

    getSelectedValueAdviser(selectedValue) {
      this.selectedAgents = selectedValue;
    },

    targetEndDate(endDate) {
      this.filterObj.endDate = endDate;
    },

    targetStartDate(startDate) {
      this.filterObj.startDate = startDate;
    },
    setDefaultValues() {
      this.filterObj.endDate = this.$route.name == "rateAverage" ? this.today : "";
      this.filterObj.startDate = this.$route.name == "rateAverage" ? this.today : "";
      // this.filterObj.status = this.$route.name == "rateAverage" ? "استشاري نشط" : "";
    },

    getMangersAndAgents(role, status = "") {
      this.agentNames = [];
      let body = new FormData();

      body.append("role", role);

      if (status !== "") {
        body.append("status", status);
      }

      if (this.selectedManagers.length || localStorage["id"]) {
        const managerProperties = {};
        this.selectedManagers.forEach((item, index) => {
          body.append([`manager_id[${index}]`], item.id);
        });
        if (this.isSalesManager)
          body.append(
            [`manager_id[${this.selectedManagers.length}]`],
            localStorage["id"]
          );
      }
      Axios.post(
        `https://api.alwsatarealestate.com/api/employee/admin/filterUsers`,
        body,
        { headers: this.request_headers() }
      ).then((res) => {
        if (role === "manager") {
          this.managerNames = res["data"]["payload"];
          this.managerNames.map((item) => {
            item["text"] = item["name"];
            item["color"] = "red";
            return item;
          });
        } else {
          this.agentNames = res["data"]["payload"];
          this.agentNames.map((item) => {
            item["text"] = item["name"];
            item["color"] = "red";
            return item;
          });
        }
      });
    },
  },

  created() {
    this.getMangersAndAgents("manager");
    this.setDefaultValues();
    this.getMangersAndAgents("agent", 1);
  },
};
</script>

<style>
.filter label {
  font-size: 16px;
}
.filter
  .selectInput
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  background: #f3f5f7;
  margin-top: 0px;
}

.filter .v-text-field--outlined.v-input--dense .v-label {
  font-size: 16px;
  font-weight: 700;
  color: black;
}

.filter .v-input__slot {
  height: 48px;
}

.filter .v-btn:not(.v-btn--round).v-size--default {
  min-width: 40px !important;
}

.filter .v-text-field.v-text-field--solo .v-label {
  font-size: 16px;
  font-weight: 700;
  color: black;
}

.filter .v-input:not(.file-inp) .v-input__icon {
  visibility: visible !important;
  left: 0px;
  color: black;
}

.filter
  .selectInput
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  padding-bottom: 17px !important;
}
.filter .v-text-field.v-text-field--solo .v-label {
  top: 10px;
}
</style>
