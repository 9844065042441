<template>
  <page-component
    style="position: relative"
    :loading="loading"
    class="ticket-details-page"
  >
    <template #content>
      <v-sheet>
        <v-card elevation="0">
          <v-card-title class="ma-5 d-flex align-center justify-center">
            <div>تفاصيل طلب الدعم</div>
            <v-badge
              color="success"
              v-if="status == 2"
              content="مكتملة"
              floating
            ></v-badge>
          </v-card-title>
        </v-card>
      </v-sheet>
      <v-timeline :dense="$vuetify.breakpoint.smAndDown">
        <span v-for="item in ticket_details" :key="item.id">
          <v-timeline-item v-if="item.position_of_message == 'left'" class="right-side">
            <span style="color: #666666" slot="opposite" class="d-block">{{
              item.created_at ? item.created_at.split(" ")[0] : ""
            }}</span>
            <span style="color: #666666" slot="opposite">{{
              item.created_at ? item.created_at.split(" ")[1] : ""
            }}</span>
            <v-card
              elevation="2"
              width="341px"
              class="cardo"
              style="
                margin-right: 13px;
                margin-left: auto !important;
                box-shadow: unset !important;
                background-color: #922595 !important;
                border-color: #992595 !important;
                border-radius: 5px !important;
              "
            >
              <v-card-title>
                <div style="color: #fff !important">{{ item.descrebtion }}</div>
              </v-card-title>
              <v-card-subtitle>
                <div style="color: #fff !important">
                  {{ item.user?.name ?? item.name }}
                </div>
              </v-card-subtitle>
            </v-card>
          </v-timeline-item>
          <v-timeline-item v-if="item.position_of_message == 'right'" class="left-side">
            <span style="color: #666666" slot="opposite" class="d-block">{{
              item.created_at ? item.created_at.split(" ")[0] : ""
            }}</span>
            <span style="color: #666666" slot="opposite">{{
              item.created_at ? item.created_at.split(" ")[1] : ""
            }}</span>
            <v-card
              elevation="2"
              width="341px"
              class="cardo"
              style="
                margin-left: 13px !important;
                margin-right: auto !important;
                box-shadow: unset !important;
                color: white !important;
                background-color: #f4b5ff !important;
                border-color: #ec62fb !important;
                border-radius: 5px !important;
              "
            >
              <v-card-title>
                <div style="color: black !important">
                  {{ item.descrebtion }}
                </div>
              </v-card-title>
              <v-card-subtitle>
                <div style="color: black !important">
                  {{ item.name }}
                </div>
              </v-card-subtitle>
            </v-card>
          </v-timeline-item>
        </span>
        <!-- <v-timeline-item fill-dot large icon="mdi-chevron-down-box" v-for="item in ticket_details" :key="item.id" :class="item.name == null ? 'right-side' : 'left-side' ">
            <strong slot="opposite">{{item.created_at}}</strong>
            <v-card elevation="2">
              <v-card-title>
                <div>{{item.descrebtion}}</div>
              </v-card-title>
              <v-card-subtitle>
                <div v-if="item.name != null">
                  {{item.name}}
                </div>
                <div v-else>
                  {{item.user.name}}
                </div>
              </v-card-subtitle>
            </v-card>
          </v-timeline-item> -->
      </v-timeline>
      <v-card elevation="0" v-if="showReplyTextbox">
        <v-card-title class="ma-5 d-flex align-center justify-center"
          >الرد</v-card-title
        >
        <v-textarea v-model="replay" class="mx-10 mb-5" outlined></v-textarea>
        <v-card-actions class="d-flex align-center justify-center"
          ><v-btn
            :loading="reply_loading"
            color="primary"
            @click="reply_ticket_content"
            class="font-weight-bold"
            >إرسال</v-btn
          ></v-card-actions
        >
      </v-card>

      <v-card elevation="0">
        <v-card-actions
          class="d-flex align-center justify-center"
          v-if="status == 2"
        >
          <v-btn style="background-color: #922595; color:white" @click="reOpenTicket" class="font-weight-bold my-5"
            >{{ changeContentOfButton() }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </template>
  </page-component>
</template>
  
  <script>
import Axios from "axios";
export default {
  props: ["agent"],
  watch: {
    status(nValue,oValue) {
      if(this.status === 2){
        this.showReplyTextbox = false;
      }else{
        this.showReplyTextbox = true;
      }
    },  
  },
  data() {
    return {
      ticket_details: [],
      loading: false,
      reply_loading: false,
      replay: "",
      complete_loading: false,
      incomplete_loading: false,
      close_loading: false,
      status: 0,
      showReplyTextbox: false,
    };
  },
  created() {
    this.get_ticket_details();
    this.setBreadCrumbs(["الرئيسية", "الدعم الفني", "تفاصيل طلب الدغم الفني"]);
    this.$store.state.opened = -1;
  },
  methods: {
    get_ticket_details() {
      this.loading = true;
      Axios.get(
        `https://api.alwsatarealestate.com/api/employee/collaborator/open-technical-support-request/${this.$route.params.id}?sort_by=created_at&sort_type=asc`,
        { headers: this.request_headers() }
      )
        .then((response) => {
          this.getTopBarData();
          this.ticket_details = response.data.payload.data;
          this.sent_counter = this.ticket_details.length;
          if (response.data.payload.ticket !== undefined) {
            this.status = response.data.payload.ticket.status;
          }
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reply_ticket_content() {
      let data = { replay: this.replay };
      this.reply_loading = true;
      Axios.post(
        `https://api.alwsatarealestate.com/api/employee/collaborator/replay-to-technical-support-request/${this.$route.params.id}`,
        data,
        { headers: this.request_headers() }
      )
        .then(() => {
          this.showPopup("success", "تم الرد بنجاح");
          this.replay = "";
          this.get_ticket_details();
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        })
        .finally(() => {
          this.reply_loading = false;
        });
    },
    async getTopBarData() {
      await Axios.get(
        "https://api.alwsatarealestate.com/api/agent/topBarCount",
        {
          headers: this.request_headers(),
        }
      )
        .then((res) => {
          res.data.message.forEach((el) => {
            if (el.technical_support_notifications_count) {
              this.$store.state.supportCount =
                el.technical_support_notifications_count;
              this.technical_support_notifications_count =
                el.technical_support_notifications_count;
            } else if (el.count_of_received_requests) {
              this.$store.state.recievedCount = el.count_of_received_requests;
              this.count_of_received_requests = el.count_of_received_requests;
            }
          });
        })
        .catch((err) => console.log(err));
    },

    reOpenTicket(){
      this.showReplyTextbox = !this.showReplyTextbox;
    },

    changeContentOfButton(){
      if(this.showReplyTextbox === true) {
        return ' الغاء فتح التذكرة';
      }else{
        return 'اعادة فتح التذكرة';
      }
    }
  },
};
</script>
  
<style lang="scss">
.ticket-details-page {
  .v-timeline-item__dot {
    width: 40px !important;
    height: 150px !important;
    border-radius: unset !important;
    clip-path: polygon(
      0% 0%,
      50% 10%,
      100% 0%,
      100% 85%,
      50% 100%,
      0% 85%,
      0% 0%
    ) !important;
    // background: red !important;
    .v-timeline-item__inner-dot {
      display: none !important;
    }
  }
  .cardo {
    margin-top: 20px !important;
  }
  .v-timeline-item.left-side {
    .v-timeline-item__dot {
      background: #f4b5ff !important;
      border: 1px solid #f4b5ff;
    }
    .cardo {
      &::before,
      &::after {
        transform: rotate(0);
        right: initial !important;
        border: none !important;
        width: 40px !important;
        height: 1px !important;
        background: #f4b5ff !important;
        left: -41px !important;
      }
    }
  }
  .v-timeline-item.right-side {
    .v-timeline-item__dot {
      background: #922595 !important;
      border: 1px solid #922595;
    }
    .cardo {
      //   margin-left: 13px;
      //   margin-right: auto !important;
      &::before,
      &::after {
        border: none !important;
        width: 40px !important;
        height: 1px !important;
        background: #922595 !important;
        left: -41px !important;
      }
    }
  }
}

@media (max-width: 960px) {
  .ticket-details-page {
    .cardo {
      padding-right: 10px;
      padding-left: 10px;
      padding-bottom: 10px;
    }
    .v-timeline-item.right-side {
      .cardo {
        // margin-right: unset !important;
        // margin-left: auto !important;
        // margin-right: 13px !important;
        margin-left: 0 !important;
        &::before,
        &::after {
          right: -41px !important;
        }
      }
    }
    .ticket-status-msg {
      margin-right: 0 !important;
    }
  }
}
@media (max-width: 500px) {
  .ticket-details-page {
    .eye-dad {
      .v-card {
        padding: 10px !important;
      }
    }
  }
}
/*
.right-side {
      flex-direction: row-reverse !important;
    }
  .right-side .v-timeline-item__opposite{
      text-align: left !important;
    }
    .left-side {
        flex-direction: row !important;
    }
    .left-side .v-timeline-item__opposite{
      text-align: right !important;
    }

    .left-side .v-timeline-item__body .cardo.v-card::after,
    .left-side .v-timeline-item__body .cardo.v-card::before{
      transform: rotate(180deg);
        right: initial !important;
        left: -42px !important;
    }
    .right-side .v-timeline-item__body .cardo.v-card::after,
    .right-side .v-timeline-item__body .cardo.v-card::before{
        right: -42px !important;
    }
*/
.right-side {
  flex-direction: row-reverse !important;
}
.right-side .v-timeline-item__opposite {
  text-align: left !important;
}
.left-side {
  flex-direction: row !important;
}
.left-side .v-timeline-item__opposite {
  text-align: right !important;
}

.right-side .v-timeline-item__body .cardo.v-card::after,
.right-side .v-timeline-item__body .cardo.v-card::before{
    right: -42px !important;
}
</style>
  