<template>
  <div>
    <ProcessGroup :selectedItems="selected_requests" type="moveAllPendingRequests" />
    <div v-if="!this.$store.state.followLoading" style="margin-top: 40px">
      <div v-if="$store.state.views === 'cards'">
        <div v-if="this.$store.state.followData.length > 0">
          <RequestsCard
            :CardData="this.$store.state.followData"
            @pageCount="handlePageNumber"
            @back="handleBack"
          />
          <div>
            <LoadingMore v-if="loadMoreData" style="margin: 40px" />
          </div>
        </div>
        <div class="noData" v-else>لا يوجد بيانات</div>
      </div>
      <div v-else-if="$store.state.views === 'table'">
        <table-component
          :showSelect="false"
          itemKey="id"
          :selectedRows.sync="selected_requests"
          :headers="headers"
          :pagination="this.$store.state.pagination"
          :limit="$store.state.counterOfShow"
          :items="this.$store.state.tableData"
          :loading="loading"
          @refresh="getTableData"
          v-model="page"
        >
          <template slot="item.quality_notes" slot-scope="props">
            <span v-if="!props.item.quality_notes"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.quality_notes.length > 10
                    ? props.item.quality_notes.substring(0, 10) + "..."
                    : props.item.quality_notes
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.quality_notes }}</div>
            </v-tooltip>
          </template>

          <template slot="item.agent_notes" slot-scope="props">
            <span v-if="!props.item.agent_notes"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.agent_notes.length > 10
                    ? props.item.agent_notes.substring(0, 10) + "..."
                    : props.item.agent_notes
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.agent_notes }}</div>
            </v-tooltip>
          </template>
        </table-component>
      </div>

      <div v-else-if="$store.state.views === 'default'">
        <div v-if="this.$store.state.followData.length > 0" style="margin-top: 40px">
          <DefaultView :CardData="this.$store.state.followData" />
          <div>
            <LoadingMore v-if="loadMoreData" style="margin: 40px" />
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
      "
      v-else
    >
      <LoadingMore />
    </div>
    <FilterDialogInAdmin pageName="followed" />
  </div>
</template>

<script>
import Axios from "axios";
import RequestsCard from "@/components/Charts/RequestsCard.vue";
import LoadingMore from "@/components/Charts/LoadingMore.vue";
import { formatDate } from "@/mixins/formatDate";
import { SourceValue } from "@/mixins/sourceValue";
import ProcessGroup from "@/views/admin/Requests/MoveToNew/ProcessGroup.vue";
import FilterDialogInAdmin from "@/components/Charts/FilterDialogInAdmin.vue";
import DefaultView from "@/components/Base/DefaultView.vue";

export default {
  name: "AdminSupport",
  components: {
    FilterDialogInAdmin,
    ProcessGroup,
    RequestsCard,
    LoadingMore,
    DefaultView,
  },

  data() {
    return {
      page: 1,
      loading: false,
      copySupportRequests: [],
      loadMoreData: false,
      loadingData: false,
      selected_requests: [],
      allGetData: [],
      allRequests: [],
      lodingToGetData: false,
      headers: [
        { text: "تاريخ الطلب", value: "تاريخ الطلب", width: "107px", align: "center" },
        { text: "نوع الطلب", value: "نوع الطلب", align: "center" },
        {
          text: "استشاري المبيعات",
          value: "استشاري المبيعات",
          align: "center",
        },
        { text: "عميل", value: "عميل", align: "center", width: "100px" },
        { text: "الجوال", value: "الجوال", align: "center" },
        {
          text: "مصدر المعامله",
          value: "مصدر المعامله",
          align: "center",
        },
        {
          text: "تصنيف الاستشاري",
          value: "تصنيف الاستشاري",
          width: "110px",
          align: "center",
        },
        { text: "الملاحظه", value: "agent_notes", align: "center" },
        {
          text: "ملاحظه الجوده",
          value: "quality_notes",
          align: "center",
        },
        {
          text: "التاريخ عند الاستشاري",
          value: "التاريخ عند الاستشاري",
          width: "110px",
          align: "center",
        },
        {
          text: "استلام الجودة",
          value: "هل تم استلامه من قبل الجوده",
          align: "center",
        },
        {
          text: "تم التحديث ",
          value: "تم التحديث في",
          width: "108px",
          align: "center",
        },
      ],
    };
  },

  watch: {
    page(val) {
      if (this.$store.state.isSearch === false) {
        this.getTableData(val);
      } else {
        this.searchRequest(val, "table");
      }
    },

    selected_requests(val) {
      this.selectedItems = val;
      this.$emit("requests", val);
    },

    "$store.state.counterOfShow": {
      handler(newval, oldvalue) {
        if (newval != oldvalue) {
          this.getTableData(this.page);
        }
      },
    },
  },

  methods: {
    handleBack() {
      this.$store.state.followData = [];
      this.$store.state.tableData = [];
      this.$store.state.followLoading = true;
      this.$store.state.isSearch = false;
      this.$store.state.back = false;
      this.getAllRequests(1, "back");
    },
    handlePageNumber(val) {
      if (this.$store.state.isSearch === false) {
        this.getAllRequests(val);
      } else {
        this.searchRequest(val);
      }
    },
    searchRequest(page, type) {
      this.loading = true;

      if (type === "table") {
        this.$store.state.tableData = [];
      }

      if (page > 1 || type === "table") {
        this.$store.state.bodySearch.delete("page");
        this.$store.state.bodySearch.append("page", page);

        let url = `${this.$store.state.url}/v2/employee/Requests/search?type=followed`;
        Axios.post(url, this.$store.state.bodySearch, {
          headers: this.request_headers(),
        }).then((res) => {
          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.searched,
          };
          let response = res.data.payload.data;
          response.map((item) => {
            if (item.length === 0) {
              this.loading = false;
            }
            this.setTbData(item);
            item["starChecked"] = false;
            return item;
          });

          this.$store.state.followSearch.push(...response);
          this.removeDublicate(this.$store.state.followSearch);
          this.loading = false;
        });
      }
    },

    getTableData(count) {
      this.$store.state.tableData = [];
      this.loading = true;
      Axios.get(
        `${this.$store.state.url}/v2/employee/Requests?type=followed&page=${count}&per_page=${this.$store.state.counterOfShow}`,
        { headers: this.request_headers() }
      ).then((res) => {
        res.data.payload.data.map((item) => {
          this.setTbData(item);
        });
        this.loading = false;
      });
    },

    setTbData(items) {
      this.$store.state.tableData.push({
        id: items.id,
        "تاريخ الطلب": items.req_date,
        "نوع الطلب": items.type,
        "استشاري المبيعات": items.user?.name,
        عميل: items.customer?.name,
        الجوال: items.customer?.mobile,
        "مصدر المعامله": SourceValue(items.source),
        "تصنيف الاستشاري": items.class_id_agent_text,
        agent_notes: items.agent_notes,
        quality_notes: items.quality_notes,
        "التاريخ عند الاستشاري": formatDate(items.agent_date),
        "هل تم استلامه من قبل الجوده": this.returnValue(items.highlight_received),
        "تم التحديث في": items.updated_at ? formatDate(items.updated_at) : "",
      });
    },

    returnValue(item) {
      if (!item) {
        return "لا";
      } else {
        return "نعم";
      }
    },

    getAllRequests(count) {
      if (
        this.$store.state.followData.length === 0 &&
        this.copySupportRequests.length === 0
      ) {
        this.$store.state.followLoading = true;
      }
      this.loadMoreData = true;
      Axios.get(
        `https://api.alwsatarealestate.com/api/v2/employee/Requests?type=followed&page=${count}&per_page=${this.$store.state.counterOfShow}`,
        { headers: this.request_headers() }
      )
        .then((res) => {
          let response = res.data.payload.data;
          response.map((item) => {
            this.setTbData(item);
            item["starChecked"] = false;
            return item;
          });

          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.count_of_followed,
          };
          this.allGetData.push(...response);
          this.removeDublicate(this.allGetData);
          this.copySupportRequests.push(...response);
        })
        .catch((err) => {
          this.loadingData = false;
          this.loadMoreData = false;
          this.$store.state.followLoading = false;
        })
        .finally(() => {
          this.loadMoreData = false;
          this.loadingData = false;
          this.$store.state.followLoading = false;
        });
    },

    removeDublicate(array) {
      let uniqueArray = array.filter((item, index) => {
        return (
          index ===
          array.findIndex((obj) => {
            return obj.id === item.id; // Compare based on the 'id' property
          })
        );
      });
      this.$store.state.followData = uniqueArray;
    },
  },

  created() {
    this.$store.state.followData = [];
    this.$store.state.tableData = [];
    this.$store.state.followLoading = true;
    this.$store.state.back = false;
    this.$store.state.bodySearch = new FormData();
    this.$store.state.isSearch = false;
    this.getAllRequests();
    this.$store.state.startElement = [];
  },
};
</script>

<style>
.noData {
  display: flex;
  justify-content: center;
  font-size: 22px;
  color: #3f0e40;
  margin-top: 70px;
  font-weight: bold;
}
</style>
