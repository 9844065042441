import axios from "axios";

const theCookie = document.cookie

  .split(";")
  .map((coki) => coki.split("="))
  .reduce(
    (acc, [key, val]) => ({
      ...acc,
      [key.trim()]: decodeURIComponent(val),
    }),
    {}
  )["wsataJwt"];
  let isAuthAdmin = 'false';
  let authAdminId = '';

  if (theCookie !== undefined && localStorage["role"] && localStorage["role"] == "7") {
    isAuthAdmin = 'true';
    authAdminId = JSON.parse(theCookie).agent_id;
  }

  let token = '';
  if(theCookie !== undefined){
    token = JSON.parse(theCookie).token;
  }

export const axiosInstance = axios.create({
  baseURL: "https://api.alwsatarealestate.com/api",
  headers: {
    "x-api-key":
        "WFKvB4Vjr8v5BVmxY7CDQ6ZMAU8DSHSBFKCMVpkfd5hhZyKTvnV3uh5XwxSwhnfeUbptZ7Z4XFJCR4vKXeHWGHTZ6djsYXjdAatf",
    // Authorization: `Bearer ${token}`,
    appplatform: "vue",
    "auth_admin": isAuthAdmin,
    "auth_admin_id": authAdminId,
  },
});

// Add an interceptor to set the Authorization header dynamically
axiosInstance.interceptors.request.use((config) => {
  const theCookie = document.cookie
      .split(";")
      .map((cookie) => cookie.split("="))
      .reduce(
          (acc, [key, val]) => ({
            ...acc,
            [key.trim()]: decodeURIComponent(val),
          }),
          {}
      )["wsataJwt"];

  let token = "";

  if (theCookie !== undefined) {
    const cookieData = JSON.parse(theCookie);
    if (cookieData.token) {
      token = cookieData.token;
    }
  }

  // Set the Authorization header only if the token is available
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

// Usage example:
// axiosInstance.get('/your-api-endpoint')