<template>
  <v-app style="background-color: #f3f5f7">
    <template>
      <nav-drawer
        v-if="$route.name != 'SignIn'"
        :support-count="technical_support_notifications_count"
      />
      <v-app-bar
        v-if="$route.name != 'SignIn'"
        style="position: absolute; top: 0"
        id="app_bar"
        app
        :color="$vuetify.theme.themes.light.color1"
        elevation="5"
        height="56"
      >
        <v-spacer class="top-spacer" />
        <!-- <div v-if="$route.name != 'SignIn'">back</div> -->

        <div v-if="searchActive && isAdminPage">
          <input
            v-model="phoneNum"
            @keyup.enter="handleFilter"
            class="nav__globalFilter"
            v-numericOnly
          />
        </div>
        <v-btn
          color="transparent"
          height="fit-content"
          depressed
          dark
          @click="handleToggleFilter"
          v-if="isAdminPage"
        >
          <div>
            <template>
              <v-icon>mdi-magnify</v-icon>
            </template>
          </div>
        </v-btn>

        <!-- back to admin account -->
        <v-btn
          v-if="userIsSwitched && currentRole != 7"
          color="transparent"
          @click="backToAdminAccount"
          class="the-refresh-btn ml-0 px-0"
          width="fit-content"
          height="fit-content"
          depressed
          dark
          :loading="loadingNav"
          title="الرجوع إلي حسابك"
        >
          <v-icon class="pr-2">mdi-exit-to-app</v-icon>
        </v-btn>

        <v-btn
          color="transparent"
          @click="refreshNav"
          class="the-refresh-btn ml-0 ml-sm-9 px-0"
          width="fit-content"
          height="fit-content"
          depressed
          dark
          :loading="loadingNav"
        >
          <!-- تحديث -->
          <v-icon class="pr-2">mdi-refresh</v-icon>
        </v-btn>
        <!--Reminders icon-->
        <template>
          <div class="text-center">
            <v-menu offset-y nudge-top="-85">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="transparent"
                  depressed
                  fab
                  dark
                  v-bind="attrs"
                  v-on="on"
                  width="fit-content"
                  height="fit-content"
                >
                  <v-badge
                    color="red"
                    class="ml-3 mb-1"
                    :content="tasks.Reminders_count"
                    v-if="tasks.Reminders_count > 0"
                    style="position: absolute; right: -10px"
                  ></v-badge>
                  <v-icon
                    style="font-size: 30px; padding: 3px; color: #fff"
                    title="التذكيرات"
                    >mdi-plus</v-icon
                  >
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  style="border-bottom: 2px solid #ededed"
                  v-for="(reminder, index) in tasks.Reminders"
                  :key="index"
                  :to="{
                    name: 'request_details',
                    params: { id: reminder.req_id },
                  }"
                >
                  <v-list-item-title>{{ reminder.value }}</v-list-item-title>
                </v-list-item>
                <v-btn
                  class="text-center"
                  router
                  block
                  depressed
                  color="transparent"
                  light
                  v-if="tasks.Reminders_count > 0"
                  :to="{ name: 'TheCalender' }"
                  >عرض المزيد ...
                </v-btn>
                <v-btn
                  class="text-center"
                  block
                  depressed
                  color="transparent"
                  light
                  v-else
                  >لا توجد طلبات جديدة
                </v-btn>
              </v-list>
            </v-menu>
          </div>
        </template>

        <!--Tasks icon-->
        <!-- <template>
          <div class="text-center">
            <v-menu offset-y nudge-top="-85">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="transparent"
                  depressed
                  fab
                  dark
                  v-bind="attrs"
                  v-on="on"
                  width="fit-content"
                  height="fit-content"
                  class="mr-0 mr-sm-5"
                >
                  <v-badge
                    color="red"
                    class="ml-3 mb-1"
                    :content="tickets.received_tasks_count"
                    v-if="tickets.received_tasks_count > 0"
                    style="position: absolute; right: -5px"
                  ></v-badge>
                  <img
                    style="width: 23px"
                    src="@/assets/icons/ticket.svg"
                    alt=""
                    title="التذاكر"
                  />
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  style="border-bottom: 2px solid #ededed"
                  v-for="(ticket, index) in tickets.received_tasks"
                  :key="index"
                  :to="{
                    name: 'ticket_details',
                    params: { ticket: ticket.id },
                  }"
                >
                  <v-list-item-title>يوجد مهمة جديدة</v-list-item-title>
                </v-list-item>
                <v-btn
                  class="text-center"
                  router
                  block
                  depressed
                  color="transparent"
                  v-if="tickets.received_tasks_count > 0"
                  light
                  :to="{ name: 'recieved_tickets' }"
                  >عرض المزيد ...
                </v-btn>
                <v-btn
                  class="text-center"
                  block
                  depressed
                  color="transparent"
                  v-else
                  light
                  >لا توجد تذاكر جديدة
                </v-btn>
              </v-list>
            </v-menu>
          </div>
        </template> -->

        <!--Notification icon-->
        <template>
          <div class="text-center">
            <v-menu offset-y nudge-top="-85">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="transparent"
                  depressed
                  fab
                  dark
                  v-bind="attrs"
                  v-on="on"
                  width="fit-content"
                  height="fit-content"
                  class="mr-0 mr-sm-5"
                >
                  <v-badge
                    color="red"
                    class="ml-3 mb-1"
                    :content="notifications.notifications_count"
                    style="position: absolute; right: -5px"
                    v-if="notifications.notifications_count > 0"
                  ></v-badge>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24.052"
                    height="24.052"
                    viewBox="0 0 20.552 21"
                  >
                    <g id="message_1_" data-name="message (1)" transform="translate(1 1)">
                      <g id="bell">
                        <line
                          id="Line_3"
                          data-name="Line 3"
                          x2="3"
                          transform="translate(8 19)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                        <path
                          id="Path_2642"
                          data-name="Path 2642"
                          d="M22.552,19.179H4a3.044,3.044,0,0,0,1.687-2.732V11.59A7.59,7.59,0,0,1,13.276,4h0a7.59,7.59,0,0,1,7.59,7.59v4.857a3.044,3.044,0,0,0,1.687,2.732Z"
                          transform="translate(-4 -4)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </g>
                    </g>
                  </svg>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  style="border-bottom: 2px solid #ededed"
                  v-for="(notif, index) in notifications.notifications"
                  :key="index"
                  :to="isVacationNotififcation(notif)"
                >
                  <v-list-item-icon
                    style="cursor: pointer"
                    @click="MarkNotificationAsRead(notif.id)"
                  >
                    <img style="width: 15px" src="/img/4.d72f4c49.svg" />
                  </v-list-item-icon>
                  <v-list-item-title v-if="notif.req_id == null">{{
                    notif.value
                  }}</v-list-item-title>
                  <v-list-item-title
                    style="cursor: pointer"
                    v-else
                    >{{ notif.value }}</v-list-item-title
                  >
                </v-list-item>
                <v-btn
                  class="text-center"
                  router
                  block
                  depressed
                  color="transparent"
                  v-if="notifications.notifications_count > 0"
                  light
                  :to="{ name: 'TheNotifc' }"
                  >عرض المزيد ...
                </v-btn>
                <v-btn
                  class="text-center"
                  block
                  depressed
                  color="transparent"
                  v-else
                  light
                  >لا توجد اشعارات جديدة
                </v-btn>
              </v-list>
            </v-menu>
          </div>
        </template>
        <!--End Notification icon-->

        <!--Messages icon-->
        <!-- <template>
          <div class="text-center">
            <v-menu offset-y nudge-top="-85">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="transparent"
                  depressed
                  fab
                  dark
                  v-bind="attrs"
                  v-on="on"
                  width="fit-content"
                  height="fit-content"
                  class="mr-0 mr-sm-5"
                >
                  <v-badge
                    color="red"
                    class="ml-3 mb-1"
                    :content="
                      $store.state.navbarUnreadCountNum < 99
                        ? $store.state.navbarUnreadCountNum
                        : '99+'
                    "
                    style="position: absolute; right: -5px"
                    v-if="$store.state.navbarUnreadCountNum > 0"
                  ></v-badge>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24.052"
                    height="24.052"
                    viewBox="0 0 21.052 21.052"
                  >
                    <g id="message_1_" data-name="message (1)" transform="translate(1 1)">
                      <path
                        id="message_1_2"
                        data-name="message (1)"
                        d="M19.191,1.5H2.861A1.361,1.361,0,0,0,1.5,2.861V20.552l4.536-4.536H19.191a1.361,1.361,0,0,0,1.361-1.361V2.861A1.361,1.361,0,0,0,19.191,1.5Z"
                        transform="translate(-1.5 -1.5)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                    </g>
                  </svg>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  style="border-bottom: 2px solid #ededed"
                  v-for="(room, index) in $store.state.navbarRooms"
                  :key="index"
                  @click="to_chat_page(room)"
                >
                  <v-list-item-title
                    >{{ (room.data?.UserName !== undefined)? String(room.data?.UserName).split(" ")[0] : '' }}:
                    {{
                      room.data.lastMessage === undefined ||
                      room.data.lastMessage.length < 10
                        ? room.data.lastMessage
                        : room.data.lastMessage?.split("").slice(0, 10).join("") +
                          " " +
                          "..."
                    }}</v-list-item-title
                  >
                </v-list-item>
                <v-btn
                  class="text-center"
                  router
                  block
                  depressed
                  color="transparent"
                  light
                  v-if="$store.state.navbarUnreadCountNum > 0"
                  @click="$router.push({ name: 'chat_component' })"
                  >عرض المزيد ...
                </v-btn>
                <v-btn
                  class="text-center"
                  block
                  depressed
                  color="transparent"
                  light
                  v-else
                  >لا توجد رسائل جديدة
                </v-btn>
              </v-list>
            </v-menu>
          </div>
        </template> -->

        <template>
          <div class="user-dropdown">
            <v-avatar class="ml-sm-4 mr-sm-9" size="40px">
              <img :src="user_avatar" alt="" />
            </v-avatar>

            <div class="dropdown">
              <button class="dropdown-toggle" @click="toggleDropdown">
                {{ loggedIn_user }}
                <i class="fas fa-caret-down" style="padding: 10px"></i>
              </button>

              <div v-if="isDropdownOpen" class="dropdown-content" v-click-outside="clickOutside">
                <v-list class="d-flex flex-row">
                  <v-list-item
                    title="بياناتي الشخصية"
                    @click="goToProfile"
                    style="border-bottom: 2px solid #ededed"
                  >
                    <v-list-item-icon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15.91"
                        height="17.773"
                        viewBox="0 0 15.91 17.773"
                      >
                        <g
                          id="Icon_feather-user"
                          data-name="Icon feather-user"
                          transform="translate(-5.5 -4)"
                        >
                          <path
                            id="Path_3967"
                            data-name="Path 3967"
                            d="M20.91,28.091V26.227A3.727,3.727,0,0,0,17.182,22.5H9.727A3.727,3.727,0,0,0,6,26.227v1.864"
                            transform="translate(0 -6.818)"
                            fill="none"
                            stroke="#00acf1"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                          />
                          <path
                            id="Path_3968"
                            data-name="Path 3968"
                            d="M19.455,8.227A3.727,3.727,0,1,1,15.727,4.5,3.727,3.727,0,0,1,19.455,8.227Z"
                            transform="translate(-2.273)"
                            fill="none"
                            stroke="#00acf1"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                          />
                        </g>
                      </svg>
                    </v-list-item-icon>
                    <v-list-item-title style="line-height: 2"
                      >بياناتي الشخصية</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item
                    title="التعميمات"
                    @click="goToAnouncements"
                    style="border-bottom: 2px solid #ededed"
                  >
                    <v-list-item-icon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.074"
                        height="16.947"
                        viewBox="0 0 17.074 16.947"
                      >
                        <g
                          id="_Group_"
                          data-name=" Group "
                          transform="translate(-38 -39.966)"
                        >
                          <path
                            id="_Compound_Path_"
                            data-name=" Compound Path "
                            d="M53.253,39.966a1.828,1.828,0,0,0-1.808,1.59c-.719.543-3.985,2.875-7.071,2.875H39.945A1.947,1.947,0,0,0,38,46.38v3.039a1.938,1.938,0,0,0,1.931,1.944h.26l1.27,4.144a1.94,1.94,0,1,0,3.729-1.069,13.121,13.121,0,0,1-.419-3.05c3.09.176,6.015,2.337,6.677,2.856a1.82,1.82,0,0,0,3.627-.233V41.793a1.824,1.824,0,0,0-1.821-1.827ZM43.991,50.58H40.9V45.215h3.094Zm-5.208-1.161V46.387a1.162,1.162,0,0,1,1.153-1.172h.178V50.58h-.17a1.154,1.154,0,0,1-1.162-1.145ZM43.7,56.037a1.148,1.148,0,0,1-.9-.059,1.16,1.16,0,0,1-.584-.7l-1.2-3.915h2.977a14.026,14.026,0,0,0,.446,3.275,1.167,1.167,0,0,1-.735,1.4ZM44.775,50.6V45.2a11.721,11.721,0,0,0,4.894-1.573,18.948,18.948,0,0,0,1.763-1.093V53.253a17.176,17.176,0,0,0-1.694-1.074A11.949,11.949,0,0,0,44.775,50.6Zm9.516,3.408a1.038,1.038,0,1,1-2.076,0V41.793a1.038,1.038,0,0,1,2.076,0Z"
                            transform="translate(0)"
                            fill="#00acf1"
                          />
                        </g>
                      </svg>
                    </v-list-item-icon>
                    <v-list-item-title style="line-height: 2"
                      >التعميمات</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item 
                    @click="logout"
                    style="border-bottom: 2px solid #ededed"
                    >
                    <v-list-item-icon title="تسجيل الخروج">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.074"
                        height="15.58"
                        viewBox="0 0 17.074 15.58"
                      >
                        <g id="logout" transform="translate(0 -22.397)">
                          <g
                            id="Group_1006"
                            data-name="Group 1006"
                            transform="translate(4.975 28.398)"
                          >
                            <g id="Group_1005" data-name="Group 1005">
                              <path
                                id="Path_3975"
                                data-name="Path 3975"
                                d="M161.082,203.738l-1.827-1.288a.5.5,0,0,0-.788.409v.788H149.7a.5.5,0,1,0,0,1h8.772v.788a.5.5,0,0,0,.788.409l1.827-1.288A.5.5,0,0,0,161.082,203.738Z"
                                transform="translate(-149.195 -202.358)"
                                fill="#00acf1"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_1008"
                            data-name="Group 1008"
                            transform="translate(0 22.397)"
                          >
                            <g
                              id="Group_1007"
                              data-name="Group 1007"
                              transform="translate(0 0)"
                            >
                              <path
                                id="Path_3976"
                                data-name="Path 3976"
                                d="M14.355,33.4a.5.5,0,0,0-.683.183,6.79,6.79,0,1,1,0-6.789.5.5,0,1,0,.866-.5,7.79,7.79,0,1,0,0,7.791A.5.5,0,0,0,14.355,33.4Z"
                                transform="translate(0 -22.397)"
                                fill="#00acf1"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </v-list-item-icon>
                    <v-list-item-title style="line-height: 2"
                      >تسجيل الخروج</v-list-item-title
                    >
                  </v-list-item>
                </v-list>

                <v-row v-if="currentRole == 7">
                  <v-col cols="12" sm="12" md="12">
                    <SelectInput
                      ref="selectInput7"
                      @selectedValues="getSelectedValueUser"
                      :originalItems="getUsersName"
                      label="تسجيل دخول بأحد المستخدمين"
                      :multiple="false"
                      style="padding: 10px !important;"
                    />
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </template>

        <!-- <v-menu offset-y nudge-top="-74" class="nav-avatar-menu">
          <template v-slot:activator="{ on, attrs }">
            <v-avatar class="ml-sm-4 mr-sm-9" size="40px" v-bind="attrs" v-on="on">
              <img src="@/assets/account.svg" alt="John" />
            </v-avatar>
          </template>
          <v-list>
            <v-list-item class="pl-12" style="border-bottom: 2px solid #ededed"
              >اهلا بك
              <v-list-item-icon class="ml-2">
                <img
                  src="@/assets/small-hands.png"
                  class="mr-2"
                  alt="" /></v-list-item-icon
            ></v-list-item>
            <v-list-item
              class="pl-12"
              @click="goToProfile"
              style="border-bottom: 2px solid #ededed"
            >
              <v-list-item-icon class="ml-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.91"
                  height="17.773"
                  viewBox="0 0 15.91 17.773"
                >
                  <g
                    id="Icon_feather-user"
                    data-name="Icon feather-user"
                    transform="translate(-5.5 -4)"
                  >
                    <path
                      id="Path_3967"
                      data-name="Path 3967"
                      d="M20.91,28.091V26.227A3.727,3.727,0,0,0,17.182,22.5H9.727A3.727,3.727,0,0,0,6,26.227v1.864"
                      transform="translate(0 -6.818)"
                      fill="none"
                      stroke="#00acf1"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                    />
                    <path
                      id="Path_3968"
                      data-name="Path 3968"
                      d="M19.455,8.227A3.727,3.727,0,1,1,15.727,4.5,3.727,3.727,0,0,1,19.455,8.227Z"
                      transform="translate(-2.273)"
                      fill="none"
                      stroke="#00acf1"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                    />
                  </g>
                </svg>
              </v-list-item-icon>
              <v-list-item-title style="line-height: 2"
                >بياناتي الشخصية</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              class="pl-12"
              @click="goToAnouncements"
              style="border-bottom: 2px solid #ededed"
            >
              <v-list-item-icon class="ml-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.074"
                  height="16.947"
                  viewBox="0 0 17.074 16.947"
                >
                  <g id="_Group_" data-name=" Group " transform="translate(-38 -39.966)">
                    <path
                      id="_Compound_Path_"
                      data-name=" Compound Path "
                      d="M53.253,39.966a1.828,1.828,0,0,0-1.808,1.59c-.719.543-3.985,2.875-7.071,2.875H39.945A1.947,1.947,0,0,0,38,46.38v3.039a1.938,1.938,0,0,0,1.931,1.944h.26l1.27,4.144a1.94,1.94,0,1,0,3.729-1.069,13.121,13.121,0,0,1-.419-3.05c3.09.176,6.015,2.337,6.677,2.856a1.82,1.82,0,0,0,3.627-.233V41.793a1.824,1.824,0,0,0-1.821-1.827ZM43.991,50.58H40.9V45.215h3.094Zm-5.208-1.161V46.387a1.162,1.162,0,0,1,1.153-1.172h.178V50.58h-.17a1.154,1.154,0,0,1-1.162-1.145ZM43.7,56.037a1.148,1.148,0,0,1-.9-.059,1.16,1.16,0,0,1-.584-.7l-1.2-3.915h2.977a14.026,14.026,0,0,0,.446,3.275,1.167,1.167,0,0,1-.735,1.4ZM44.775,50.6V45.2a11.721,11.721,0,0,0,4.894-1.573,18.948,18.948,0,0,0,1.763-1.093V53.253a17.176,17.176,0,0,0-1.694-1.074A11.949,11.949,0,0,0,44.775,50.6Zm9.516,3.408a1.038,1.038,0,1,1-2.076,0V41.793a1.038,1.038,0,0,1,2.076,0Z"
                      transform="translate(0)"
                      fill="#00acf1"
                    />
                  </g>
                </svg>
              </v-list-item-icon>
              <v-list-item-title style="line-height: 2">التعميمات</v-list-item-title>
            </v-list-item>
            <v-list-item class="pl-12" @click="logout">
              <v-list-item-icon class="ml-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.074"
                  height="15.58"
                  viewBox="0 0 17.074 15.58"
                >
                  <g id="logout" transform="translate(0 -22.397)">
                    <g
                      id="Group_1006"
                      data-name="Group 1006"
                      transform="translate(4.975 28.398)"
                    >
                      <g id="Group_1005" data-name="Group 1005">
                        <path
                          id="Path_3975"
                          data-name="Path 3975"
                          d="M161.082,203.738l-1.827-1.288a.5.5,0,0,0-.788.409v.788H149.7a.5.5,0,1,0,0,1h8.772v.788a.5.5,0,0,0,.788.409l1.827-1.288A.5.5,0,0,0,161.082,203.738Z"
                          transform="translate(-149.195 -202.358)"
                          fill="#00acf1"
                        />
                      </g>
                    </g>
                    <g
                      id="Group_1008"
                      data-name="Group 1008"
                      transform="translate(0 22.397)"
                    >
                      <g
                        id="Group_1007"
                        data-name="Group 1007"
                        transform="translate(0 0)"
                      >
                        <path
                          id="Path_3976"
                          data-name="Path 3976"
                          d="M14.355,33.4a.5.5,0,0,0-.683.183,6.79,6.79,0,1,1,0-6.789.5.5,0,1,0,.866-.5,7.79,7.79,0,1,0,0,7.791A.5.5,0,0,0,14.355,33.4Z"
                          transform="translate(0 -22.397)"
                          fill="#00acf1"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </v-list-item-icon>
              <v-list-item-title style="line-height: 2">تسجيل الخروج</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->
      </v-app-bar>
      <v-app-bar
        v-if="$route.name != 'SignIn'"
        style="
          position: absolute;
          top: 56px;
          border-top: 1px solid #f8f9fa30;
          margin-bottom: 20px;
        "
        id="app_bar"
        app
        color="white"
        elevation="0"
        class="pb-1"
        height="66"
      >
        <breadcrumbs-component />
        <v-spacer class="hidden-xs-and-down" />
        <div class="separatorr">
          <v-btn
            v-if="requests_routes && $route.name != 'archievedInQuality' && isAgent "
            fab
            class="ml-2 pa-0"
            small
            :color="$vuetify.theme.themes.light.BtnBKColor"
            @click="$store.state.search_model = true"
            depressed
            width="40px"
            style="background: #3f0e40; border-radius: 8px"
            height="40px"
          >
            <v-icon color="white">mdi-magnify</v-icon>
          </v-btn>

          <v-menu
            offset-y
            nudge-top="-7"
            v-if="
              requests_routes &&
              $route.name != 'extra' &&
              $route.name != 'archievedInQuality' &&
              isAgent &&
              !$store.state.search_result_visibility
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-avatar size="45px" v-bind="attrs" v-on="on">
                <svg
                  width="512"
                  height="512"
                  viewBox="0 0 512 512"
                  fill="#3f0e40"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M474.557 37.4414V168.577H328.851V37.4414H474.557ZM255.999 37.4414V474.557H37.4414V37.4414H255.999ZM474.557 197.719V314.281H328.851V197.719H474.556H474.557ZM474.557 343.422V474.557H328.851V343.422H474.556H474.557Z"
                    fill="#3f0e40"
                  />
                </svg>
              </v-avatar>
            </template>
            <v-list>
              <v-list-item @click="$store.state.views = 'default'">
                <v-list-item-icon class="ml-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="#3F0E40"
                  >
                    <rect x="2" y="5" width="12" height="3" fill="#3F0E40" />
                    <rect x="2" y="10" width="12" height="3" fill="#3F0E40" />
                    <rect x="2" y="16" width="12" height="3" fill="#3F0E40" />
                    <rect x="2" y="21" width="12" height="3" fill="#3F0E40" />
                    <rect
                      x="16.4424"
                      y="5"
                      width="11.5385"
                      height="8.65385"
                      rx="1"
                      fill="#3F0E40"
                    />
                    <rect
                      x="16.4424"
                      y="15.0957"
                      width="11.5385"
                      height="8.65385"
                      rx="1"
                      fill="#3F0E40"
                    />
                  </svg>
                </v-list-item-icon>

                <v-list-item-title style="line-height: 2">
                  عرض الملفات
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                class="pl-12"
                @click="$store.state.views = 'cards'"
                style="border-bottom: 2px solid #ededed"
              >
                <v-list-item-icon class="ml-2">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="#3F0E40"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="four-squares-icon 1">
                      <g id="Group">
                        <path
                          id="Vector"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2.61524 12.7831H10.4074C10.7402 12.7831 11.0124 13.1075 11.0124 13.5034V21.0877C11.0124 21.4842 10.7404 21.8083 10.4074 21.8083H2.61524C2.28272 21.8083 2.01025 21.4842 2.01025 21.0877V13.5034C2.01025 13.1075 2.28272 12.7831 2.61524 12.7831ZM13.5925 2H21.3848C21.7178 2 21.9898 2.32407 21.9898 2.72043V10.3048C21.9898 10.7011 21.7178 11.0249 21.3848 11.0249H13.5925C13.2596 11.0249 12.988 10.7011 12.988 10.3048V2.72043C12.988 2.32407 13.2596 2 13.5925 2ZM2.60449 2H10.3971C10.73 2 11.0021 2.32407 11.0021 2.72043V10.3048C11.0021 10.7011 10.7302 11.0249 10.3971 11.0249H2.60449C2.27197 11.0249 2 10.7011 2 10.3048V2.72043C2 2.32407 2.27197 2 2.60449 2ZM13.6027 12.9753H21.3951C21.7276 12.9753 22 13.2994 22 13.6959V21.2803C22 21.6761 21.7276 22.0002 21.3951 22.0002H13.6027C13.2699 22.0002 12.9977 21.6761 12.9977 21.2803V13.6959C12.9977 13.2994 13.2699 12.9753 13.6027 12.9753Z"
                          fill="#3F0E40"
                        />
                      </g>
                    </g>
                  </svg>
                </v-list-item-icon>
                <v-list-item-title style="line-height: 2"> عرض الكروت</v-list-item-title>
              </v-list-item>

              <v-list-item
                class="pl-12"
                @click="$store.state.views = 'table'"
                style="border-bottom: 2px solid #ededed"
              >
                <v-list-item-icon class="ml-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27.348"
                    height="22.783"
                    viewBox="0 0 24.348 19.783"
                    fill="#3F0E40"
                  >
                    <path
                      id="Icon_awesome-list-ul"
                      data-name="Icon awesome-list-ul"
                      d="M22.065,3.375a2.283,2.283,0,1,1-2.283,2.283A2.283,2.283,0,0,1,22.065,3.375Zm0,7.609a2.283,2.283,0,1,1-2.283,2.283A2.283,2.283,0,0,1,22.065,10.984Zm0,7.609a2.283,2.283,0,1,1-2.283,2.283,2.283,2.283,0,0,1,2.283-2.283Zm-21.3.761H15.978a.761.761,0,0,1,.761.761v1.522a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,21.636V20.114A.761.761,0,0,1,.761,19.353Zm0-15.217H15.978a.761.761,0,0,1,.761.761V6.418a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,6.418V4.9A.761.761,0,0,1,.761,4.136Zm0,7.609H15.978a.761.761,0,0,1,.761.761v1.522a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,14.027V12.505A.761.761,0,0,1,.761,11.745Z"
                      transform="translate(0 -3.375)"
                      fill="#3F0E40"
                    />
                  </svg>
                </v-list-item-icon>
                <v-list-item-title style="line-height: 2">عرض الجدول</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <!-- <v-menu
            offset-y
            nudge-top="-7"
            v-if="
              requests_routes &&
              !isAgent &&
              $route.name != 'extra' &&
              !$store.state.search_result_visibility
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-avatar size="45px" v-bind="attrs" v-on="on">
                <svg
                  width="512"
                  height="512"
                  viewBox="0 0 512 512"
                  fill="#3f0e40"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M474.557 37.4414V168.577H328.851V37.4414H474.557ZM255.999 37.4414V474.557H37.4414V37.4414H255.999ZM474.557 197.719V314.281H328.851V197.719H474.556H474.557ZM474.557 343.422V474.557H328.851V343.422H474.556H474.557Z"
                    fill="#3f0e40"
                  />
                </svg>
              </v-avatar>
            </template>
            <v-list>
              <v-list-item
                class="pl-12"
                @click="$store.state.cardsStyle = true"
                style="border-bottom: 2px solid #ededed"
              >
                <v-list-item-icon class="ml-2">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="#3F0E40"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="four-squares-icon 1">
                      <g id="Group">
                        <path
                          id="Vector"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2.61524 12.7831H10.4074C10.7402 12.7831 11.0124 13.1075 11.0124 13.5034V21.0877C11.0124 21.4842 10.7404 21.8083 10.4074 21.8083H2.61524C2.28272 21.8083 2.01025 21.4842 2.01025 21.0877V13.5034C2.01025 13.1075 2.28272 12.7831 2.61524 12.7831ZM13.5925 2H21.3848C21.7178 2 21.9898 2.32407 21.9898 2.72043V10.3048C21.9898 10.7011 21.7178 11.0249 21.3848 11.0249H13.5925C13.2596 11.0249 12.988 10.7011 12.988 10.3048V2.72043C12.988 2.32407 13.2596 2 13.5925 2ZM2.60449 2H10.3971C10.73 2 11.0021 2.32407 11.0021 2.72043V10.3048C11.0021 10.7011 10.7302 11.0249 10.3971 11.0249H2.60449C2.27197 11.0249 2 10.7011 2 10.3048V2.72043C2 2.32407 2.27197 2 2.60449 2ZM13.6027 12.9753H21.3951C21.7276 12.9753 22 13.2994 22 13.6959V21.2803C22 21.6761 21.7276 22.0002 21.3951 22.0002H13.6027C13.2699 22.0002 12.9977 21.6761 12.9977 21.2803V13.6959C12.9977 13.2994 13.2699 12.9753 13.6027 12.9753Z"
                          fill="#3F0E40"
                        />
                      </g>
                    </g>
                  </svg>
                </v-list-item-icon>
                <v-list-item-title style="line-height: 2"> عرض الكروت</v-list-item-title>
              </v-list-item>

              <v-list-item
                class="pl-12"
                @click="$store.state.cardsStyle = false"
                style="border-bottom: 2px solid #ededed"
              >
                <v-list-item-icon class="ml-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27.348"
                    height="22.783"
                    viewBox="0 0 24.348 19.783"
                    fill="#3F0E40"
                  >
                    <path
                      id="Icon_awesome-list-ul"
                      data-name="Icon awesome-list-ul"
                      d="M22.065,3.375a2.283,2.283,0,1,1-2.283,2.283A2.283,2.283,0,0,1,22.065,3.375Zm0,7.609a2.283,2.283,0,1,1-2.283,2.283A2.283,2.283,0,0,1,22.065,10.984Zm0,7.609a2.283,2.283,0,1,1-2.283,2.283,2.283,2.283,0,0,1,2.283-2.283Zm-21.3.761H15.978a.761.761,0,0,1,.761.761v1.522a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,21.636V20.114A.761.761,0,0,1,.761,19.353Zm0-15.217H15.978a.761.761,0,0,1,.761.761V6.418a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,6.418V4.9A.761.761,0,0,1,.761,4.136Zm0,7.609H15.978a.761.761,0,0,1,.761.761v1.522a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,14.027V12.505A.761.761,0,0,1,.761,11.745Z"
                      transform="translate(0 -3.375)"
                      fill="#3F0E40"
                    />
                  </svg>
                </v-list-item-icon>
                <v-list-item-title style="line-height: 2">عرض الجدول</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->

          <!-- <v-btn
            width="40px"
            height="40px"
            v-if="
              requests_routes &&
              !isAgent &&
              $route.name != 'extra' &&
              !$store.state.search_result_visibility
            "
            icon
            color="purple"
            class="ml-1 table-style active-table-style"
            @click="$store.state.cardsStyle = true"
            style="
              border-radius: var(--8, 8px);

              background: rgba(63, 14, 64, 0.15);
            "
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="four-squares-icon 1">
                <g id="Group">
                  <path
                    id="Vector"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.61524 12.7831H10.4074C10.7402 12.7831 11.0124 13.1075 11.0124 13.5034V21.0877C11.0124 21.4842 10.7404 21.8083 10.4074 21.8083H2.61524C2.28272 21.8083 2.01025 21.4842 2.01025 21.0877V13.5034C2.01025 13.1075 2.28272 12.7831 2.61524 12.7831ZM13.5925 2H21.3848C21.7178 2 21.9898 2.32407 21.9898 2.72043V10.3048C21.9898 10.7011 21.7178 11.0249 21.3848 11.0249H13.5925C13.2596 11.0249 12.988 10.7011 12.988 10.3048V2.72043C12.988 2.32407 13.2596 2 13.5925 2ZM2.60449 2H10.3971C10.73 2 11.0021 2.32407 11.0021 2.72043V10.3048C11.0021 10.7011 10.7302 11.0249 10.3971 11.0249H2.60449C2.27197 11.0249 2 10.7011 2 10.3048V2.72043C2 2.32407 2.27197 2 2.60449 2ZM13.6027 12.9753H21.3951C21.7276 12.9753 22 13.2994 22 13.6959V21.2803C22 21.6761 21.7276 22.0002 21.3951 22.0002H13.6027C13.2699 22.0002 12.9977 21.6761 12.9977 21.2803V13.6959C12.9977 13.2994 13.2699 12.9753 13.6027 12.9753Z"
                    fill="#3F0E40"
                  />
                </g>
              </g>
            </svg>
          </v-btn> -->
          <!-- <v-btn
            class="ml-4 table-style active-table-style"
            width="40px"
            height="40px"
            v-if="requests_routes && !$store.state.search_result_visibility && !isAgent"
            style="background: #3f0e40; border-radius: var(--8, 8px)"
            icon
            color="#ffffff"
            @click="$store.state.cardsStyle = false"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27.348"
              height="22.783"
              viewBox="0 0 24.348 19.783"
            >
              <path
                id="Icon_awesome-list-ul"
                data-name="Icon awesome-list-ul"
                d="M22.065,3.375a2.283,2.283,0,1,1-2.283,2.283A2.283,2.283,0,0,1,22.065,3.375Zm0,7.609a2.283,2.283,0,1,1-2.283,2.283A2.283,2.283,0,0,1,22.065,10.984Zm0,7.609a2.283,2.283,0,1,1-2.283,2.283,2.283,2.283,0,0,1,2.283-2.283Zm-21.3.761H15.978a.761.761,0,0,1,.761.761v1.522a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,21.636V20.114A.761.761,0,0,1,.761,19.353Zm0-15.217H15.978a.761.761,0,0,1,.761.761V6.418a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,6.418V4.9A.761.761,0,0,1,.761,4.136Zm0,7.609H15.978a.761.761,0,0,1,.761.761v1.522a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,14.027V12.505A.761.761,0,0,1,.761,11.745Z"
                transform="translate(0 -3.375)"
                fill="#fff"
              />
            </svg>
          </v-btn> -->
          <v-divider
            v-if="rates_route"
            style="
              background-color: #b4bcd9;
              transform: translateX(5px);
              position: absolute;
              height: 72% !important;
              max-height: 100% !important;
              min-height: 0px;
              bottom: 14%;
            "
            vertical
          />
          <v-btn
            v-if="rates_route"
            fab
            class="mx-2 pa-0"
            small
            :color="$vuetify.theme.themes.light.BtnBKColor"
            @click="$store.state.search_rate = true"
            depressed
            width="35"
            style="min-width: unset"
            height="35"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.467"
              class="mt-1"
              height="15.92"
              viewBox="0 0 16.467 14.92"
            >
              <path
                id="Icon_feather-filter"
                data-name="Icon feather-filter"
                d="M18.467,4.5H3l6.187,7.316v5.058L12.28,18.42v-6.6Z"
                transform="translate(-2.5 -4)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </svg>
          </v-btn>
        </div>
      </v-app-bar>
      <v-main>
        <v-container fluid>
          <!--Handle Errors-->
          <div dir="ltr">
            <v-alert
              dense
              text
              outlined
              type="error"
              class="mx-3"
              dismissible
              v-model="saveErrs.alert"
            >
              <p v-for="(err, i) in saveErrs.error" :key="i">{{ err }}</p>
            </v-alert>
          </div>
          <!--End Handle Errors-->
          <page-component :loading="loading" class="mx-3">
            <template #content>
              <router-view
                v-if="!$store.state.search_result_visibility && !loading"
                @requests="update_app_bar_props($event)"
                @refreshTopBarFunctionality="refreshTopBarFunctionality"
              />
              <search-result v-else />
              <request-log :field="$store.state.field" v-model="$store.state.field_log" />
              <popup-component />
              <popup-error-component />
              <popup-error-btn-component />
              <!-- <announcement-alert-component /> -->
              <filter-all v-model="$store.state.search_model" />
              <!--              <filter-rate v-model="$store.state.search_rate" />  -->
            </template>
          </page-component>
        </v-container>
      </v-main>
    </template>
  </v-app>
</template>

<script>
import firebase from "./Firebase";
import Axios from "axios";
import { mapActions } from "vuex";
import { axiosInstance } from "@/axios/config";
import SelectInput from "@/components/Charts/SelectInput.vue";

export default {
  name: "App",
  components: {SelectInput },
  data() {
    return {
      clickCount: localStorage.setItem("count", 0),
      loading: false,
      filtered_search: false,
      arabicErrorMassage: [],
      //Notificatons
      notifications: [],
      tasks: [],
      tickets: [],
      messages: [],
      saveErrs: {
        alert: false,
        error: [],
      },
      currentRole: 0,
      userIsSwitched: false,
      loadingNav: false,
      fetched: false,
      navbarMsgsNotif: { unreadMsgs: 0, rooms: [] },
      newSupportRequest: [],
      technical_support_notifications_count: 0,
      searchActive: false,
      phoneNum: null,
      isDropdownOpen: false,
    };
  },
  async created() {
    if (document.cookie !== "") {
      let promise1 = await this.get_work_list();
      let promise2 = await this.getTopBarData();
      let promise3 = await this.get_request_resources();
      let promise4 = await this.get_request_statuses();
      let promise5 = await this.get_agent_customers();
      let promise6 = await this.get_military_works();
      let promise7 = await this.get_request_classification();
      let promise8 = await this.get_salary_sources();
      let promise9 = await this.get_cities();
      let promise10 = await this.get_madany_works();
      let promise11 = await this.get_app_products();
      let promise12 = await this.get_funding_sources();
      let promise13 = await this.get_military_ranks();
      let promise14 = await this.get_selected_product_types();
      let promise15 = await this.get_projects();
      let promise16 = await this.get_rejection_list();
      let promise17 = await this.get_mortgage_settings();

      let promise18 = await this.getAllMortgageCustomerNeeds();
      let promise19 = await this.getAllMortgageCustomerObjections();
      let promise20 = await this.getAllMortgageCustomerObsatacles();

      await Promise.all([
        promise1,
        promise2,
        promise3,
        promise4,
        promise5,
        promise6,
        promise7,
        promise8,
        promise9,
        promise10,
        promise11,
        promise12,
        promise13,
        promise14,
        promise15,
        promise16,
        promise17,
        promise18,
        promise19,
        promise20,
      ]);
    }
    this.loading = false;
  },
  computed: {
    isAdminPage() {
      return this.$route.path.includes("/admin");
    },

    getUsersName() {
      return this.allUsers;
    },

    isAgent() {
      return this.$route.path.startsWith("/agent");
    },

    request_details_route() {
      return (
        this.$route.name == "agent_data" ||
        this.$route.name == "real_estate_data" ||
        this.$route.name == "fund_data" ||
        this.$route.name == "fund_calc" ||
        this.$route.name == "attachments" ||
        this.$route.name == "logs" ||
        this.$route.name == "tickets" ||
        this.$route.name == "request_details"
      );
    },
    user_name() {
      return JSON.parse(
        document.cookie
          .split(";")
          .map((coki) => coki.split("="))
          .reduce(
            (acc, [key, val]) => ({
              ...acc,
              [key.trim()]: decodeURIComponent(val),
            }),
            {}
          )["wsataJwt"]
      )["agent_name"];
    },

    user_avatar() {
      return window.localStorage.getItem("avatar");
    },

    loggedIn_user() {
      return window.localStorage.getItem("loggedIn_user_name");
    },

    requests_routes() {
      return (
        this.$route.name == "all" ||
        this.$route.name == "recieved" ||
        /*  this.$route.name == "latest" ||*/
        this.$route.name == "following" ||
        this.$route.name == "special" ||
        this.$route.name == "archieved" ||
        this.$route.name == "special" ||
        this.$route.name == "completed" ||
        this.$route.name == "buying" ||
        this.$route.name == "admin-latest-report" ||
        this.$route.name == "extra" ||
        this.$route.name == "salesManagerAll" ||
        this.$route.name == "salesManager_recieved" ||
        this.$route.name == "salesManager_following" ||
        this.$route.name == "salesManager_SMrejected" ||
        this.$route.name == "salesManager_archieved" ||
        this.$route.name == "salesManager_SMmortgage" ||
        this.$route.name == "salesManager_completed" ||
        this.$route.name == "salesManager_buying" ||
        this.$route.name == "salesManager_SMmortgagebuying" ||
        this.$route.name == "salesManager_SMbuying" ||
        this.$route.name == "SMEAllRequests" ||
        this.$route.name == "SMERecievedRequests" ||
        this.$route.name == "SMESpecialRequests" ||
        this.$route.name == "SMEFollowRequests" ||
        this.$route.name == "SMECompletedRequests" ||
        this.$route.name == "SMEArchievedRequests" ||
        this.$route.name == "archievedInQuality"
      );
    },
    recieved_requests_route() {
      return this.$route.name == "recieved";
    },
    extra_requests_route() {
      return this.$route.name == "extra";
    },
    search_result() {
      return this.$store.state.search_result;
    },
    rates_route() {
      return (
        this.$route.name ==
        "RateServices" /*||
        this.$route.name == "salesManager_SMRateServices"*/
      );
    },
    search_rate_result() {
      return this.$store.state.search_rate_result;
    },
  },
  methods: {

    getSelectedValueUser(val) {
      console.log(val[0]['id']);
      axiosInstance.get(`/v2/employee/swith-logged-user/${val[0]['id']}`).then((res) => {
        if (res.data.payload.role !== 4) {
          this.$store.state.loggedIn = false;
          let adminIdBeforeSwitched = window.localStorage.getItem("id");
          let roleBeforeSwitched = window.localStorage.getItem("role");


          localStorage.clear();
          document.cookie =
            `wsataJwt=;expires=expires=Sat, 20 Jan 1980 12:00:00 UTC;path=/;domain=` +
            window.location.hostname +
            `;`;

          let expireTime = new Date();
          expireTime = expireTime.setHours(expireTime.getHours() + 12);
          document.cookie =
            `wsataJwt=${JSON.stringify(res.data.payload)};expires=${Date(
              expireTime
            )};path=/;domain=` +
            window.location.hostname +
            `;`;
          localStorage.setItem("role", res.data.payload.role);
          localStorage.setItem("adminIdBeforeSwitched", adminIdBeforeSwitched);
          localStorage.setItem("roleBeforeSwitched", roleBeforeSwitched);

          localStorage.setItem("userIsSwitched", true);

          localStorage.setItem("avatar", res.data.payload.avatar);
          localStorage.setItem("loggedIn_user_name", res.data.payload.agent_name);

          document.querySelectorAll("header").forEach((head) => {
            head.style.display = "block";
          });
          window.location.reload();
          this.$store.state.loggedIn = true;

          if (localStorage.getItem("role") == "1") {
            this.$router.push("/salesManager/requests/all");
          } else if (localStorage.getItem("role") == "0") {
            this.$router.push("/agent/requests/all");
          } else if (localStorage.getItem("role") == "7") {
            this.$router.push("/admin");
          } else if (localStorage.getItem("role") == "5") {
            this.$router.push("/quality/requests/all");
          } else {
            this.$router.push("/");
          }
          window.location.reload();
        }
      });
    },

    getAllUsers() {
      this.allUsers = [];
      let body = new FormData();
      let url = "/employee/admin/filterUsers";
      axiosInstance.post(url, body).then((res) => {
        this.allUsers = res.data.payload;
        this.allUsers.map((item) => {
          item["color"] = "red";
          item["text"] = item["name"];
          return item;
        });
      });
    },

    clickOutside() {
      this.$nextTick(() => {
        this.isDropdownOpen = false;
      });
    },

    isVacationNotififcation(notif) {
    // console.log("notif", notif);
      if (notif.is_vacation_notification === true) {
        return {
          name: "alternative_vacation_details",
          params: { id: notif.vacation_id },
        };
      } else if (notif.type === 8) {
        return {
          name: "SupportContent",
          params: { id: notif.helpDeskId },
        };
      }else if (notif.value === "لديك طلب تأرشف من الجودة") {
        return {
          name: "archievedInQuality",
        };
      } else if (notif.req_id != null) {
        // return {
        //   name: "request_details",
        //   params: { id: notif.req_id },
        // };

        if (localStorage["role"] && localStorage["role"] == "1") {
          return {
            name: "salesManager_request_details",
            params: { id: notif.req_id },
          };
        } else {
          return {
            name: "request_details",
            params: { id: notif.req_id },
          };
        }
      }else{
        return null;
      }
    },

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    MarkNotificationAsRead(id) {
      axiosInstance.post("/employee/mark-as-read", { notify_id: id }).then((response) => {
        this.getTopBarData();
      });
    },
    backToAdminAccount(){
      let main_user_id = '';
      if(window.localStorage.getItem("roleBeforeSwitched") == '7'){
        main_user_id = window.localStorage.getItem("adminIdBeforeSwitched");
      }else if(window.localStorage.getItem("roleBeforeSwitched") == '1'){
        main_user_id = window.localStorage.getItem("salesManagerIdBeforeSwitched");
      }
      
      axiosInstance.get(`/v2/employee/restore-logged-admin/${main_user_id}`).then((res) => {
        if (res.data.payload.role !== 4) {
          this.$store.state.loggedIn = false;

            window.localStorage.clear();
            document.cookie =
              `wsataJwt=;expires=expires=Sat, 20 Jan 1980 12:00:00 UTC;path=/;domain=` +
              window.location.hostname +
              `;`;

            let expireTime = new Date();
            expireTime = expireTime.setHours(expireTime.getHours() + 12);
            document.cookie =
              `wsataJwt=${JSON.stringify(res.data.payload)};expires=${Date(
                expireTime
              )};path=/;domain=` +
              window.location.hostname +
              `;`;
            window.localStorage.setItem("role", res.data.payload.role);
            window.localStorage.setItem("id", res.data.payload.agent_id);

            localStorage.setItem("avatar", res.data.payload.avatar);
            localStorage.setItem("loggedIn_user_name", res.data.payload.agent_name);

            if (res.data.payload.role === 7) {
              window.localStorage.setItem("isAdmin", true);
              window.localStorage.setItem("token", res.data.payload);
            } else {
              window.localStorage.removeItem("isAdmin");
            }

            document.querySelectorAll("header").forEach((head) => {
              head.style.display = "block";
            });
            this.$store.state.loggedIn = true;

            if( window.localStorage.getItem("roleBeforeSwitched") == '1'){
              this.$router.push("/salesManager");
            }else if(window.localStorage.getItem("roleBeforeSwitched") == '7'){
              this.$router.push("/admin");
            }else {
              this.$router.push("/");
            }
            window.location.reload();
          }
        });
    },

    handleToggleFilter() {
      this.searchActive = !this.searchActive;
    },

    handleFilter() {
      this.$router.push({
        name: "general-filter",
        query: {
          phoneNum: this.phoneNum,
        },
      });
    },

    ...mapActions(["setNavChatAction"]),
    async get_rooms() {
      this.fetched = true;
      const id = JSON.parse(
        document.cookie
          .split(";")
          .map((coki) => coki.split("="))
          .reduce(
            (acc, [key, val]) => ({
              ...acc,
              [key.trim()]: decodeURIComponent(val),
            }),
            {}
          )["wsataJwt"]
      )["agent_id"];
      const chat_rooms = firebase
        .firestore()
        .collection("rooms")
        .where("AgentId", "in", [`${id}`])
        .orderBy("LastMessageTime", "desc");
      chat_rooms.onSnapshot((res) => {
        this.navbarMsgsNotif.rooms = [];
        this.navbarUnReadMsgs = 0;
        this.navbarMsgsNotif.unreadMsgs = 0;
        res.forEach(async (docOne) => {
          if (docOne.data().RequestId) {
            let obj = { id: docOne.id, data: docOne.data() };
            obj.data.ureadMsgsCount = 0;
            let chat_ref = firebase
              .firestore()
              .collection("messages")
              .where("roomId", "==", `${docOne.id}`);
            chat_ref.onSnapshot((res) => {
              //this.navbarUnReadMsgs = 0;
              obj.data.ureadMsgsCount = 0;
              // this.navbarMsgsNotif.unreadMsgs = 0;
              res.forEach((docTwo) => {
                if (docTwo.data().senderId != id) {
                  if (docTwo.data().is_read == 0) {
                    this.navbarMsgsNotif.unreadMsgs += 1;
                    obj.data.ureadMsgsCount += 1;
                  }
                }
                obj.data.lastMsgSenderId = docTwo.data().senderId;
                obj.data.lastMessage = docTwo.data().text
                  ? docTwo.data().text !== undefined && docTwo.data().text.length > 30
                    ? docTwo.data().text?.split("").slice(0, 29).join("") + " " + "..."
                    : docTwo.data().text
                  : "تم ارسال مرفق ..";
              });
              this.navbarMsgsNotif.rooms.push(obj);
              this.setNavChatAction(this.navbarMsgsNotif);
            });
          }
        });
      });
      setTimeout(() => {
        this.fetched = false;
      }, 1000);
    },
    to_chat_page(item) {
      this.$router.push({
        name: "chat_component",
        query: {
          customer_name: item.data.UserName,
          customer_id: item.data.UserId,
          request_id: item.id,
        },
      });
    },
    goToAnouncements() {
      this.$router.push({ name: "TheAnnouncements" });
      this.toggleDropdown();
    },

    goToProfile() {
      this.$router.push({ name: "profile" });
      this.toggleDropdown();
    },

    toggleTableStyle(e) {
      document.querySelectorAll(".table-style").forEach((btn) => {
        btn.querySelector("svg").classList.remove("active-table-style");
      });
      e.target.querySelector("svg").classList.add("active-table-style");
    },

    resize_app_bar() {
      this.$store.state.mini = !this.$store.state.mini;
    },

    async get_work_list() {
      await Axios.get("https://api.alwsatarealestate.com/api/customer/WorkSources", {
        headers: this.request_headers(),
      }).then((response) => {
        this.$store.state.works_list = response.data.payload;
      });
    },
    async get_projects() {
      await Axios.get("https://api.alwsatarealestate.com/api/customer/Projects", {
        headers: this.request_headers(),
      }).then((response) => {
        this.$store.state.projects = response.data.payload;
      });
    },
    async get_request_resources() {
      await Axios.get("https://api.alwsatarealestate.com/api/agent/Request/Sources", {
        headers: this.request_headers(),
      }).then((response) => {
        this.$store.state.work_sources = response.data.payload;
      });
    },
    async get_salary_sources() {
      await Axios.get("https://api.alwsatarealestate.com/api/customer/SalarySources", {
        headers: this.request_headers(),
      }).then((response) => {
        this.$store.state.salary_sources = response.data.payload;
      });
    },
    async get_request_classification() {
      if (this.currentRole == 5) {
        await Axios.get(
          "https://api.alwsatarealestate.com/api/agent/Request/Classification?type_of_classification=5",
          { headers: this.request_headers() }
        ).then((response) => {
          this.$store.state.request_class = response.data.payload;
        });
      } else {
        await Axios.get(
          "https://api.alwsatarealestate.com/api/agent/Request/Classification",
          { headers: this.request_headers() }
        ).then((response) => {
          this.$store.state.request_class = response.data.payload;
        });
      }
    },
    async get_rejection_list() {
      await Axios.get("https://api.alwsatarealestate.com/api/agent/Rejections/Reasons", {
        headers: this.request_headers(),
      }).then((response) => {
        this.$store.state.rejection_list = response.data.payload;
      });
    },
    async get_selected_product_types() {
      await Axios.get("https://api.alwsatarealestate.com/api/customer/SelectedProduct", {
        headers: this.request_headers(),
      }).then((response) => {
        this.$store.state.selected_products = response.data.payload;
      });
    },

    async get_mortgage_settings() {
      await Axios.get("https://api.alwsatarealestate.com/api/employee/admin/mortgage-settings-types", {
        headers: this.request_headers(),
      }).then((response) => {
        this.$store.state.customer_needs = response.data.payload.customer_needs;
        this.$store.state.objections_challenges =
          response.data.payload.objections_challenges;
        this.$store.state.obstacles = response.data.payload.obstacles;
      });
    },

    async getAllMortgageCustomerNeeds() {
      await Axios.get(
        "https://api.alwsatarealestate.com/api/employee/admin/mortgage-settings-CustomerNeeds",
        {
          headers: this.request_headers(),
        }
      ).then((response) => {
        this.$store.state.customer_needs = response.data.payload;
      });
    },

    async getAllMortgageCustomerObjections() {
      await Axios.get(
        "https://api.alwsatarealestate.com/api/employee/admin/mortgage-settings-CustomerObjections",
        {
          headers: this.request_headers(),
        }
      ).then((response) => {
        this.$store.state.objections_challenges = response.data.payload;
      });
    },

    async getAllMortgageCustomerObsatacles() {
      await Axios.get(
        "https://api.alwsatarealestate.com/api/employee/admin/mortgage-settings-CustomerObsatacles",
        {
          headers: this.request_headers(),
        }
      ).then((response) => {
        this.$store.state.obstacles = response.data.payload;
      });
    },

    async get_cities() {
      await Axios.get("https://api.alwsatarealestate.com/api/cities", {
        headers: this.request_headers(),
      }).then((response) => {
        this.$store.state.cities = response.data.payload;
      });
    },
    async get_app_products() {
      await Axios.get("https://api.alwsatarealestate.com/api/customer/ProductTypes", {
        headers: this.request_headers(),
      }).then((response) => {
        this.$store.state.app_products = response.data.payload;
      });
    },
    async get_funding_sources() {
      await Axios.get("https://api.alwsatarealestate.com/api/agent/Funding/Sources", {
        headers: this.request_headers(),
      }).then((response) => {
        this.$store.state.funding_sources = response.data.payload;
      });
    },
    async get_madany_works() {
      await Axios.get("https://api.alwsatarealestate.com/api/agent/Madany/Works", {
        headers: this.request_headers(),
      }).then((response) => {
        this.$store.state.madany_works = response.data.payload;
      });
    },
    async get_military_ranks() {
      await Axios.get("https://api.alwsatarealestate.com/api/customer/MilitaryRanks", {
        headers: this.request_headers(),
      }).then((response) => {
        this.$store.state.military_ranks = response.data.payload;
      });
    },
    async get_request_statuses() {
      await Axios.get("https://api.alwsatarealestate.com/api/agent/RequestStatus", {
        headers: this.request_headers(),
      }).then((response) => {
        this.$store.state.request_statuses = response.data.payload;
      });
    },
    async get_military_works() {
      await Axios.get("https://api.alwsatarealestate.com/api/agent/Askary/Works", {
        headers: this.request_headers(),
      }).then((response) => {
        this.$store.state.military_works = response.data.payload;
      });
    },
    async get_agent_customers() {
      await Axios.get("https://api.alwsatarealestate.com/api/agent/RelatedCustomers", {
        headers: this.request_headers(),
      }).then((response) => {
        this.$store.state.agent_customers = response.data.payload;
      });
    },
    update_app_bar_props(elements) {
      this.$store.state.elements = elements;
    },

    to_request_details(item) {
      this.$router.push({ name: "request_details", params: { id: item.id } });
    },
    to_tickets_page(item) {
      this.$router.push({ name: "tickets", params: { id: item.id } });
    },

    printProps() {
      //selected elements
      var divToPrint = document.getElementById("recieved-requests");
      let newWin = window.open("");
      newWin.document.write(divToPrint.outerHTML);
      newWin.print();
    },

    //Notifications
    async getTopBarData() {
      let top_bar_endpoint = "https://api.alwsatarealestate.com/api/agent/topBarCount";
      if (
        localStorage["isAdmin"] ||
        (localStorage["role"] && localStorage["role"] == "1")
      ) {
        top_bar_endpoint =
          "https://api.alwsatarealestate.com/api/v2/employee/topBarCount";
      }
      await Axios.get(top_bar_endpoint, {
        headers: this.request_headers(),
      })
        .then((res) => {
          res.data.message.forEach((el) => {
            if (el.notifications) {
              this.notifications = el;
            } else if (el.received_tasks) {
              this.tickets = el;
              // this.$store.state.received_tasks_count = el.received_tasks_count
              this.$store.state.not_processed_tasks_count = el.not_processed_tasks_count;
              this.$store.state.first_not_processed_task_id = el.first_not_processed_task_id;
            } else if (el.Reminders) {
              this.tasks = el;
            } else if (el.technical_support_notifications_count) {
              this.$store.state.supportCount = el.technical_support_notifications_count;
              this.technical_support_notifications_count =
                el.technical_support_notifications_count;
            } else if (el.count_of_received_requests) {
              this.$store.state.recievedCount = el.count_of_received_requests;
              this.count_of_received_requests = el.count_of_received_requests;
            }  else if (el.rates_in_admin_count) {
              this.$store.state.rates_in_admin_count = el.rates_in_admin_count;
            }else if (el.recieved_tasks_notifications_count) {
              this.$store.state.recieved_tasks_notifications_count = el.recieved_tasks_notifications_count
            }else if (el.rates_notifications_count) {
              this.$store.state.rates_notifications_count = el.rates_notifications_count
            } else {
              this.messages = el;
              this.$store.state.navbarUnreadCountNum = el.unread_messages_count
            }
          });
        })
        .catch((err) => console.log(err));
    },

    async refreshNav() {
      /*this.get_rooms();*/
      if (document.cookie !== "") {
        this.loadingNav = true;
        await this.getTopBarData();
        this.loadingNav = false;
        await this.get_request_classification();
        await this.get_rejection_list();
        await this.get_selected_product_types();
        await this.get_work_list();
        await this.get_projects();
        await this.get_request_resources();
        await this.get_salary_sources();
        await this.get_cities();
        await this.get_madany_works();
        await this.get_app_products();
        await this.get_funding_sources();
        await this.get_military_ranks();
        await this.get_military_works();
        await this.get_request_statuses();
        await this.get_agent_customers();
        await this.get_mortgage_settings();

        await this.getAllMortgageCustomerNeeds();
        await this.getAllMortgageCustomerObjections();
        await this.getAllMortgageCustomerObsatacles();

      }
    },

    async refreshTopBarFunctionality(){
      console.log('refresh Top Bar Functionality success !')
      await this.getTopBarData();
    },

    logout() {
      this.$store.state.loggedIn = false;
      localStorage.clear();
      document.cookie =
        `wsataJwt=;expires=expires=Sat, 20 Jan 1980 12:00:00 UTC;path=/;domain=` +
        window.location.hostname +
        `;`;
      setTimeout(() => {
        this.$router.push({ name: "SignIn" });
      }, 500);
      return;
    },
  },
  watch: {
    "$route.name": {
      handler(val) {
        this.$store.state.elements = [];
        this.$store.state.bcItems = [];
        this.$store.state.search_result = [];
        this.$store.state.search_model = false;
        this.$store.state.search_rate = false;
        this.$store.state.search_rate_result = [];
        this.$store.state.search_result_visibility = false;
        this.$store.state.search_rate_result_visibility = false;
        if (val == "all") {
          setTimeout(() => {
            document.querySelector(".the-refresh-btn").click();
            this.$root.$emit("triggerChatRoomsData");
          }, 500);
        }
        if (!this.request_details_route) {
          this.saveErrs = {
            alert: false,
            error: [],
          };
        }
      },
    },
    "$store.state.cardsStyle": {
      handler() {
        this.$store.state.elements = [];
      },
    },
    "$store.state.loggedIn": {
      handler() {
        this.refreshNav();
      },
    },
  },
  beforeMount() {
    this.userIsSwitched = localStorage["userIsSwitched"];
  },

  mounted() {
    this.getAllUsers();

    this.currentRole = localStorage["role"];

    if (this.$route.name != "SignIn") {
      this.get_rooms();
    }
    let id;
    if (
      document.cookie
        .split(";")
        .map((coki) => coki.split("="))
        .reduce(
          (acc, [key, val]) => ({
            ...acc,
            [key.trim()]: decodeURIComponent(val),
          }),
          {}
        )["wsataJwt"] !== undefined
    ) {
      id = JSON.parse(
        document.cookie
          .split(";")
          .map((coki) => coki.split("="))
          .reduce(
            (acc, [key, val]) => ({
              ...acc,
              [key.trim()]: decodeURIComponent(val),
            }),
            {}
          )["wsataJwt"]
      )["agent_id"];
    } else {
      id = null;
    }
    // unreaded messages
    let unreadedList = firebase
      .firestore()
      .collection("messages")
      .where("is_read", "==", 0)
      //.orderBy('updated_at', 'desc')
      .where("receiverId", "==", `${id}`);
    this.messages.unread_messages = [];
    unreadedList.onSnapshot((res) => {
      this.messages.unread_messages_count = res.size;
      res.forEach((doc) => {
        if (doc.data().message_type != "transferring") {
          let msg_exist = this.messages.unread_messages.filter((msg) => {
            return msg["created_at"] == doc.data().created_at;
          });

          if (msg_exist.length == 0 && this.messages.unread_messages.length < 6) {
            this.messages.unread_messages.push({
              value: doc.data().text,
              req_id: doc.data().roomId,
            });
          }
        }
      });
      this.messages.unread_messages?.sort((a, b) => {
        if (a.created_at < b.created_at) return -1;
        if (a.created_at > b.created_at) return 1;
        return 0;
      });
    });
    // end unreaded
  },
  // },
};
</script>

<style lang="scss">

.v-application--is-rtl .v-list-item__action:first-child, .v-application--is-rtl .v-list-item__icon:first-child{
  margin-left: 0px !important;
}

.nav__globalFilter {
  border: 1px solid white;
  height: 40px;
  border-radius: 4px;
  color: white;
  padding: 10px;
}

@font-face {
  font-family: "Dintext";
  src: local("Dintext"),
    url(./assets/font/alfont_com_AlFont_com_DINNextLTArabic-Regular.ttf)
      format("truetype");
}

* {
  font-family: Dintext;
}
.td{
  font-weight: bold !important;
  color: #3f0e40 !important;
  background: #fff !important;
  border-top: 2px solid #3f0e40;
  font-family:Dintext !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row), .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td{
  font-family:Dintext !important;
}
#app_bar {
  background-image: url(./assets/nav-ground.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.v-main__wrap {
  background: #f3f5f7;
}

.button-colors {
  background-color: #40be72 !important;
  color: white !important;
  font: 15px bold white !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: rgba(192, 0, 250, 0.986) !important;
}

.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: #ced4da;
}

.horiz-dots {
  top: 123px !important;
}

.v-badge {
  position: absolute;
  right: 6px;
  z-index: 10;
}

hr {
  height: 30px !important;
}

.table-style svg {
  opacity: 0.4;

  &.active-table-style {
    opacity: 1;
  }
}
.active-view-style-btn {
  background: #f0caf1 !important;
}
.v-list-item .v-list-item__subtitle,
.v-list-item .v-list-item__title {
  line-height: unset !important;
}

.v-list-item .v-list-item__subtitle,
.v-list-item .v-list-item__title {
  line-height: unset !important;
}

//Chat style
.chat__footer input:not([type="file"]) {
  padding: 28px 0 28px 69px !important;
}

.chat__footer fieldset {
  border: 1px solid #e2e2e2c9 !important;
  outline: none !important;
}

@media (max-width: 600px) {
  hr {
    display: none !important;
  }

  .separatorr .v-main {
    padding-top: 154px !important;
  }

  .top-spacer {
    display: none;
  }

  .v-toolbar__content,
  .v-toolbar__extension {
    justify-content: space-around;
  }

  .v-toolbar.v-app-bar:last-of-type {
    height: 110px !important;

    .v-toolbar__content {
      flex-direction: column !important;

      div.separatorr {
        display: flex;
        justify-content: space-around;
        width: 100%;
      }

      button {
        img {
          width: 27px !important;
          margin-bottom: 5px;
        }

        i {
          font-size: 20px;
        }
      }
    }
  }

  .table-toolbar {
    height: 100px !important;

    .v-toolbar__content {
      flex-wrap: wrap !important;

      span {
        font-size: 14px !important;
      }
    }
  }
}

.user-dropdown {
  position: relative;
  display: flex;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 8px;
}

.dropdown {
  position: relative;
}

.dropdown-toggle {
  cursor: pointer;
  background: none;
  border: none;
  font-size: 16px;
  color: white;
  display: flex;
  align-items: center;
}

.dropdown-content {
  // display: none;
  position: absolute;
  left: -16px;
  top: 100px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  min-width: 160px;
}

.dropdown-content a {
  padding: 12px;
  text-decoration: none;
  display: block;
  color: #333;
}

.dropdown-content a:hover {
  background-color: #f5f5f5;
}
</style>
