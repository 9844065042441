<template>
  <div>
    <ProcessGroup @getData="reloadPage" :selectedItems="selected_requests" type="need_to_be_returned_new" />
    <div v-if="!this.$store.state.needTurnNewLoading" style="margin-top: 40px">
      <div v-if="$store.state.views === 'cards'">
        <div v-if="this.$store.state.needTurnNewData.length > 0">
          <RequestsCard
            :CardData="this.$store.state.needTurnNewData"
            v-if="!this.$store.state.needTurnNewLoading"
            @pageCount="handlePageNumber"
            @back="handleBack"
          />
          <div>
            <LoadingMore v-if="loadMoreData" style="margin: 40px" />
          </div>
        </div>
        <div v-else>
          <div v-if="this.$store.state.isSearch">
            <button
              @click="handleBack"
              style="
                width: 70px;
                height: 40px;
                border-radius: 5px;
                margin: 50px;
                color: white;
                background-color: #441545;
                direction: ltr;
              "
            >
              رجوع
            </button>
          </div>
          <div class="noData">لا يوجد بيانات</div>
        </div>
      </div>
      <div v-else-if="$store.state.views === 'table'" class="table__view">
        <table-component
          :showSelect="true"
          itemKey="id"
          :selectedRows.sync="selected_requests"
          :headers="headers"
          :pagination="this.$store.state.pagination"
          :limit="$store.state.counterOfShow"
          :items="this.$store.state.tableData"
          :loading="loading"
          @refresh="getTableData"
          v-model="page"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="#3f0e40" small depressed dark v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <SingleProccessInNew :item="item" />
              </v-list>
            </v-menu>
          </template>

          <template slot="item.quality_notes" slot-scope="props">
            <span v-if="!props.item.quality_notes"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.quality_notes.length > 35
                    ? props.item.quality_notes.substring(0, 35) + "..."
                    : props.item.quality_notes
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.quality_notes }}</div>
            </v-tooltip>
          </template>

          <template slot="item.agent_notes" slot-scope="props">
            <span v-if="!props.item.agent_notes"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.agent_notes.length > 35
                    ? props.item.agent_notes.substring(0, 35) + "..."
                    : props.item.agent_notes
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.agent_notes }}</div>
            </v-tooltip>
          </template>
        </table-component>
        <OrderRequestSection
          v-if="this.$store.state.openOrderRequestSection"
          style="margin-top: 50px"
        />
      </div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
      "
      v-else
    >
      <LoadingMore />
    </div>
    <NewQualitySearch type="need_to_be_returned_new" />
    <RefuseRequest @handleRefaund="reloadPage" />
    <QualityDialog :selectedItems="selected_requests" />
    <AdminDialog
      @reload="reloadPage"
      @selectedValue="selectedValue"
      :items="this.$store.state.allStatusAgent"
      label="استشاري المبيعات"
    />
    <PendingCondition />
    <TurnuserDialog
      :selectedItems="selected_requests"
      type="need_to_be_returned_new"
      @reloadPage="reloadPage"
    />
    <OrderRequestDialog />
    <CallsDialog />
  </div>
</template>

<script>
import Axios from "axios";
import RequestsCard from "@/components/Charts/RequestsCard.vue";
import LoadingMore from "@/components/Charts/LoadingMore.vue";
import CallsDialog from "@/components/Charts/CallsDialog.vue";
import { formatDate } from "@/mixins/formatDate";
import AdminDialog from "@/components/AdminComponent/AdminDialog.vue";
import ProcessGroup from "@/views/admin/Requests/MoveToNew/ProcessGroup.vue";
import QualityDialog from "@/components/AdminComponent/QualityDialog.vue";
import PendingCondition from "@/views/admin/Requests/pending/PendingCondition.vue";
import RefuseRequest from "./RefuseRequest.vue";
import NewQualitySearch from "@/views/admin/Requests/QualityNew/NewQualitySearch.vue";
import SingleProccessInNew from "@/views/admin/Requests/QualityNew/SingleProccessInNew.vue";
import TurnuserDialog from "@/components/AdminComponent/TurnuserDialog.vue";
import OrderRequestDialog from "@/components/AdminComponent/OrderRequestDialog.vue";
import OrderRequestSection from "@/components/AdminComponent/OrderRequestSection.vue";

export default {
  name: "AdminSupport",
  components: {
    SingleProccessInNew,
    NewQualitySearch,
    RefuseRequest,
    TurnuserDialog,
    PendingCondition,
    QualityDialog,
    ProcessGroup,
    AdminDialog,
    RequestsCard,
    LoadingMore,
    OrderRequestDialog,
    OrderRequestSection,
    CallsDialog,
  },

  data() {
    return {
      page: 1,
      loading: false,
      copySupportRequests: [],
      loadMoreData: false,
      loadingData: false,
      selected_requests: [],
      allGetData: [],
      orderRequestId: null,
      headers: [
        { align: "center", value: "data-table-select" },
        { text: "تاريخ الطلب", value: "تاريخ الطلب", align: "center" },
        { text: "حاله الطلب", value: "حاله الطلب", align: "center" },
        { text: "الجوده", value: "الجوده", width: "120px", align: "center" },
        { text: "استشاري المبيعات", value: "استشاري المبيعات", align: "center" },
        { text: "عميل", value: "عميل", width: "130px", align: "center" },
        { text: "الجوال", value: "الجوال", align: "center" },
        {
          text: "تصيف الاستشاري",
          value: "تصيف الاستشاري",
          width: "120px",
          align: "center",
        },
        {
          text: "الملاحظة استشاري المبيعات",
          value: "agent_notes",
          width: "150px",
          align: "center",
        },
        {
          text: "ملاحظة استشاري الجودة",
          value: "quality_notes",
          width: "150px",
          align: "center",
        },
        { text: "", value: "action", align: "center" },
      ],
    };
  },
  beforeDestroy() {
    this.$store.state.openOrderRequestSection = false;
  },

  watch: {
    page(val) {
      if (this.$store.state.isSearch === false) {
        this.getTableData(val);
      } else {
        this.searchRequest(val, "table");
      }
    },

    selected_requests(val) {
      this.selectedItems = val;
      this.$emit("requests", val);
    },

    "$store.state.counterOfShow": {
      handler(newval, oldvalue) {
        if (newval != oldvalue) {
          this.getTableData(this.page);
        }
      },
    },

    $route: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.state.counterOfShow = 10;
        }
      },
    },
  },

  methods: {
    handleBack() {
      this.$store.state.needTurnNewData = [];
      this.$store.state.tableData = [];
      this.$store.state.needTurnNewLoading = true;
      this.$store.state.isSearch = false;
      this.$store.state.back = false;
      this.getAllRequests(1, "back");
    },
    
    handlePageNumber(val) {
      if (this.$store.state.isSearch === false) {
        this.getAllRequests(val);
      } else {
        this.searchRequest(val);
      }
    },
    searchRequest(page, type) {
      this.loading = true;

      if (type === "table") {
        this.$store.state.tableData = [];
      }

      if (page > 1 || type === "table") {
        this.$store.state.bodySearch.delete("page");
        this.$store.state.bodySearch.append("page", page);

        let url = `${this.$store.state.url}/v2/employee/Requests/search?type=need_to_be_returned_new`;
        Axios.post(url, this.$store.state.bodySearch, {
          headers: this.request_headers(),
        }).then((res) => {
          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.searched,
          };
          let response = res.data.payload.data;
          response.map((item) => {
            if (item.length === 0) {
              this.loading = false;
            }
            this.setTbData(item);
            item["starChecked"] = false;
            return item;
          });

          this.$store.state.needTurnNewSearch.push(...response);
          this.removeDublicate(this.$store.state.needTurnNewSearch);
          this.loading = false;
        });
      }
    },

    selectedValue(itemSelected) {
      this.getAgentRequest(itemSelected);
    },

    getAgentRequest(itemSelected) {
      let body = new FormData();

      body.append("request_ids[0]", this.$store.state.selectId);
      body.append("agents_ids[0]", itemSelected.id);

      Axios.post(
        `${this.$store.state.url}/v2/employee/Requests/moveNeedToBeTurnedQualityReqNew`,
        body,
        { headers: this.request_headers() }
      )
        .then((res) => {
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          let error = { err };
          let message = error.err.response.data.message[0];
          this.showPopup("error", message);
        });
    },

    getTableData(count) {
      this.$store.state.tableData = [];
      this.loading = true;
      Axios.get(
        `${this.$store.state.url}/v2/employee/Requests?type=need_to_be_returned_new&page=${count}&per_page=${this.$store.state.counterOfShow}`,
        { headers: this.request_headers() }
      ).then((res) => {
        res.data.payload.data.map((item) => {
          this.setTbData(item);
        });
        this.loading = false;
      });
    },

    setTbData(items) {
      this.$store.state.tableData.push({
        id: items.id,
        request: {
          id: items.request.id,
        },
        "تاريخ الطلب": formatDate(items.quality_request.request?.created_at),
        "حاله الطلب": items.status,
        الجوده: items.qualityUser.name,
        "استشاري المبيعات": items.quality_request.request.user?.name,
        عميل: items.quality_request.request.customer.name,
        الجوال: items.quality_request.request.customer.mobile,
        "تصيف الاستشاري": items.quality_request.request.class_id_agent_text,
        agent_notes: items.quality_request.request.agent_notes,
        quality_notes: items.quality_request.request.quality_notes,
      });
    },

    returnValue(item) {
      if (item === 0 || !item || item === "no") {
        return "لا";
      } else {
        return "نعم";
      }
    },

    getAllRequests(count) {
      if (
        this.$store.state.needTurnNewData.length === 0 &&
        this.copySupportRequests.length === 0
      ) {
        this.$store.state.needTurnNewLoading = true;
      }
      this.loadMoreData = true;
      Axios.get(
        `https://api.alwsatarealestate.com/api/v2/employee/Requests?type=need_to_be_returned_new&page=${count}&per_page=${this.$store.state.counterOfShow}`,
        { headers: this.request_headers() }
      )
        .then((res) => {
          let response = res.data.payload.data;
          response.map((item) => {
            this.setTbData(item);
            item["starChecked"] = false;
            return item;
          });

          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.count_of_need_to_be_returned_new,
          };
          this.allGetData.push(...response);
          this.removeDublicate(this.allGetData);
          this.copySupportRequests.push(...response);
          this.$store.state.needTurnNewLoading = false;
        })
        .catch((err) => {
          this.loadingData = false;
          this.loadMoreData = false;
        })
        .finally(() => {
          this.loadMoreData = false;
          this.loadingData = false;
        });
    },

    reloadPage() {
      this.$store.state.needTurnNewData = [];
      this.$store.state.tableData = [];
      this.allGetData = [];
      this.$store.state.needTurnNewLoading = true;
      this.$store.state.isSearch = false;
      this.getAllRequests(1);
      this.$store.state.startElement = [];
    },

    removeDublicate(array) {
      let uniqueArray = array.filter((item, index) => {
        return (
          index ===
          array.findIndex((obj) => {
            return obj.id === item.id; // Compare based on the 'id' property
          })
        );
      });
      this.$store.state.needTurnNewData = uniqueArray;
    },
  },

  created() {
    this.$store.state.needTurnNewData = [];
    this.$store.state.tableData = [];
    this.$store.state.isSearch = false;
    this.$store.state.back = false;
    this.$store.state.bodySearch = new FormData();
    this.getAllRequests(1);
    this.$store.state.startElement = [];
    this.$store.state.views = "cards";
  },
};
</script>

<style>
.noData {
  display: flex;
  justify-content: center;
  font-size: 22px;
  color: #3f0e40;
  margin-top: 70px;
  font-weight: bold;
}
</style>

//fix group proccess //make page reload after turn request
