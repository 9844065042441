<template>
  <page-component style="position: relative" class="agent-data-page">
    <!-- eslint-disable -->
    <template #content>
      <v-btn
        fab
        :color="$vuetify.theme.themes.light.dialogActionBTN"
        @click="addNewRequestDialog = true"
        class="my-10"
        style="position: fixed; left: 20px; bottom: 0px; z-index: 3"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25.425"
          height="21.166"
          viewBox="0 0 25.425 21.166"
        >
          <g
            id="Icon_feather-user-plus"
            data-name="Icon feather-user-plus"
            transform="translate(1 1)"
          >
            <path
              id="Path_2554"
              data-name="Path 2554"
              d="M17.471,28.889v-2.13A4.259,4.259,0,0,0,13.212,22.5H5.759A4.259,4.259,0,0,0,1.5,26.759v2.13"
              transform="translate(-1.5 -9.723)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path
              id="Path_2555"
              data-name="Path 2555"
              d="M15.268,8.759A4.259,4.259,0,1,1,11.009,4.5,4.259,4.259,0,0,1,15.268,8.759Z"
              transform="translate(-3.023 -4.5)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path
              id="Path_2556"
              data-name="Path 2556"
              d="M30,12v6.389"
              transform="translate(-9.77 -6.676)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path
              id="Path_2557"
              data-name="Path 2557"
              d="M31.889,16.5H25.5"
              transform="translate(-8.464 -7.982)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </g>
        </svg>
      </v-btn>
      <add-new-request
        v-model="addNewRequestDialog"
        width="350px"
        @submitted="get_all_requests"
      />

      <!-- بيانات العميل -->
      <!-- <v-sheet class="px-5 py-5">
        <span class="font-weight-bold" style="display: inline">بيانات العميل</span> -->
      <!-- <v-btn color="primary" style="display: inline-flex; float: left" class="mt-n2">
          <v-icon>mdi-printer-outline</v-icon>
          طباعة استمارة تفريغ طلب
          </v-btn> -->

      <v-form class="mt-5 mb-5" :disabled="!for_agent">
        <v-card flat>
          <v-layout
            row
            wrap
            style="background-color: #f3f5f7"
            class="userProfile__container"
          >
            <v-col cols="12" lg="4" md="4" sm="6" xs="12">
              <v-card class="pa-3 position-relative userProfile__card">
                <v-col cols="12" sm="12">
                  <div style="display: flex" class="input__group">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('customerName')"
                      />
                      إسم العميل
                    </div>
                    <div class="horizontal__line"></div>
                    <div>
                      <v-text-field
                        type="text"
                        class="form-control"
                        style="
                          background-color: #fbfbfb;
                          border-right: #fbfbfb;
                          margin-top: -20px;
                        "
                        v-model="agent.customer.name"
                        ref="customerName"
                      />
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" sm="12">
                  <div style="display: flex; position: relative" class="input__group">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('mobile')"
                      />
                      جوال العميل
                    </div>
                    <div class="horizontal__line"></div>
                    <div>
                      <v-text-field
                        type="text"
                        class="form-control"
                        style="
                          background-color: #fbfbfb;
                          border-right: #fbfbfb;
                          margin-top: -20px;
                        "
                        v-model="agent.customer.mobile"
                        v-numericOnly
                      />

                      <img
                        src="@/assets/icons/add-new.svg"
                        alt="image"
                        @click="showNums = !showNums"
                        style="
                          position: absolute;
                          left: 35px;
                          top: 9px;
                          width: 16px;
                          height: 25px;
                          z-index: 1;
                        "
                      />
                      <img
                        style="
                          cursor: pointer;
                          position: absolute;
                          left: 10px;
                          top: 10px;
                          z-index: 1;
                        "
                        @click="secondNumber = !secondNumber"
                        src="@/assets/add-num.png"
                        alt=""
                      />
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" sm="12">
                  <div style="display: flex; position: relative" class="input__group">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('age')"
                      />
                      العمر
                    </div>
                    <div class="horizontal__line"></div>
                    <div>
                      <v-text-field
                        type="text"
                        class="form-control"
                        style="
                          background-color: #fbfbfb;
                          border-right: #fbfbfb;
                          margin-top: -20px;
                        "
                        v-model="agent.customer.age"
                        v-numericOnly
                      />
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" sm="12">
                  <div style="display: flex; position: relative" class="input__group">
                    <div style="display: flex; align-items: center">
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('salary')"
                      />
                      صافي الراتب
                    </div>
                    <div class="horizontal__line"></div>
                    <div>
                      <v-text-field
                        type="text"
                        class="form-control"
                        style="
                          background-color: #fbfbfb;
                          border-right: #fbfbfb;
                          margin-top: -20px;
                        "
                        v-model="agent.customer.salary"
                        v-numericOnly
                      />
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" sm="12">
                  <div style="display: flex; position: relative" class="input__group">
                    <div style="display: flex; align-items: center">
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('basic_salary')"
                      />
                      الراتب الأساسي
                    </div>
                    <div class="horizontal__line"></div>
                    <div>
                      <v-text-field
                        type="text"
                        class="form-control"
                        style="
                          background-color: #fbfbfb;
                          border-right: #fbfbfb;
                          margin-top: -20px;
                        "
                        v-model="agent.customer.basic_salary"
                        v-numericOnly
                      />
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" sm="12">
                  <div style="display: flex; align-items: center" class="input__group">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('sex')"
                      />
                      الجنس
                    </div>
                    <div class="horizontal__line"></div>

                    <v-autocomplete
                      :items="['أنثي', 'ذكر']"
                      v-model="sex"
                      style="margin-top: 15px"
                      outlined
                      dense
                    >
                      <template v-slot:item="{ item, attrs, on }">
                        <v-list-item v-bind="attrs" v-on="on">
                          <v-list-item-title
                            :id="attrs['aria-labelledby']"
                            v-text="item"
                          ></v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    <img src="@/assets/icons/down-arrow.svg" />
                  </div>
                </v-col>
              </v-card>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12">
              <v-card class="pa-3 position-relative userProfile__card">
                <v-col cols="12" sm="12">
                  <div style="display: flex" class="input__group">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('agent_identity_number')"
                      />
                      رقم الهويه
                    </div>
                    <div class="horizontal__line"></div>
                    <div>
                      <v-text-field
                        type="text"
                        class="form-control"
                        style="
                          background-color: #fbfbfb;
                          border-right: #fbfbfb;
                          margin-top: -20px;
                        "
                        v-model="agent.agent_identity_number"
                        v-numericOnly
                      />
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" sm="12" v-if="!chowHigriDate">
                  <div style="display: flex; align-items: center" class="input__group">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('birth_hijri')"
                      />
                      تاريخ الميلاد (ميلادي)
                    </div>
                    <div class="horizontal__line"></div>

                    <v-text-field
                      type="date"
                      style="margin-bottom: 5px"
                      hide-details
                      v-model="agent.customer.birth_date"
                      class="mr-1"
                      dense
                      append-icon
                    >
                      <template v-slot:append>
                        <svg
                          id="calendar"
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.396"
                          height="16.989"
                          viewBox="0 0 17.396 16.989"
                          style="margin-top: 15px; cursor: pointer"
                        >
                          <path
                            id="Path_2784"
                            data-name="Path 2784"
                            d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                            transform="translate(-4.21 -7.917)"
                            fill="#6c757d"
                          />
                          <path
                            id="Path_2785"
                            data-name="Path 2785"
                            d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                            transform="translate(-4.21 -4.86)"
                            fill="#6c757d"
                          />
                        </svg>

                        <img
                          src="@/assets/icons/alternative.svg"
                          alt="img"
                          style="margin-right: 10px; cursor: pointer"
                          @click="chowHigriDate = !chowHigriDate"
                        />
                      </template>
                    </v-text-field>
                  </div>
                </v-col>

                <v-col cols="12" sm="12" v-if="chowHigriDate">
                  <div style="display: flex; align-items: center" class="input__group">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('birth_hijri')"
                      />
                      تاريخ الميلاد (هجري)
                    </div>
                    <div class="horizontal__line"></div>

                    <v-text-field
                      style="margin-bottom: 5px"
                      hide-details
                      v-model="agent.customer.birth_date_higri"
                      class="mr-1"
                      dense
                      append-icon
                    >
                      <template v-slot:append>
                        <svg
                          @click="showHiringBirthDate = !showHiringBirthDate"
                          id="calendar"
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.396"
                          height="16.989"
                          viewBox="0 0 17.396 16.989"
                          style="margin-top: 15px; cursor: pointer"
                        >
                          <path
                            id="Path_2784"
                            data-name="Path 2784"
                            d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                            transform="translate(-4.21 -7.917)"
                            fill="#6c757d"
                          />
                          <path
                            id="Path_2785"
                            data-name="Path 2785"
                            d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                            transform="translate(-4.21 -4.86)"
                            fill="#6c757d"
                          />
                        </svg>
                        <img
                          src="@/assets/icons/alternative.svg"
                          alt="img"
                          style="margin-right: 10px; cursor: pointer"
                          @click="chowHigriDate = !chowHigriDate"
                        />
                      </template>
                    </v-text-field>
                    <v-hijri-date-picker
                      @change="showHiringBirthDate = false"
                      v-if="showHiringBirthDate"
                      style="position: absolute; z-index: 1000"
                      v-model="agent.customer.birth_date_higri"
                      locale="ar"
                    />
                  </div>
                </v-col>

                <v-col cols="12" sm="12">
                  <div style="display: flex; align-items: center" class="input__group">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('joint_hiring_date')"
                      />
                      تاريخ التعيين
                    </div>
                    <div class="horizontal__line"></div>

                    <v-text-field
                      style="margin-bottom: 5px"
                      hide-details
                      v-model="agent.customer.hiring_date"
                      class="mr-1"
                      dense
                      append-icon
                    >
                      <template v-slot:append>
                        <svg
                          @click="showHiringDate = !showHiringDate"
                          id="calendar"
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.396"
                          height="16.989"
                          viewBox="0 0 17.396 16.989"
                          style="margin-top: 15px"
                        >
                          <path
                            id="Path_2784"
                            data-name="Path 2784"
                            d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                            transform="translate(-4.21 -7.917)"
                            fill="#6c757d"
                          />
                          <path
                            id="Path_2785"
                            data-name="Path 2785"
                            d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                            transform="translate(-4.21 -4.86)"
                            fill="#6c757d"
                          />
                        </svg>
                      </template>
                    </v-text-field>
                    <v-hijri-date-picker
                      @change="showHiringDate = false"
                      v-if="showHiringDate"
                      style="position: absolute; z-index: 1000"
                      v-model="agent.customer.hiring_date"
                      locale="ar"
                    />
                  </div>
                </v-col>

                <v-col cols="12" sm="12">
                  <div style="display: flex; align-items: center" class="input__group">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('work')"
                      />
                      جهة العمل
                    </div>
                    <div class="horizontal__line"></div>

                    <v-autocomplete
                      :items="$store.state.works_list"
                      hide-details
                      v-model="agent.customer.work"
                      item-text="value"
                      item-value="id"
                      style="border: none !important"
                      class="mr-1"
                      outlined
                      dense
                    >
                    </v-autocomplete>
                    <img src="@/assets/icons/down-arrow.svg" />
                  </div>
                </v-col>

                <v-col cols="12" sm="12" v-if="agent.customer.work == 1">
                  <div style="display: flex; align-items: center" class="input__group">
                    <div class="mb-2">
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('askaryWork')"
                      />
                      الجهة
                    </div>
                    <div class="horizontal__line"></div>
                    <v-autocomplete
                      :items="$store.state.military_works"
                      hide-details
                      v-model="agent.customer.askary_id"
                      style="border: none !important"
                      item-text="value"
                      item-value="id"
                      class="mr-1"
                      outlined
                      dense
                    >
                    </v-autocomplete>
                    <img src="@/assets/icons/down-arrow.svg" />
                  </div>
                </v-col>

                <v-col cols="12" sm="12" v-if="agent.customer.work == 1">
                  <div style="display: flex; align-items: center" class="input__group">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('rank')"
                      />
                      الرتبة العسكرية
                    </div>
                    <div class="horizontal__line"></div>

                    <v-autocomplete
                      :items="$store.state.military_ranks"
                      hide-details
                      v-model="agent.customer.military_rank"
                      item-text="value"
                      item-value="id"
                      style="border: none !important"
                      class="mr-1"
                      outlined
                      dense
                    >
                    </v-autocomplete>
                    <img src="@/assets/icons/down-arrow.svg" />
                  </div>
                </v-col>

                <v-col cols="12" sm="12" v-if="agent.customer.work == 2">
                  <div style="display: flex; align-items: center" class="input__group">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('madanyWork')"
                      />
                      الجهة
                    </div>
                    <div class="horizontal__line"></div>

                    <v-autocomplete
                      :items="$store.state.madany_works"
                      hide-details
                      v-model="agent.customer.madany_id"
                      item-text="value"
                      item-value="id"
                      style="border: none !important"
                      class="mr-1"
                      outlined
                      dense
                    >
                    </v-autocomplete>
                    <img src="@/assets/icons/down-arrow.svg" />
                  </div>
                </v-col>

                <v-col cols="12" sm="12" v-if="agent.customer.work == 2">
                  <div style="display: flex; position: relative" class="input__group">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('jobTitle')"
                      />
                      المنصب الوظيفي
                    </div>
                    <div class="horizontal__line"></div>
                    <div>
                      <v-text-field
                        type="text"
                        class="form-control"
                        style="
                          background-color: #fbfbfb;
                          border-right: #fbfbfb;
                          margin-top: -20px;
                        "
                        v-model="agent.customer.job_title"
                      />
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" sm="12">
                  <div style="display: flex; align-items: center" class="input__group">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('salary_source')"
                      />
                      جهة نزول المرتب
                    </div>
                    <div class="horizontal__line"></div>

                    <v-autocomplete
                      :items="$store.state.salary_sources"
                      item-text="value"
                      item-value="id"
                      hide-details
                      v-model="agent.customer.salary_id"
                      class="mr-1"
                      outlined
                      dense
                    >
                    </v-autocomplete>
                    <img src="@/assets/icons/down-arrow.svg" />
                  </div>
                </v-col>
              </v-card>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12">
              <v-card class="px-3 position-relative userProfile__card">
                <div class="switch__container">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-left: 5px"
                      @click="show_Field_log('support')"
                    />
                    هل هو مدعوم
                  </div>
                  <div>
                    <v-radio-group row v-model="agent.customer.is_supported">
                      <v-radio value="yes" label="نعم"></v-radio>
                      <v-radio value="no" label="لا"></v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />

                <div class="switch__container">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-left: 5px"
                      @click="show_Field_log('without_transfer_salary')"
                    />
                    بدون تحويل الراتب
                  </div>
                  <div>
                    <v-radio-group v-model="agent.customer.without_transfer_salary" row>
                      <v-radio :value="1" label="نعم"></v-radio>
                      <v-radio :value="0" label="لا"></v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />

                <div class="switch__container">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-left: 5px"
                      @click="show_Field_log('add_support_installment_to_salary')"
                    />
                    إضافة قسط الدعم إلى الراتب
                  </div>
                  <div>
                    <v-radio-group
                      row
                      v-model="agent.customer.add_support_installment_to_salary"
                    >
                      <v-radio :value="1" label="نعم"></v-radio>
                      <v-radio :value="0" label="لا"></v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />

                <div class="switch__container">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-left: 5px"
                      @click="show_Field_log('guarantees')"
                    />
                    الضمانات
                  </div>
                  <div>
                    <v-radio-group row v-model="agent.customer.guarantees">
                      <v-radio :value="1" label="نعم"></v-radio>
                      <v-radio :value="0" label="لا"></v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />

                <div class="switch__container">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-left: 5px"
                      @click="show_Field_log('obligations')"
                    />
                    هل لديه التزامات
                  </div>
                  <div>
                    <v-radio-group row v-model="agent.customer.has_obligations">
                      <v-radio value="yes" label="نعم"></v-radio>
                      <v-radio value="no" label="لا"></v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />

                <div class="switch__container">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-left: 5px"
                      @click="show_Field_log('distress')"
                    />
                    هل لديه تعثرات
                  </div>
                  <div>
                    <v-radio-group row v-model="agent.customer.has_financial_distress">
                      <v-radio value="yes" label="نعم"></v-radio>
                      <v-radio value="no" label="لا"></v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />

                <v-col cols="12" sm="12" v-if="agent.customer.has_obligations === 'yes'">
                  <div style="display: flex; position: relative" class="input__group">
                    <div style="display: flex; align-items: center">
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('obligations_value')"
                      />
                      قيمة الإلتزامات
                    </div>
                    <div class="horizontal__line"></div>
                    <div>
                      <v-text-field
                        type="text"
                        class="form-control"
                        style="
                          background-color: #fbfbfb;
                          border-right: #fbfbfb;
                          margin-top: -20px;
                        "
                        v-model="agent.customer.obligations_value"
                        v-numericOnly
                      />
                    </div>
                  </div>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  v-if="agent.customer.has_financial_distress === 'yes'"
                >
                  <div style="display: flex; position: relative" class="input__group">
                    <div style="display: flex; align-items: center">
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('financial_distress_value')"
                      />
                      قيمة التعثرات
                    </div>
                    <div class="horizontal__line"></div>
                    <div>
                      <v-text-field
                        type="text"
                        class="form-control"
                        style="
                          background-color: #fbfbfb;
                          border-right: #fbfbfb;
                          margin-top: -20px;
                        "
                        v-model="agent.customer.financial_distress_value"
                        v-numericOnly
                      />
                    </div>
                  </div>
                </v-col>
              </v-card>
            </v-col>
          </v-layout>
        </v-card>
      </v-form>

      <v-dialog v-if="0" v-model="secondNumber" max-width="540px">
        <v-card class="pl-3">
          <div class="d-flex justify-space-between align-center">
            <v-card-title> اضافة رقم هاتف</v-card-title>
            <v-icon style="cursor: pointer" @click="secondNumber = false"
              >mdi-close</v-icon
            >
          </div>
          <v-card-text>
            <v-text-field
              hide-details
              class="mr-1"
              outlined
              dense
              v-model="secNum"
              @keydown="confirmNum.err = true"
            ></v-text-field>
            <div class="d-flex justify-content-center">
              <v-btn class="mt-5 success white--text d-block" @click="addNumber">{{
                verifing ? "جاري التحقق ..." : "اضافة"
              }}</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-if="0" v-model="showNums" max-width="540px">
        <v-card v-if="numbersArray.length > 0" class="pb-3 pl-3 pr-3">
          <div class="d-flex justify-space-between align-center">
            <v-card-title> ارقام الهاتف</v-card-title>
            <v-icon style="cursor: pointer" @click="showNums = false">mdi-close</v-icon>
          </div>
          <div class="d-flex justify-start align-center flex-wrap">
            <span
              style="
                background: rgb(60 89 208);
                color: white;
                padding: 7px 9px 9px;
                border-radius: 10px;
                font-size: 16px;
                margin: 10px;
                letter-spacing: 1px;
              "
              v-for="(num, i) in numbersArray"
              :key="i"
              >{{ num.mobile }}</span
            >
          </div>
        </v-card>
        <v-card v-else class="text-center pt-3">
          <v-card-text style="font-size: 17px"
            >لم يتم اضافة ارقام هاتف حتي الان</v-card-text
          >
        </v-card>
      </v-dialog>

      <v-sheet class="px-3 pb-3 pt-3">
        <v-form :disabled="!for_agent">
          <!-- <v-row>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-1 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('customerName')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>

                اسم العميل
              </div>
              <v-text-field
                ref="customerName"
                hide-details
                v-model="agent.customer.name"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-1 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('agent_identity_number')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                رقم الهويه
              </div>
              <v-text-field
                hide-details
                v-model="agent.agent_identity_number"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-1 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('sex')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                الجنس
              </div>
              <v-radio-group v-model="agent.customer.sex" row class="mr-n4 px-0">
                <v-radio value="male" label="ذكر"></v-radio>
                <v-radio value="female" label="أنثي"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-1 py-0">
              <div class="mb-2 d-flex justify-space-between align-items-center">
                <div>
                  <svg
                    @click="show_Field_log('mobile')"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.76"
                    height="12.964"
                    viewBox="0 0 15.76 12.964"
                    style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                  >
                    <path
                      id="Icon_material-history"
                      data-name="Icon material-history"
                      d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                      transform="translate(-1.5 -4.5)"
                    />
                  </svg>
                  جوال العميل
                </div>
                <div style="display: flex; align-items: center; gap: 10px">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.528"
                    height="10.111"
                    viewBox="0 0 13.528 10.111"
                    style="cursor: pointer; margin-top: 3px"
                    @click="showNums = !showNums"
                  >
                    <g
                      id="Icon_feather-eye-dark"
                      data-name="Icon feather-eye-dark"
                      transform="translate(-1 -5.5)"
                    >
                      <path
                        id="Path_4040"
                        data-name="Path 4040"
                        d="M1.5,10.556S3.778,6,7.764,6s6.264,4.556,6.264,4.556-2.278,4.556-6.264,4.556S1.5,10.556,1.5,10.556Z"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                      />
                      <path
                        id="Path_4041"
                        data-name="Path 4041"
                        d="M16.917,15.208A1.708,1.708,0,1,1,15.208,13.5,1.708,1.708,0,0,1,16.917,15.208Z"
                        transform="translate(-7.444 -4.653)"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                      />
                    </g>
                  </svg>
                  <img
                    style="cursor: pointer"
                    @click="secondNumber = !secondNumber"
                    src="@/assets/add-num.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="d-flex">
                <v-text-field
                  readonly
                  hide-details
                  v-model="agent.customer.mobile"
                  class="mr-1"
                  outlined
                  dense
                ></v-text-field>
                <v-dialog v-model="secondNumber" max-width="540px">
                  <v-card class="pl-3">
                    <div class="d-flex justify-space-between align-center">
                      <v-card-title> اضافة رقم هاتف</v-card-title>
                      <v-icon style="cursor: pointer" @click="secondNumber = false"
                        >mdi-close</v-icon
                      >
                    </div>
                    <v-card-text>
                      <v-text-field
                        hide-details
                        class="mr-1"
                        outlined
                        dense
                        v-model="secNum"
                        @keydown="confirmNum.err = true"
                      ></v-text-field>
                      <div class="d-flex justify-content-center">
                        <v-btn
                          class="mt-5 success white--text d-block"
                          @click="addNumber"
                          >{{ verifing ? "جاري التحقق ..." : "اضافة" }}</v-btn
                        >
                      </div>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="showNums" max-width="540px">
                  <v-card v-if="numbersArray.length > 0" class="pb-3 pl-3 pr-3">
                    <div class="d-flex justify-space-between align-center">
                      <v-card-title> ارقام الهاتف</v-card-title>
                      <v-icon style="cursor: pointer" @click="showNums = false"
                        >mdi-close</v-icon
                      >
                    </div>
                    <div class="d-flex justify-start align-center flex-wrap">
                      <span
                        style="
                          background: rgb(60 89 208);
                          color: white;
                          padding: 7px 9px 9px;
                          border-radius: 10px;
                          font-size: 16px;
                          margin: 10px;
                          letter-spacing: 1px;
                        "
                        v-for="(num, i) in numbersArray"
                        :key="i"
                        >{{ num.mobile }}</span
                      >
                    </div>
                  </v-card>
                  <v-card v-else class="text-center pt-3">
                    <v-card-text style="font-size: 17px"
                      >لم يتم اضافة ارقام هاتف حتي الان</v-card-text
                    >
                  </v-card>
                </v-dialog>
              </div>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2 d-flex justify-space-between align-items-center">
                <div>
                  <svg
                    @click="show_Field_log('birth_hijri')"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.76"
                    height="12.964"
                    viewBox="0 0 15.76 12.964"
                    style="margin-left: 6px; cursor: pointer; margin-bottom: -2px"
                  >
                    <path
                      id="Icon_material-history"
                      data-name="Icon material-history"
                      d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                      transform="translate(-1.5 -4.5)"
                    />
                  </svg>
                  <span v-if="customer_birth_date">تاريخ الميلاد (ميلادي)</span>
                  <span v-else>تاريخ الميلاد (هجري)</span>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13.558"
                  height="13.156"
                  viewBox="0 0 13.558 13.156"
                  @click="customer_birth_date = !customer_birth_date"
                  style="cursor: pointer; margin-top: 8px"
                >
                  <g id="compare" transform="translate(13.558) rotate(90)">
                    <path
                      id="Path_3990"
                      data-name="Path 3990"
                      d="M.7,3.534l.916-.954L3.06,1.073,3.392.727h-.58l.916.954L5.174,3.188l.332.346a.4.4,0,0,0,.58,0,.447.447,0,0,0,0-.6L5.17,1.976,3.724.469,3.392.123a.4.4,0,0,0-.58,0L1.9,1.077.45,2.584.118,2.93a.446.446,0,0,0,0,.6.4.4,0,0,0,.58,0Z"
                      transform="translate(0 0.003)"
                    />
                    <path
                      id="Path_3991"
                      data-name="Path 3991"
                      d="M0,.428v10c0,.135,0,.272,0,.407v.017a.424.424,0,0,0,.41.427.426.426,0,0,0,.41-.427v-10c0-.135,0-.272,0-.407V.428A.424.424,0,0,0,.411,0,.426.426,0,0,0,0,.428Zm4.378,10.2.916.954,1.446,1.507.332.346a.4.4,0,0,0,.58,0l.916-.954,1.446-1.507.332-.346a.446.446,0,0,0,0-.6.4.4,0,0,0-.58,0l-.916.954L7.405,12.482l-.332.346h.58l-.916-.954L5.291,10.367l-.332-.346a.4.4,0,0,0-.58,0,.448.448,0,0,0,0,.6Z"
                      transform="translate(2.691)"
                    />
                    <path
                      id="Path_3992"
                      data-name="Path 3992"
                      d="M.821,10.853v-10c0-.135,0-.272,0-.407V.428A.424.424,0,0,0,.411,0,.426.426,0,0,0,0,.428v10c0,.135,0,.272,0,.407v.017a.424.424,0,0,0,.41.427.426.426,0,0,0,.41-.427Z"
                      transform="translate(9.644 2.278)"
                    />
                  </g>
                </svg>
              </div>
              <v-text-field
                type="date"
                v-if="customer_birth_date"
                hide-details
                v-model="agent.customer.birth_date"
                class="mr-1"
                outlined
                dense
                append-icon
              >
                <template v-slot:append>
                  <svg
                    id="calendar"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.396"
                    height="16.989"
                    viewBox="0 0 17.396 16.989"
                    style="margin-bottom: -6px"
                  >
                    <path
                      id="Path_2784"
                      data-name="Path 2784"
                      d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                      transform="translate(-4.21 -7.917)"
                      fill="#6c757d"
                    />
                    <path
                      id="Path_2785"
                      data-name="Path 2785"
                      d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                      transform="translate(-4.21 -4.86)"
                      fill="#6c757d"
                    />
                  </svg>
                </template>
              </v-text-field>
              <v-text-field
                style="position: relative"
                v-else
                hide-details
                v-model="agent.customer.birth_date_higri"
                class="mr-1"
                outlined
                dense
              >
                <template v-slot:append>
                  <svg
                    @click="show_customer_date = !show_customer_date"
                    id="calendar"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.396"
                    height="16.989"
                    viewBox="0 0 17.396 16.989"
                    style="margin-bottom: -6px; cursor: pointer"
                  >
                    <path
                      id="Path_2784"
                      data-name="Path 2784"
                      d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                      transform="translate(-4.21 -7.917)"
                      fill="#6c757d"
                    />
                    <path
                      id="Path_2785"
                      data-name="Path 2785"
                      d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                      transform="translate(-4.21 -4.86)"
                      fill="#6c757d"
                    />
                  </svg>
                </template>
              </v-text-field>
              <v-hijri-date-picker
                @change="show_customer_date = false"
                v-if="show_customer_date"
                style="position: absolute; z-index: 1000"
                v-model="agent.customer.birth_date_higri"
                locale="ar"
              />
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('age')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                العمر
              </div>
              <v-text-field
                hide-details
                v-model="agent.customer.age"
                v-numericOnly
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('work')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                جهة العمل
              </div>
              <v-autocomplete
                :items="$store.state.works_list"
                hide-details
                v-model="agent.customer.work"
                item-text="value"
                item-value="id"
                class="mr-1"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              lg="3"
              md="4"
              sm="6"
              xs="12"
              class="mb-5 py-0"
              v-if="agent.customer.work == 1"
            >
              <div class="mb-2">
                <svg
                  @click="show_Field_log('askaryWork')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                الجهة
              </div>
              <v-autocomplete
                :items="$store.state.military_works"
                hide-details
                v-model="agent.customer.askary_id"
                item-text="value"
                item-value="id"
                class="mr-1"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              lg="3"
              md="4"
              sm="6"
              xs="12"
              class="mb-5 py-0"
              v-if="agent.customer.work == 1"
            >
              <div class="mb-2">
                <svg
                  @click="show_Field_log('rank')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                الرتبة العسكرية
              </div>
              <v-autocomplete
                :items="$store.state.military_ranks"
                hide-details
                v-model="agent.customer.military_rank"
                item-text="value"
                item-value="id"
                class="mr-1"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              lg="3"
              md="4"
              sm="6"
              xs="12"
              class="mb-5 py-0"
              v-if="agent.customer.work == 2"
            >
              <div class="mb-2">
                <svg
                  @click="show_Field_log('madanyWork')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                الجهة
              </div>
              <v-autocomplete
                :items="$store.state.madany_works"
                hide-details
                v-model="agent.customer.madany_id"
                item-text="value"
                item-value="id"
                class="mr-1"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              lg="3"
              md="4"
              sm="6"
              xs="12"
              class="mb-5 py-0"
              v-if="agent.customer.work == 2"
            >
              <div class="mb-2">
                <svg
                  @click="show_Field_log('jobTitle')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                المنصب الوظيفي
              </div>
              <v-text-field
                hide-details
                v-model="agent.customer.job_title"
                class="mr-1"
                outlined
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('salary')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                صافي الراتب
              </div>
              <v-text-field
                hide-details
                v-model="agent.customer.salary"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-1 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('salary_source')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                جهة نزول المرتب
              </div>
              <v-autocomplete
                :items="$store.state.salary_sources"
                item-text="value"
                item-value="id"
                hide-details
                v-model="agent.customer.salary_id"
                class="mr-1"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-1 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('support')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                هل هو مدعوم
              </div>
              <v-radio-group row class="mr-n4 px-0" v-model="agent.customer.is_supported">
                <v-radio value="yes" label="نعم"></v-radio>
                <v-radio value="no" label="لا"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-1 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('basic_salary')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                الراتب الأساسي
              </div>
              <v-text-field
                hide-details
                v-model="agent.customer.basic_salary"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-1 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('without_transfer_salary')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                بدون تحويل الراتب
              </div>
              <v-radio-group
                v-model="agent.customer.without_transfer_salary"
                row
                class="mr-n4 px-0"
              >
                <v-radio :value="1" label="نعم"></v-radio>
                <v-radio :value="0" label="لا"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-1 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('add_support_installment_to_salary')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                إضافة قسط الدعم إلي الراتب
              </div>
              <v-radio-group
                row
                v-model="agent.customer.add_support_installment_to_salary"
                class="mr-n4 px-0"
              >
                <v-radio :value="1" label="نعم"></v-radio>
                <v-radio :value="0" label="لا"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-1 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('guarantees')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                الضمانات
              </div>
              <v-radio-group row v-model="agent.customer.guarantees" class="mr-n4 px-0">
                <v-radio :value="1" label="نعم"></v-radio>
                <v-radio :value="0" label="لا"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-1 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('obligations')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                هل لديه إلتزامات
              </div>
              <v-radio-group
                row
                v-model="agent.customer.has_obligations"
                class="mr-n4 px-0"
              >
                <v-radio value="yes" label="نعم"></v-radio>
                <v-radio value="no" label="لا"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-1 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('obligations_value')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                قيمة الإلتزامات
              </div>
              <v-text-field
                hide-details
                v-model="agent.customer.obligations_value"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
                :disabled="agent.customer.has_obligations == 'no'"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('distress')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                هل لديه تعثرات
              </div>
              <v-radio-group
                row
                v-model="agent.customer.has_financial_distress"
                class="mr-n4 px-0"
              >
                <v-radio value="yes" label="نعم"></v-radio>
                <v-radio value="no" label="لا"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('financial_distress_value')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                قيمة التعثرات
              </div>
              <v-text-field
                hide-details
                v-model="agent.customer.financial_distress_value"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
                :disabled="agent.customer.has_financial_distress == 'no'"
              ></v-text-field>
            </v-col>
          </v-row> -->
          <!-- بيانات المتضامن -->

          <div
            @click="openInformationJoint = !openInformationJoint"
            class="d-flex align-center justify-center py-3"
            style="
              font: 16px;
              border-radius: 4px;
              color: white;
              background-color: #451646;
              cursor: pointer;
              padding-bottom: 10px !important;
            "
          >
            بيانات المتضامن
          </div>

          <v-card flat v-if="openInformationJoint" class="mt-3">
            <v-layout
              row
              wrap
              style="background-color: #f3f5f7"
              class="userProfile__container"
            >
              <v-col cols="12" lg="4" md="4" sm="6" xs="12">
                <v-card class="pa-3 position-relative userProfile__card">
                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div style="display: flex; align-items: center">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('jointName')"
                        />
                        اسم المتضامن
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          class="form-control"
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.joint.name"
                          ref="customerName"
                        />
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div style="display: flex; align-items: center">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('jointMobile')"
                        />
                        جوال المتضامن
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          class="form-control"
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.joint.mobile"
                          v-numericOnly
                        />
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div style="display: flex; align-items: center">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('jointSalary')"
                        />
                        راتب المتضامن
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          class="form-control"
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.joint.salary"
                          v-numericOnly
                        />
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12" v-if="!showHigriDateJoint">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div style="display: flex; align-items: center">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('birth_hijri')"
                        />
                        تاريخ الميلاد (ميلادي)
                      </div>
                      <div class="horizontal__line"></div>

                      <v-text-field
                        type="date"
                        style="margin-bottom: 5px"
                        hide-details
                        v-model="agent.joint.birth_date"
                        class="mr-1"
                        dense
                        append-icon
                      >
                        <template v-slot:append>
                          <svg
                            id="calendar"
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.396"
                            height="16.989"
                            viewBox="0 0 17.396 16.989"
                            style="margin-top: 15px"
                          >
                            <path
                              id="Path_2784"
                              data-name="Path 2784"
                              d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                              transform="translate(-4.21 -7.917)"
                              fill="#6c757d"
                            />
                            <path
                              id="Path_2785"
                              data-name="Path 2785"
                              d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                              transform="translate(-4.21 -4.86)"
                              fill="#6c757d"
                            />
                          </svg>

                          <img
                            src="@/assets/icons/alternative.svg"
                            alt="img"
                            style="margin-right: 10px; cursor: pointer"
                            @click="showHigriDateJoint = !showHigriDateJoint"
                          />
                        </template>
                      </v-text-field>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12" v-if="showHigriDateJoint">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div style="display: flex; align-items: center">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('birth_hijri')"
                        />
                        تاريخ الميلاد (هجري)
                      </div>
                      <div class="horizontal__line"></div>

                      <v-text-field
                        type="date"
                        style="margin-bottom: 5px"
                        hide-details
                        v-model="agent.customer.birth_date_higri"
                        class="mr-1"
                        dense
                        append-icon
                      >
                        <template v-slot:append>
                          <svg
                            @click="showHiringBirthDate = !showHiringBirthDate"
                            id="calendar"
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.396"
                            height="16.989"
                            viewBox="0 0 17.396 16.989"
                            style="margin-top: 15px"
                          >
                            <path
                              id="Path_2784"
                              data-name="Path 2784"
                              d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                              transform="translate(-4.21 -7.917)"
                              fill="#6c757d"
                            />
                            <path
                              id="Path_2785"
                              data-name="Path 2785"
                              d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                              transform="translate(-4.21 -4.86)"
                              fill="#6c757d"
                            />
                          </svg>
                          <img
                            src="@/assets/icons/alternative.svg"
                            alt="img"
                            style="margin-right: 10px; cursor: pointer"
                            @click="chowHigriDate = !chowHigriDate"
                          />
                        </template>
                      </v-text-field>
                      <v-hijri-date-picker
                        @change="showHiringBirthDate = false"
                        v-if="showHiringBirthDate"
                        style="position: absolute; z-index: 1000"
                        v-model="agent.customer.birth_date_higri"
                        locale="ar"
                      />
                    </div>
                  </v-col>
                </v-card>
              </v-col>
              <v-col cols="12" lg="4" md="4" sm="6" xs="12">
                <v-card class="pa-3 position-relative userProfile__card">
                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('jointage')"
                        />
                        العمر
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          class="form-control"
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.joint.age"
                          v-numericOnly
                        />
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('jointWork')"
                        />
                        جهة العمل
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="$store.state.works_list"
                        hide-details
                        v-model="agent.joint.work"
                        item-text="value"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12" v-if="agent.joint.work == 1">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('jointaskaryWork')"
                        />
                        الجهة
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="$store.state.military_works"
                        hide-details
                        v-model="agent.joint.askary_id"
                        item-text="value"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12" v-if="agent.joint.work == 1">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('jointRank')"
                        />
                        الرتبة العسكرية
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="$store.state.military_ranks"
                        hide-details
                        v-model="agent.joint.military_rank"
                        item-text="value"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12" v-if="agent.joint.work == 2">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('jointmadanyWork')"
                        />
                        الجهة
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="$store.state.madany_works"
                        hide-details
                        v-model="agent.joint.madany_id"
                        item-text="value"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12" v-if="agent.joint.work == 2">
                    <div style="display: flex; position: relative" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('jointJobTitle')"
                        />
                        المنصب الوظيفي
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          class="form-control"
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.joint.job_title"
                        />
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('jointsalary_source')"
                        />
                        جهة نزول المرتب
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="$store.state.salary_sources"
                        hide-details
                        v-model="agent.joint.salary_id"
                        item-text="value"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('jointfunding_source')"
                        />
                        جهة التمويل
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="$store.state.funding_sources"
                        hide-details
                        v-model="agent.joint.funding_id"
                        item-text="value"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>
                </v-card>
              </v-col>
              <v-col cols="12" lg="4" md="4" sm="6" xs="12">
                <v-card class="pa-3 position-relative userProfile__card">
                  <v-col cols="12" sm="12">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('joint_hiring_date')"
                        />
                        تاريخ التعيين
                      </div>
                      <div class="horizontal__line"></div>

                      <v-text-field
                        type="date"
                        style="margin-bottom: 5px"
                        hide-details
                        v-model="agent.joint.hiring_date"
                        class="mr-1"
                        dense
                        append-icon
                      >
                        <template v-slot:append>
                          <svg
                            id="calendar"
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.396"
                            height="16.989"
                            viewBox="0 0 17.396 16.989"
                            style="margin-top: 15px"
                          >
                            <path
                              id="Path_2784"
                              data-name="Path 2784"
                              d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                              transform="translate(-4.21 -7.917)"
                              fill="#6c757d"
                            />
                            <path
                              id="Path_2785"
                              data-name="Path 2785"
                              d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                              transform="translate(-4.21 -4.86)"
                              fill="#6c757d"
                            />
                          </svg>
                        </template>
                      </v-text-field>
                    </div>
                  </v-col>
                  <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />

                  <div class="switch__container">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-left: 5px"
                        @click="show_Field_log('jointSupport')"
                      />
                      هل هو مدعوم
                    </div>
                    <div>
                      <v-radio-group v-model="agent.joint.is_supported" row>
                        <v-radio value="yes" label="نعم"></v-radio>
                        <v-radio value="no" label="لا"></v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                  <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />

                  <div class="switch__container">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-left: 5px"
                        @click="show_Field_log('joint_add_support_installment_to_salary')"
                      />
                      إضافة قسط الدعم إلي الراتب
                    </div>
                    <div>
                      <v-radio-group
                        v-model="agent.joint.add_support_installment_to_salary"
                        row
                      >
                        <v-radio value="1" label="نعم"></v-radio>
                        <v-radio value="0" label="لا"></v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                  <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />

                  <div class="switch__container">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-left: 5px"
                        @click="show_Field_log('jointObligations')"
                      />
                      هل لديه إلتزامات
                    </div>
                    <div>
                      <v-radio-group v-model="agent.joint.has_obligations" row>
                        <v-radio value="yes" label="نعم"></v-radio>
                        <v-radio value="no" label="لا"></v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                  <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />
                  <v-col cols="12" sm="12" v-if="agent.joint.has_obligations === 'yes'">
                    <div style="display: flex" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('jointobligations_value')"
                        />
                        قيمة الإلتزامات
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          class="form-control"
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.joint.obligations_value"
                          v-numericOnly
                        />
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-col>
            </v-layout>
          </v-card>
          <!-- <div
            class="d-flex align-center justify-center py-3 mb-3"
            style="
              font: 16px;
              border-radius: 4px;
              color: white;
              background-color: #451646;
            "
          >
            بيانات المتضامن
          </div>
          <v-row class="mt-3">
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('jointName')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                اسم المتضامن
              </div>
              <v-text-field
                ref="customerName"
                color=""
                hide-details
                v-model="agent.joint.name"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('jointMobile')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                جوال المتضامن
              </div>
              <v-text-field
                hide-details
                v-model="agent.joint.mobile"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('jointSalary')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                راتب المتضامن
              </div>
              <v-text-field
                hide-details
                v-model="agent.joint.salary"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2 d-flex justify-space-between align-items-center">
                <div>
                  <svg
                    @click="show_Field_log('jointBirth_higri')"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.76"
                    height="12.964"
                    viewBox="0 0 15.76 12.964"
                    style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                  >
                    <path
                      id="Icon_material-history"
                      data-name="Icon material-history"
                      d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                      transform="translate(-1.5 -4.5)"
                    />
                  </svg>
                  <span v-if="joint_birth_date">تاريخ ميلاد المتضامن (ميلادي)</span>
                  <span v-else>تاريخ ميلاد المتضامن (هجري)</span>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13.558"
                  height="13.156"
                  viewBox="0 0 13.558 13.156"
                  @click="joint_birth_date = !joint_birth_date"
                  style="cursor: pointer; margin-top: 8px"
                >
                  <g id="compare" transform="translate(13.558) rotate(90)">
                    <path
                      id="Path_3990"
                      data-name="Path 3990"
                      d="M.7,3.534l.916-.954L3.06,1.073,3.392.727h-.58l.916.954L5.174,3.188l.332.346a.4.4,0,0,0,.58,0,.447.447,0,0,0,0-.6L5.17,1.976,3.724.469,3.392.123a.4.4,0,0,0-.58,0L1.9,1.077.45,2.584.118,2.93a.446.446,0,0,0,0,.6.4.4,0,0,0,.58,0Z"
                      transform="translate(0 0.003)"
                    />
                    <path
                      id="Path_3991"
                      data-name="Path 3991"
                      d="M0,.428v10c0,.135,0,.272,0,.407v.017a.424.424,0,0,0,.41.427.426.426,0,0,0,.41-.427v-10c0-.135,0-.272,0-.407V.428A.424.424,0,0,0,.411,0,.426.426,0,0,0,0,.428Zm4.378,10.2.916.954,1.446,1.507.332.346a.4.4,0,0,0,.58,0l.916-.954,1.446-1.507.332-.346a.446.446,0,0,0,0-.6.4.4,0,0,0-.58,0l-.916.954L7.405,12.482l-.332.346h.58l-.916-.954L5.291,10.367l-.332-.346a.4.4,0,0,0-.58,0,.448.448,0,0,0,0,.6Z"
                      transform="translate(2.691)"
                    />
                    <path
                      id="Path_3992"
                      data-name="Path 3992"
                      d="M.821,10.853v-10c0-.135,0-.272,0-.407V.428A.424.424,0,0,0,.411,0,.426.426,0,0,0,0,.428v10c0,.135,0,.272,0,.407v.017a.424.424,0,0,0,.41.427.426.426,0,0,0,.41-.427Z"
                      transform="translate(9.644 2.278)"
                    />
                  </g>
                </svg>
              </div>
              <v-text-field
                type="date"
                v-if="joint_birth_date"
                hide-details
                v-model="agent.joint.birth_date"
                class="mr-1"
                outlined
                dense
                append-icon
              >
                <template v-slot:append>
                  <svg
                    id="calendar"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.396"
                    height="16.989"
                    viewBox="0 0 17.396 16.989"
                    style="margin-bottom: -6px"
                  >
                    <path
                      id="Path_2784"
                      data-name="Path 2784"
                      d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                      transform="translate(-4.21 -7.917)"
                      fill="#6c757d"
                    />
                    <path
                      id="Path_2785"
                      data-name="Path 2785"
                      d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                      transform="translate(-4.21 -4.86)"
                      fill="#6c757d"
                    />
                  </svg>
                </template>
              </v-text-field>
              <v-text-field
                style="position: relative"
                v-else
                hide-details
                v-model="agent.joint.birth_date_higri"
                class="mr-1"
                outlined
                dense
                append-icon
              >
                <template v-slot:append>
                  <svg
                    id="calendar"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.396"
                    height="16.989"
                    viewBox="0 0 17.396 16.989"
                    style="margin-bottom: -6px"
                    @click="show_joint_date = !show_joint_date"
                  >
                    <path
                      id="Path_2784"
                      data-name="Path 2784"
                      d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                      transform="translate(-4.21 -7.917)"
                      fill="#6c757d"
                    />
                    <path
                      id="Path_2785"
                      data-name="Path 2785"
                      d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                      transform="translate(-4.21 -4.86)"
                      fill="#6c757d"
                    />
                  </svg>
                </template>
              </v-text-field>
              <v-hijri-date-picker
                @change="show_joint_date = false"
                v-if="show_joint_date && !joint_birth_date"
                style="position: absolute; z-index: 1000"
                v-model="agent.joint.birth_date_higri"
                locale="ar"
              />
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('jointage')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                العمر
              </div>
              <v-text-field
                hide-details
                v-model="agent.joint.age"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('jointWork')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                جهة العمل
              </div>
              <v-autocomplete
                :items="$store.state.works_list"
                hide-details
                v-model="agent.joint.work"
                item-text="value"
                item-value="id"
                class="mr-1"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              lg="3"
              md="4"
              sm="6"
              xs="12"
              class="mb-5 py-0"
              v-if="agent.joint.work == 1"
            >
              <div class="mb-2">
                <svg
                  @click="show_Field_log('jointaskaryWork')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                الجهة
              </div>
              <v-autocomplete
                :items="$store.state.military_works"
                hide-details
                v-model="agent.joint.askary_id"
                item-text="value"
                item-value="id"
                class="mr-1"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              lg="3"
              md="4"
              sm="6"
              xs="12"
              class="mb-5 py-0"
              v-if="agent.joint.work == 1"
            >
              <div class="mb-2">
                <svg
                  @click="show_Field_log('jointRank')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                الرتبة العسكرية
              </div>
              <v-autocomplete
                :items="$store.state.military_ranks"
                hide-details
                v-model="agent.joint.military_rank"
                item-text="value"
                item-value="id"
                class="mr-1"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              lg="3"
              md="4"
              sm="6"
              xs="12"
              class="mb-5 py-0"
              v-if="agent.joint.work == 2"
            >
              <div class="mb-2">
                <svg
                  @click="show_Field_log('jointmadanyWork')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                الجهة
              </div>
              <v-autocomplete
                :items="$store.state.madany_works"
                hide-details
                v-model="agent.joint.madany_id"
                item-text="value"
                item-value="id"
                class="mr-1"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              lg="3"
              md="4"
              sm="6"
              xs="12"
              class="mb-5 py-0"
              v-if="agent.joint.work == 2"
            >
              <div class="mb-2">
                <svg
                  @click="show_Field_log('jointJobTitle')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                المنصب الوظيفي
              </div>
              <v-text-field
                hide-details
                v-model="agent.joint.job_title"
                class="mr-1"
                outlined
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('joint_hiring_date')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                تاريخ التعيين
              </div>
              <v-text-field
                type="date"
                hide-details
                v-model="agent.joint.hiring_date"
                class="mr-1"
                outlined
                dense
                append-icon
              >
                <template v-slot:append>
                  <svg
                    id="calendar"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.396"
                    height="16.989"
                    viewBox="0 0 17.396 16.989"
                    style="margin-bottom: -6px"
                  >
                    <path
                      id="Path_2784"
                      data-name="Path 2784"
                      d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                      transform="translate(-4.21 -7.917)"
                      fill="#6c757d"
                    />
                    <path
                      id="Path_2785"
                      data-name="Path 2785"
                      d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                      transform="translate(-4.21 -4.86)"
                      fill="#6c757d"
                    />
                  </svg>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('jointsalary_source')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                جهة نزول المرتب
              </div>
              <v-autocomplete
                :items="$store.state.salary_sources"
                item-text="value"
                item-value="id"
                hide-details
                v-model="agent.joint.salary_id"
                class="mr-1"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-1 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('jointSupport')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                هل هو مدعوم
              </div>
              <v-radio-group row class="mr-n4 px-0" v-model="agent.joint.is_supported">
                <v-radio value="yes" label="نعم"></v-radio>
                <v-radio value="no" label="لا"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-1 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('joint_add_support_installment_to_salary')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                إضافة قسط الدعم إلي الراتب
              </div>
              <v-radio-group
                row
                v-model="agent.joint.add_support_installment_to_salary"
                class="mr-n4 px-0"
              >
                <v-radio value="1" label="نعم"></v-radio>
                <v-radio value="0" label="لا"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-1 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('jointObligations')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                هل لديه إلتزامات
              </div>
              <v-radio-group row v-model="agent.joint.has_obligations" class="mr-n4 px-0">
                <v-radio value="yes" label="نعم"></v-radio>
                <v-radio value="no" label="لا"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-1 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('jointobligations_value')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                قيمة الإلتزامات
              </div>
              <v-text-field
                hide-details
                v-model="agent.joint.obligations_value"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
                :disabled="agent.joint.has_obligations == 'no'"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('jointfunding_source')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                جهة التمويل
              </div>
              <v-autocomplete
                :items="$store.state.funding_sources"
                item-text="value"
                item-value="id"
                hide-details
                v-model="agent.joint.funding_id"
                class="mr-1"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row> -->
        </v-form>
      </v-sheet>
      <!-- </v-sheet> -->
    </template>
  </page-component>
</template>

<script src="dist/hijir-date-picker.js"></script>
<script>
import Axios from "axios";

export default {
  props: ["agent"],
  data() {
    return {
      openInformationJoint: false,
      workList: this.$store.state.works_list,
      searchTerm: "",
      addNewRequestDialog: false,
      date: "",
      joint_birth_date: false,
      customer_birth_date: false,
      show_joint_date: false,
      show_customer_date: false,
      secondNumber: false,
      showNums: false,
      secNum: "",
      numbersArray: [],
      confirmNum: {
        msg: "",
      },
      verifing: false,
    };
  },
  computed: {
    for_agent() {
      return (
        this.$store.state.request_info.statusReq == 0 ||
        this.$store.state.request_info.statusReq == 1 ||
        this.$store.state.request_info.statusReq == 2 ||
        this.$store.state.request_info.statusReq == 4
      );
    },
  },
  mounted() {
    if (
      this.$store.state.request_info.status === `طلب تم فتحه` &&
      !(this.$store.state.request_info.is_stared == 1) &&
      !(this.$store.state.request_info.is_followed == 1)
    ) {
      this.setBreadCrumbs([
        "الرئيسية",
        "الطلبات",
        "الطلبات المستلمه",
        this.agent.customer.name,
      ]);
    } else if (this.$store.state.request_info.status === `مؤرشف لدى استشاري المبيعات`) {
      this.setBreadCrumbs([
        "الرئيسية",
        "الطلبات",
        "الطلبات المؤرشفه",
        this.agent.customer.name,
      ]);
    } else if (
      this.$store.state.request_info.status === `في انتظار موافقة مدير المبيعات`
    ) {
      this.setBreadCrumbs([
        "الرئيسية",
        "الطلبات",
        "الطلبات المكتمله",
        this.agent.customer.name,
      ]);
    } else if (this.$store.state.request_info.is_stared == 1) {
      this.setBreadCrumbs([
        "الرئيسية",
        "الطلبات",
        "الطلبات المميزه",
        this.agent.customer.name,
      ]);
    } else if (this.$store.state.request_info.is_followed == 1) {
      this.setBreadCrumbs([
        "الرئيسية",
        "الطلبات",
        "الطلبات المتابعه",
        this.agent.customer.name,
      ]);
    } else {
      this.setBreadCrumbs(["الرئيسية", "الطلبات", this.agent.customer.name]);
    }
    // this.workList = ;
  },
  watch: {
    date(val) {
      this.agent.joint.birth_date_higri = val;
    },
    "$route.params": {
      handler() {
        this.$router.go(this.$router.currentRoute);
      },
      deep: true,
    },
    agent: {
      handler(val) {
        if (val) {
          this.$emit("update:agent", val);
          this.$store.state.request_info = val;
          val.customer.work = parseInt(val.customer.work);
          val.customer.military_rank = parseInt(val.customer.military_rank) || "";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async get_all_requests(page = 1) {
      this.loading = true;
      await Axios.get(
        `https://api.alwsatarealestate.com/api/v2/employee/Requests?type=all&page=${page}&per_page=10`,
        {
          headers: this.request_headers(),
        }
      ).then((response) => {
        this.items = response.data.payload.data;
        this.pagination = {
          limit: 10,
          count: response.data.payload.count_of_all,
        };
        this.loading = false;
      });
    },

    async getNumbers() {
      await Axios.get(
        `https://api.alwsatarealestate.com/api/agent/Request/Phones/${this.$route.params.id}`,
        {
          headers: this.request_headers(),
        }
      )
        .then((res) => {
          if (res.data.payload.length > 0) {
            this.numbersArray = res.data.payload;
          }
        })
        .catch((err) => console.log(err.message));
    },
    addNumber() {
      this.verifing = true;
      Axios.post(
        `https://api.alwsatarealestate.com/api/agent/Request/Add/Phone`,
        {
          mobile: this.secNum,
          request_id: this.$route.params.id,
        },
        {
          headers: this.request_headers(),
        }
      )
        .then((res) => {
          this.showPopup("success", res.data.message);
          this.secondNumber = false;
          this.getNumbers();
          this.verifing = false;
          this.secNum = "";
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
          this.verifing = false;
        });
    },
  },
  async created() {
    await this.getNumbers();
  },
};
</script>

<style lang="scss">
.agent-data-page fieldset {
  border: 1px solid #ced4da !important;
}
</style>
