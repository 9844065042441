<template>
  <c-dialog v-model="active" title="سجل الطلب" :loading="loading" :errors="errors" width="570px">
    <template slot="content">
      <v-form v-if="!loading && field_log.length">
        <v-row>
          <v-col lg="3" md="4" sm="6">
            اسم المستخدم
          </v-col>
          <v-col lg="3" md="4" sm="6">
            التحديث
          </v-col>
          <v-col lg="3" md="4" sm="6">
            وقت التحديث
          </v-col>
        </v-row>
        <v-row v-for="log in field_log" :key="log.id">
          <v-col lg="3" md="4" sm="6">
            {{log.user.name}}
          </v-col>
          <v-col lg="3" md="4" sm="6">
            {{log.value}}
          </v-col>
          <v-col lg="3" md="4" sm="6">
            {{log.updateValue_at}}
          </v-col>
        </v-row>
      </v-form>
      <v-row v-else class="d-flex align-center justify-center">
          لا توجد بيانات متاحة
        </v-row>
    </template>
  </c-dialog>
</template>

<script>
import Dialog from '../../mixins/dialog'
import Axios from 'axios'
export default {
  mixins: [Dialog],
  props: ['field'],
  name: 'FieldLogDialog',

  data() {
    return {
      field_log: [],
      propsValue: ''

    }
  },
  computed: {
    sources() {
      return this.$store.state.work_sources
    }
  },
  watch: {
    field(val) {
      if(val === 'comment1'){
        this.propsValue = 'comment'
      }
      else if (val === 'class_agent1'){
        this.propsValue = 'class_agent'
      }
      else if (val === 'reqSource1'){
        this.propsValue = 'reqSource'
      }
      else if (val === 'reqtyp1'){
        this.propsValue = 'reqtyp'
      }
      else{
        this.propsValue = val
      }
      if(val) {
        this.get_request_log()
      }
    }
  },
  methods: {
    init() {
      this.field_log = []
    },
    get_request_log() {
      this.init()
      this.loading = true
      Axios.post("https://api.alwsatarealestate.com/api/agent/Request/Field/History",{
        request_id: this.$route.params.id ?? this.$store.state.request_info.id,
        field: this.propsValue
        },
        {
        headers: this.request_headers(),
        }).then((response)=> {
          this.field_log = response.data.payload
        }).catch(err=> {
          this.showPopup("error", err.response.data.message)
        }).finally(()=> {
          this.loading = false
          this.active = false
          setTimeout(() => {
            this.active = true
          }, 0);
        })
    }
  }
}
</script>
<style scoped>
.v-row:nth-of-type(even) {
  border-color: #F8F9FA !important;
  font-size: 16px !important;
  background-color: #F8F9FA !important;
}
</style>