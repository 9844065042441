<template>
  <div>
    <v-menu
      offset-y
      nudge-top="-20"
      v-if="
        !showProcessAddToDone &&
        !showFollowedProcess &&
        !showReceivedProcess &&
        !showArchivedProcess
      "
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          height="40px"
          width="40px"
          style="min-width: unset; left: 10px; border-radius: var(--8, 8px)"
          class="ml-5 mr-3 p-0 genaralIcons"
          color="#3f0e40"
        >
          <v-icon color="white" size="40">mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>

        <v-list-item
          v-if="showProcessAddToNew"
          class="black--text font-weight-bold"
          style="border-bottom: 2px solid #ededed"
          @click="backToReceivedBasket"
        >
          <v-list-item-icon class="ml-3">
            <v-icon>mdi-arrow-left</v-icon>
          </v-list-item-icon>
          <v-list-item-title>إرجاع للمستلمة</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="showQualityNeedTurnedProcess"
          class="black--text font-weight-bold"
          style="border-bottom: 2px solid #ededed"
          @click="backToReceivedBasket"
        >
          <v-list-item-icon class="ml-3">
            <v-icon>mdi-arrow-left</v-icon>
          </v-list-item-icon>
          <v-list-item-title>إرجاع للمستلمة</v-list-item-title>
        </v-list-item>

        <v-list-item @click="handleTurnuser">
          <v-list-item-icon class="ml-2">
            <ConvertRequestIcon />
          </v-list-item-icon>
          <v-list-item-title>تحويل الطلب</v-list-item-title>
        </v-list-item>

        <v-list-item @click="addAddition" v-if="showPendingProcess">
          <v-list-item-icon class="ml-2">
            <ConvertRequestIcon />
          </v-list-item-icon>
          <v-list-item-title>اضافه شرط لحظي</v-list-item-title>
        </v-list-item>

        <v-list-item @click="Processed" v-if="showProcessAddToNew">
          <v-list-item-icon class="ml-2">
            <ProcessedIcon />
          </v-list-item-icon>
          <v-list-item-title>تمت المعالجه</v-list-item-title>
        </v-list-item>

        <v-list-item @click="sendToQuality" v-if="showProcessAddToNew">
          <v-list-item-icon class="ml-2">
            <SendToQualityIcon />
          </v-list-item-icon>
          <v-list-item-title>نقل الي الجوده</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn
      @click="handleOpenDialog"
      fab
      class="mx-2 pa-0 genaralIcons"
      small
      style="left: 125px; border-radius: var(--8, 8px)"
      :color="$vuetify.theme.themes.light.BtnBKColor"
      depressed
      width="40"
      height="40"
    >
      <v-icon color="white">mdi-magnify</v-icon>
    </v-btn>

    <v-menu offset-y nudge-top="-7">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          size="45px"
          v-bind="attrs"
          v-on="on"
          style="z-index: 5; left: 80px; top: -53px; position: absolute"
        >
          <svg
            width="512"
            height="512"
            viewBox="0 0 512 512"
            fill="#3f0e40"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M474.557 37.4414V168.577H328.851V37.4414H474.557ZM255.999 37.4414V474.557H37.4414V37.4414H255.999ZM474.557 197.719V314.281H328.851V197.719H474.556H474.557ZM474.557 343.422V474.557H328.851V343.422H474.556H474.557Z"
              fill="#3f0e40"
            />
          </svg>
        </v-avatar>
      </template>
      <v-list>
        <v-list-item @click="$store.state.views = 'default'" v-if="showNewView">
          <v-list-item-icon class="ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="#3F0E40"
            >
              <rect x="2" y="5" width="12" height="3" fill="#3F0E40" />
              <rect x="2" y="10" width="12" height="3" fill="#3F0E40" />
              <rect x="2" y="16" width="12" height="3" fill="#3F0E40" />
              <rect x="2" y="21" width="12" height="3" fill="#3F0E40" />
              <rect
                x="16.4424"
                y="5"
                width="11.5385"
                height="8.65385"
                rx="1"
                fill="#3F0E40"
              />
              <rect
                x="16.4424"
                y="15.0957"
                width="11.5385"
                height="8.65385"
                rx="1"
                fill="#3F0E40"
              />
            </svg>
          </v-list-item-icon>

          <v-list-item-title style="line-height: 2"> عرض الملفات </v-list-item-title>
        </v-list-item>

        <v-list-item
          class="pl-12"
          @click="$store.state.views = 'cards'"
          style="border-bottom: 2px solid #ededed"
        >
          <v-list-item-icon class="ml-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="#3F0E40"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="four-squares-icon 1">
                <g id="Group">
                  <path
                    id="Vector"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.61524 12.7831H10.4074C10.7402 12.7831 11.0124 13.1075 11.0124 13.5034V21.0877C11.0124 21.4842 10.7404 21.8083 10.4074 21.8083H2.61524C2.28272 21.8083 2.01025 21.4842 2.01025 21.0877V13.5034C2.01025 13.1075 2.28272 12.7831 2.61524 12.7831ZM13.5925 2H21.3848C21.7178 2 21.9898 2.32407 21.9898 2.72043V10.3048C21.9898 10.7011 21.7178 11.0249 21.3848 11.0249H13.5925C13.2596 11.0249 12.988 10.7011 12.988 10.3048V2.72043C12.988 2.32407 13.2596 2 13.5925 2ZM2.60449 2H10.3971C10.73 2 11.0021 2.32407 11.0021 2.72043V10.3048C11.0021 10.7011 10.7302 11.0249 10.3971 11.0249H2.60449C2.27197 11.0249 2 10.7011 2 10.3048V2.72043C2 2.32407 2.27197 2 2.60449 2ZM13.6027 12.9753H21.3951C21.7276 12.9753 22 13.2994 22 13.6959V21.2803C22 21.6761 21.7276 22.0002 21.3951 22.0002H13.6027C13.2699 22.0002 12.9977 21.6761 12.9977 21.2803V13.6959C12.9977 13.2994 13.2699 12.9753 13.6027 12.9753Z"
                    fill="#3F0E40"
                  />
                </g>
              </g>
            </svg>
          </v-list-item-icon>
          <v-list-item-title style="line-height: 2"> عرض الكروت</v-list-item-title>
        </v-list-item>

        <v-list-item
          class="pl-12"
          @click="$store.state.views = 'table'"
          style="border-bottom: 2px solid #ededed"
        >
          <v-list-item-icon class="ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27.348"
              height="22.783"
              viewBox="0 0 24.348 19.783"
              fill="#3F0E40"
            >
              <path
                id="Icon_awesome-list-ul"
                data-name="Icon awesome-list-ul"
                d="M22.065,3.375a2.283,2.283,0,1,1-2.283,2.283A2.283,2.283,0,0,1,22.065,3.375Zm0,7.609a2.283,2.283,0,1,1-2.283,2.283A2.283,2.283,0,0,1,22.065,10.984Zm0,7.609a2.283,2.283,0,1,1-2.283,2.283,2.283,2.283,0,0,1,2.283-2.283Zm-21.3.761H15.978a.761.761,0,0,1,.761.761v1.522a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,21.636V20.114A.761.761,0,0,1,.761,19.353Zm0-15.217H15.978a.761.761,0,0,1,.761.761V6.418a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,6.418V4.9A.761.761,0,0,1,.761,4.136Zm0,7.609H15.978a.761.761,0,0,1,.761.761v1.522a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,14.027V12.505A.761.761,0,0,1,.761,11.745Z"
                transform="translate(0 -3.375)"
                fill="#3F0E40"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-title style="line-height: 2">عرض الجدول</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- <v-btn
      fab
      class="mx-2 pa-0 genaralIcons table-style active-table-style"
      icon
      color="#ffffff"
      @click="$store.state.showCards = true"
      @focus="toggleTableStyle"
      depressed
      width="40"
      height="40"
      style="left: 75px; border-radius: var(--8, 8px); background: #3f0e40"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="27.348"
        height="22.783"
        viewBox="0 0 24.348 19.783"
      >
        <path
          id="Icon_awesome-list-ul"
          data-name="Icon awesome-list-ul"
          d="M22.065,3.375a2.283,2.283,0,1,1-2.283,2.283A2.283,2.283,0,0,1,22.065,3.375Zm0,7.609a2.283,2.283,0,1,1-2.283,2.283A2.283,2.283,0,0,1,22.065,10.984Zm0,7.609a2.283,2.283,0,1,1-2.283,2.283,2.283,2.283,0,0,1,2.283-2.283Zm-21.3.761H15.978a.761.761,0,0,1,.761.761v1.522a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,21.636V20.114A.761.761,0,0,1,.761,19.353Zm0-15.217H15.978a.761.761,0,0,1,.761.761V6.418a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,6.418V4.9A.761.761,0,0,1,.761,4.136Zm0,7.609H15.978a.761.761,0,0,1,.761.761v1.522a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,14.027V12.505A.761.761,0,0,1,.761,11.745Z"
          transform="translate(0 -3.375)"
          fill="#fff"
        />
      </svg>
    </v-btn>

    <v-btn
      fab
      class="mx-2 pa-0 genaralIcons table-style active-table-style"
      style="
        left: 125px;
        border-radius: var(--8, 8px);
        background: rgba(63, 14, 64, 0.15);
      "
      icon
      color="#ffffff"
      @click="$store.state.showCards = true"
      @focus="toggleTableStyle"
      depressed
      width="40"
      height="40"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="four-squares-icon 1">
          <g id="Group">
            <path
              id="Vector"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.61524 12.7831H10.4074C10.7402 12.7831 11.0124 13.1075 11.0124 13.5034V21.0877C11.0124 21.4842 10.7404 21.8083 10.4074 21.8083H2.61524C2.28272 21.8083 2.01025 21.4842 2.01025 21.0877V13.5034C2.01025 13.1075 2.28272 12.7831 2.61524 12.7831ZM13.5925 2H21.3848C21.7178 2 21.9898 2.32407 21.9898 2.72043V10.3048C21.9898 10.7011 21.7178 11.0249 21.3848 11.0249H13.5925C13.2596 11.0249 12.988 10.7011 12.988 10.3048V2.72043C12.988 2.32407 13.2596 2 13.5925 2ZM2.60449 2H10.3971C10.73 2 11.0021 2.32407 11.0021 2.72043V10.3048C11.0021 10.7011 10.7302 11.0249 10.3971 11.0249H2.60449C2.27197 11.0249 2 10.7011 2 10.3048V2.72043C2 2.32407 2.27197 2 2.60449 2ZM13.6027 12.9753H21.3951C21.7276 12.9753 22 13.2994 22 13.6959V21.2803C22 21.6761 21.7276 22.0002 21.3951 22.0002H13.6027C13.2699 22.0002 12.9977 21.6761 12.9977 21.2803V13.6959C12.9977 13.2994 13.2699 12.9753 13.6027 12.9753Z"
              fill="#3F0E40"
            />
          </g>
        </g>
      </svg>
    </v-btn> -->

    <!-- <v-text-field
      outlined
      v-model="$store.state.counterOfShow"
      dense
      width="10"
      height="35"
      color="white"
      style="
        position: absolute;
        top: -50px;
        left: 230px;
        min-width: unset;
        max-width: 50px !important;
        z-index: 5;
      "
      class="white-border"
    ></v-text-field> -->

    <TurnuserDialog :selectedItems="selectedItems" :type="type" />
    <NeedAddToNewSearch />
    <WatingNewSearch />

    <template>
      <v-row justify="center">
        <v-dialog v-model="backToReceivedDialog" persistent width="650">
          <v-card>
            <v-card-title class="text-h5"> </v-card-title>
            <v-card-text>
              <h2>هل انت متأكد من ارجاع الطلبات لسلة المستلمه ؟</h2>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green-darken-1"
                variant="text"
                @click="backToReceivedDialog = false"
              >
                الغاء
              </v-btn>
              <v-btn
                color="green-darken-1"
                variant="text"
                @click="sureToBackToReceivedBasket"
              >
                متاكد
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

  </div>
</template>

<style lang="scss">
.genaralIcons {
  position: absolute;
  top: -50px;
  min-width: unset;
  z-index: 5;
}

.white-border {
  .v-input--outlined {
    border-color: white !important;
  }

  .v-label {
    color: white !important;
  }

  .v-icon {
    color: white !important;
  }

  input {
    color: white !important;
  }
}
</style>

<script>
import { SendToQualityIcon } from "@/assets/adminIcons/sendToQualityIcon";
import { ConvertRequestIcon } from "@/assets/adminIcons/convertRequest";
import TurnuserDialog from "@/components/AdminComponent/TurnuserDialog.vue";
import Axios from "axios";
import NeedAddToNewSearch from "@/views/admin/Requests/MoveToNew/NeedAddToNewSearch.vue";
import WatingNewSearch from "@/views/admin/Requests/WatingNew/WatingNewSearch.vue";
import { ProcessedIcon } from "@/assets/adminIcons/processedIcon";
export default {
  components: {
    WatingNewSearch,
    NeedAddToNewSearch,
    SendToQualityIcon,
    ConvertRequestIcon,
    ProcessedIcon,
    TurnuserDialog,
  },
  data() {
    return {
      backToReceivedDialog: false,
    };
  },
  props: ["selectedItems", "type"],

  computed: {
    showNewView() {
      return (
        window.location.pathname === "/admin/requests/recieved" ||
        window.location.pathname === "/admin/requests/followed"
      );
    },
    showProcessAddToNew() {
      return window.location.pathname === "/admin/requests/need-action-new";
    },

    showProcessAddToDone() {
      return window.location.pathname === "/admin/requests/need-to-turn-done";
    },

    showReceivedProcess() {
      return window.location.pathname === "/admin/requests/recieved";
    },

    showFollowedProcess() {
      return window.location.pathname === "/admin/requests/followed";
    },

    showArchivedProcess() {
      return window.location.pathname === "/admin/requests/archieved";
    },

    showPendingProcess() {
      return window.location.pathname === "/admin/requests/pending";
    },

    showQualityNeedTurnedProcess() {
      return window.location.pathname === "/admin/requests/need-to-turn-new";
    },

  },

  methods: {
    addAddition() {
      if (this.selectedItems.length || this.$store.state.startElement.length) {
        this.$store.state.openConditionDialog = true;
      }
    },

    handleOpenDialog() {
      if (window.location.pathname === "/admin/requests/need-action-new") {
        this.$store.state.needToAddNewFilter = true;
      } else if (window.location.pathname === "/admin/requests/wating-new") {
        this.$store.state.waitingNewFilter = true;
      } else if (
        window.location.pathname === "/admin/requests/recieved" ||
        window.location.pathname === "/admin/requests/archieved" ||
        window.location.pathname === "/admin/requests/followed"
      ) {
        this.$store.state.search_admin = true;
      } else if (
        window.location.pathname === "/admin/requests/need-to-turn-new" ||
        window.location.pathname === "/admin/requests/need-to-turn-done"
      ) {
        this.$store.state.NeedToMoveQualityNewSearch = true;
      }
      // else if(window.location.pathname === "/admin/requests/pending"){
      //
      // }
    },

    handleTurnuser() {
      if (this.selectedItems.length || this.$store.state.startElement.length) {
        this.$store.state.turnuserDialog = true;
      }
    },

    sendToQuality() {
      if (this.selectedItems.length > 0 || this.$store.state.startElement.length > 0) {
        this.$store.state.dialogQuality = true;
      }
    },

    handleTableDesign() {
      this.$store.state.showCards = false;
    },

    toggleTableStyle(e) {
      document.querySelectorAll(".table-style").forEach((btn) => {
        btn.querySelector("svg").classList.remove("active-table-style");
      });
      e.target.querySelector("svg").classList.add("active-table-style");
    },

    Processed() {
      let url = `${this.$store.state.url}/v2/employee/Requests/moveReqsNewToDoneArray`;
      let body = new FormData();
      this.selectedItems.length > 0 &&
        this.selectedItems.forEach((item, index) => {
          body.append(`request_ids[${index}]`, item["id"]);
        });

      this.$store.state.startElement.length > 0 &&
        this.$store.state.startElement.forEach((item, index) => {
          body.append(`request_ids[${index}]`, item["id"]);
        });

      Axios.post(url, body, { headers: this.request_headers() }).then((res) => {
        this.showPopup("success", res.data.message);
      });
    },




    backToReceivedBasket() {
      if (
        this.selectedItems.length ||
        this.$store.state.startElement.length > 0
      ) {
        this.backToReceivedDialog = true;
      }
    },

    sureToBackToReceivedBasket() {
      let url = '';
      
      if(this.showQualityNeedTurnedProcess){
        url = "https://api.alwsatarealestate.com/api/v2/employee/Requests/moveFromQualityNeedTransferToRecieved";
      }else if(this.showProcessAddToNew){
        url = "https://api.alwsatarealestate.com/api/v2/employee/Requests/moveFromNeedTransferToRecieved";
      }

      let body = new FormData();
      this.selectedItems.length &&
        this.selectedItems.forEach((item, index) => {
          body.append(`request_ids[${index}]`, item["id"]);
        });

      this.$store.state.startElement.length &&
        this.$store.state.startElement.forEach((item, index) => {
          body.append(`request_ids[${index}]`, item["id"]);
        });

      this.backToReceivedDialog = false;

      Axios.post(url, body, { headers: this.request_headers() })
        .then((res) => {
          this.showPopup("success", res.data.message);
          this.$emit("getData");
        })
        .catch((err) => {
          let error = { err };
          this.snackMessage = error.err.response.data.message;
          this.showPopup("error", error.err.response.data.message);
        });
    },





  },
};
</script>
