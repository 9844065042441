<template>
  <page-component style="position: relative" :loading="loading">
    <template #content>
      <div class="py-5 mr-1 font-weight-bold">
        التذاكر المرسلة
        <span
          class="ml-4 font-weight-bold"
          style="float: left; color: #3b5de7"
          >{{ sent_counter }}</span
        >
      </div>
      <v-btn
        fab
      :color="$vuetify.theme.themes.light.dialogActionBTN"
        @click="addNewRequestDialog = true"
        class="my-10"
        style="position: fixed; left: 20px; bottom: 30px; z-index: 1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25.425"
          height="21.166"
          viewBox="0 0 25.425 21.166"
        >
          <g
            id="Icon_feather-user-plus"
            data-name="Icon feather-user-plus"
            transform="translate(1 1)"
          >
            <path
              id="Path_2554"
              data-name="Path 2554"
              d="M17.471,28.889v-2.13A4.259,4.259,0,0,0,13.212,22.5H5.759A4.259,4.259,0,0,0,1.5,26.759v2.13"
              transform="translate(-1.5 -9.723)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path
              id="Path_2555"
              data-name="Path 2555"
              d="M15.268,8.759A4.259,4.259,0,1,1,11.009,4.5,4.259,4.259,0,0,1,15.268,8.759Z"
              transform="translate(-3.023 -4.5)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path
              id="Path_2556"
              data-name="Path 2556"
              d="M30,12v6.389"
              transform="translate(-9.77 -6.676)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path
              id="Path_2557"
              data-name="Path 2557"
              d="M31.889,16.5H25.5"
              transform="translate(-8.464 -7.982)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </g>
        </svg>
      </v-btn>
      <add-new-request v-model="addNewRequestDialog" width="350px" />
    <AgentChat />
      <v-divider class="mb-5" style="border: 1px solid #EDEEF0" />
      <v-row class="mt-0">
        <v-col
          cols="12"
          lg="3"
          md="4"
          sm="6"
          xs="12"
          v-for="ticket in sent_tickets"
          :key="ticket.id"
        >
        <v-card elevation="1" style="    border: 2px solid rgb(233, 237, 244) !important;
            box-shadow: 0px 0px 0px 0px !important;
            margin: 0px !important;" class="pa-2 ma-2">
            <div>
              <span style="font-size: 14px;">{{ ticket.user.name || "لايوجد اسم" }}</span>
              <span
                class="py-1 px-4"
                style="
                  float: left;
                  border-radius: 2px;
                  background: #D9DEFA;
                  color: #495AA0;
                  font-weight: bold;
                  letter-spacing: -.5px;
                  font-size: 14px;
                  line-height: 100%;
                  height: 25px;
                "
                >{{ ticket.status || "لايوجد حالة" }}</span
              >
            </div>
            <div class="mt-3" style="font-size: 14px;">
              {{ ticket.receive.name || "لايوجد اسم" }}
              <span class="py-1" style="float: left">{{
                ticket.request ? ticket.request.customer.name : ""
              }}</span>
            </div>
            <div class="mt-3" style="font-size: 14px;">
              <v-icon class="mb-1" style="margin-right: -4px; font-size: 20px;">mdi-calendar</v-icon>
              {{
                ticket.created_at.split(".")[0].split("T").join(" | ") ||
                "لايوجد تاريخ"
              }}
              <span class=" px-0" style="float: left">

<svg xmlns="http://www.w3.org/2000/svg" width="16.1" height="11.982" viewBox="0 0 16.1 11.982"
  @click="to_ticket_details(ticket.id)" style="cursor: pointer; margin-bottom: -3px;">
  <g id="Group_11443" data-name="Group 11443" transform="translate(-765.437 -394.636)">
    <g id="Icon_feather-eye" data-name="Icon feather-eye" transform="translate(765.937 395.136)">
      <path id="Path_3993" data-name="Path 3993"
        d="M1.5,11.491S4.246,6,9.05,6s7.55,5.491,7.55,5.491-2.746,5.491-7.55,5.491S1.5,11.491,1.5,11.491Z"
        transform="translate(-1.5 -6)" fill="none" stroke="#3b5de7" stroke-linecap="round"
        stroke-linejoin="round" stroke-width="1" />
      <path id="Path_3994" data-name="Path 3994"
        d="M17.618,15.559A2.059,2.059,0,1,1,15.559,13.5,2.059,2.059,0,0,1,17.618,15.559Z"
        transform="translate(-8.009 -10.068)" fill="none" stroke="#3b5de7" stroke-linecap="round"
        stroke-linejoin="round" stroke-width="1" />
    </g>
  </g>
</svg>
<span style="margin-right: 5px">عرض التذكرة</span>
</span>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </page-component>
</template>

<script>
import Axios from "axios";
import AgentChat from "@/components/AgentChat/AgentChat.vue";

export default {
  props: ["agent"],
  components: {AgentChat},
  data() {
    return {
      agent_data: {},
      recieved_tickets: [],
      completed_tickets: [],
      sent_tickets: [],
      sent_counter: 0,
      recieved_counter: 0,
      completed_counter: 0,
      loading: false,
      addNewTaskDialog: false,
      addNewRequestDialog: false,
    };
  },
  created() {
    this.$store.state.opened = -1
    this.get_request_tickets();
  },
  methods: {
    get_request_tickets() {
      this.loading = true;
      Axios.get(`https://api.alwsatarealestate.com/api/agent/SentTasks`, {
        headers: this.request_headers(),
      })
        .then((response) => {
          this.sent_tickets = response.data.payload;
          this.sent_counter = this.sent_tickets.length;
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    to_ticket_details(id) {
      this.$router.push({
        name: "ticket_details",
        params: { ticket: id },
        query: { type: "sent" },
      });
    },
  },
};
</script>
