<template>
  <v-row justify="center">
    <v-dialog
        v-model="this.$store.state.SMSendMorPurDialog"
        persistent
        width="624"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5" style="margin-bottom: 30px">تاكيد ارسال الدفعة؟</span>
        </v-card-title>
        <v-card-text>
            <v-row>
                
            <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="mb-1 py-0">
              <div class="mb-2">
                إرسال إلى:
              </div>
              <v-radio-group
                v-model="sendTo"
                row
                class="mr-n4 px-0"
              >
                <v-radio value="funding" label="مشرف التمويل"></v-radio>
                <v-radio value="agent" v-if="$store.state.request_info.isSentSalesAgent == 0" label="استشاري المبيعات"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="my-2">
              <div class="mb-2">
                الملاحظة
              </div>
              <v-textarea hide-details style="background-color: white ; border: 2px solid; padding: 5px;" v-model="note" 
                dense></v-textarea>
            </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue-darken-1"
              variant="text"
              @click="$store.state.SMSendMorPurDialog = false"
          >
            الغاء
          </v-btn>
          <v-btn
              color="blue-darken-1"
              variant="text"
              @click="send_payment"
          >
            ارسال
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Axios from "axios";

export default {
  name: 'SMSendMorPurDialog',
  props: ['reqId'],

  data(){
    return{
      sendTo: "",
      note: ""
    }
  },

  methods: {
    
    send_payment() {
      let req_id = "";
        this.loading = true;
        req_id = parseInt(this.reqId);
        const formData = new FormData();
        formData.append("send_to", this.sendTo);
        formData.append("comment", this.note);
        Axios.post(
        `https://api.alwsatarealestate.com/api/v2/employee/Requests/MortgagePurchase/send/${req_id}`,
          formData,
          {
            headers: this.request_headers(),
          }
        )
          .then(() => {
            this.showPopup(
              "success",
              "تم ارسال بنجاح"
            );
            this.refresh_request_details();
          })
          .catch((err) => {
            this.showPopup("error", err.response.data.message);
          })
          .finally(()=>{
            this.$store.state.SMSendMorPurDialog = false;
          });
      
    },
    
    refresh_request_details() {
      this.loading = true;
      Axios.get(`https://api.alwsatarealestate.com/api/v2/employee/Requests/${this.reqId}`, {
        headers: this.request_headers(),
      })
        .then((response) => {
          this.agent = response.data.payload;
          this.$store.state.request_info = response.data.payload;
          this.loading = false;
          this.setBreadCrumbs([
            "الرئيسية",
            "الطلبات",
            this.agent.customer.name,
          ]);
          this.$store.state.request_class.forEach((el) => {
            if (el.id == this.agent.class_id_agent) {
              this.getAgentClass = el.value;
            }
          });
          this.agent.follow_time = response.data.payload.date_time_agent
            ? response.data.payload.date_time_agent.reminder_date.split(" ")[1]
            : "";
          this.agent.follow_date = response.data.payload.date_time_agent
            ? response.data.payload.date_time_agent.reminder_date.split(" ")[0]
            : "";
        })
        .catch((err) => {
          if (err.response) {
            this.showPopup("error", err.response.data.message);
            this.loading = false;
            this.$router.go(-1);
          }
          else {
            console.log(err);
          }
        });
    },
  },

}
</script>