<template>
  <div>
    <h4>
      المؤشرات
    </h4>

    <h4 class="sub-title">
      مؤشر تقييم العملاء
    </h4>
    <v-row>
      <v-col cols="2">
        <v-select
          :items="items"
          label="المده الزمنيه"
          item-text="label"
          item-value="value"
          v-model="day_date"
          solo
          outlined
                @change="reloadData"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-sheet class="c-block-1">
          <h5>
            ( تم تقييم بواسطة {{star_t_c}} عميل )
          </h5>
          <div class="rate-1">
            <span>(1 نجمة)</span>
            <span style="display: contents;">
              <v-progress-linear
              style="
                    max-width: 75%;
                    margin: auto;"
                v-model="star_1"
                color="amber"
                height="8"
                rounded
              ></v-progress-linear>
            </span>
            <span>({{ star_1_c }} عميل)</span>
          </div>
          <div class="rate-1">
            <span>(2 نجمة)</span>
            <span style="display: contents;">
              <v-progress-linear
              style="
                    max-width: 75%;
                    margin: auto;"
                v-model="star_2"
                color="amber"
                height="8"
                rounded
              ></v-progress-linear>
            </span>
            <span>({{star_2_c}} عميل)</span>
          </div>
          <div class="rate-1">
            <span>(3 نجمة)</span>
            <span style="display: contents;">
              <v-progress-linear
              style="
                    max-width: 75%;
                    margin: auto;"
                v-model="star_3"
                color="amber"
                height="8"
                rounded
              ></v-progress-linear>
            </span>
            <span>({{star_3_c}} عميل)</span>
          </div>
          <div class="rate-1">
            <span>(4 نجمة)</span>
            <span style="display: contents;">
              <v-progress-linear
              style="
                    max-width: 75%;
                    margin: auto;"
                v-model="star_4"
                color="amber"
                height="8"
                rounded
              ></v-progress-linear>
            </span>
            <span>({{star_4_c}} عميل)</span>
          </div>
          <div class="rate-1">
            <span>(5 نجمة)</span>
            <span style="display: contents;">
              <v-progress-linear
              style="
                    max-width: 75%;
                    margin: auto;"
                v-model="star_5"
                color="amber"
                height="8"
                rounded
              ></v-progress-linear>
            </span>
            <span>({{star_5_c}} عميل)</span>
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="6">
        
        <v-sheet class="c-block-1 c-block-2">
          <div class="svg-cont">
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.0002 46.054L43.0669 52.7473C45.0935 53.974 47.5735 52.1607 47.0402 49.8673L44.1069 37.2807L53.8935 28.8007C55.6802 27.254 54.7202 24.3207 52.3735 24.134L39.4935 23.0407L34.4535 11.1473C33.5469 8.98734 30.4535 8.98734 29.5469 11.1473L24.5069 23.014L11.6269 24.1073C9.28019 24.294 8.32019 27.2273 10.1069 28.774L19.8935 37.254L16.9602 49.8407C16.4269 52.134 18.9069 53.9473 20.9335 52.7207L32.0002 46.054Z" fill="#F2C94C"/>
            </svg>

          </div>
          <h5>
            (مؤشر معدل التقييم)
          </h5>
          <v-progress-linear
              style="
                    max-width: 75%;
                    margin: auto;"
                v-model="star_avg"
                color="amber"
                height="8"
                rounded
              ></v-progress-linear>
          </v-sheet>

      </v-col>
    </v-row>

<h4 class="sub-title">
  مؤشر الطلبات المستلمة
</h4>
<v-row>
  <v-col cols="3" v-for="(item, index) in agents" :key="index">
    <AgentPercentageCard :agent="item" :color="item.color"/>
  </v-col>
</v-row>
<br/>
  </div>
</template>

<script>

import { axiosInstance } from "@/axios/config";
import AgentPercentageCard from './agentPercentageCard'
import Axios from "axios";

export default {
  components: {
    AgentPercentageCard
  },
  data() {
    return {
        day_date: '',
        agents: [],
        star_1: 0,
        star_2: 0,
        star_3: 0,
        star_4: 0,
        star_5: 0,
        star_1_c: 0,
        star_2_c: 0,
        star_3_c: 0,
        star_4_c: 0,
        star_5_c: 0,
        star_t_c: 0,
        star_avg: 0,
        valueDeterminate: 50,
      items: [
        {
          label: "اسبوع",
          value: 7 
        },
        {
          label: "اسبوعين",
          value: 14 
        },
        {
          label: "شهر",
          value: 30 
        },
      ],
    };
  },

  methods: {
    reloadData(){
      axiosInstance
    .get(`/employee/admin/agent-percentage-report?relaod&filter_date=${this.day_date}`)
    .then((res) => {
      this.star_1 = res.data.payload.star_1;
      this.star_2 = res.data.payload.star_2;
      this.star_3 = res.data.payload.star_3;
      this.star_4 = res.data.payload.star_4;
      this.star_5 = res.data.payload.star_5;
      this.star_1_c = res.data.payload.star_1_c;
      this.star_2_c = res.data.payload.star_2_c;
      this.star_3_c = res.data.payload.star_3_c;
      this.star_4_c = res.data.payload.star_4_c;
      this.star_5_c = res.data.payload.star_5_c;
      this.star_t_c = this.star_1_c + this.star_2_c +this.star_3_c + this.star_4_c + this.star_5_c;
      this.star_adv = res.data.payload.star_adv;
    })
    .catch((err) => {
      console.log("eror")
    });
    }
  },

  created() {
    axiosInstance
    .get(`/employee/admin/agent-percentage-report`, {})
    .then((res) => {
      this.agents = res.data.payload.agents;
      this.star_1 = res.data.payload.star_1;
      this.star_2 = res.data.payload.star_2;
      this.star_3 = res.data.payload.star_3;
      this.star_4 = res.data.payload.star_4;
      this.star_5 = res.data.payload.star_5;
      this.star_1_c = res.data.payload.star_1_c;
      this.star_2_c = res.data.payload.star_2_c;
      this.star_3_c = res.data.payload.star_3_c;
      this.star_4_c = res.data.payload.star_4_c;
      this.star_5_c = res.data.payload.star_5_c;
      this.star_t_c = this.star_1_c + this.star_2_c +this.star_3_c + this.star_4_c + this.star_5_c;
      this.star_adv = res.data.payload.star_adv;
    })
    .catch((err) => {
      console.log("eror")
    });
  },
};
</script>
<style>
h4.sub-title{
  background-color: #d9d1da;
    padding: 11px 11px;
    margin: 11px 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid;
    color: #451646;
}
.c-block-1 .rate-1 {
    display: flex;
    justify-content: space-between;
}
.c-block-1 h5 {
  color: #7e1280;
    font-size: 15px;
    margin-bottom: 5px;
}
.c-block-1 {
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #d7d7d7;
}
.svg-cont{
  margin: auto;
  display: table;
}
.c-block-2 h5{
  margin: auto;
  margin-bottom: 21px;
    display: table;
}
</style>