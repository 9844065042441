<template>
  <div>
    <page-component style="position: relative" :loading="loading">
    <template #content>
        
          <v-sheet class="px-5" style="margin-top: 10px; padding-top: 15px">
            <div class="supportContainer">
              <div style="margin-bottom: 40px">
                <div>
                  <div v-for="(item, index) in request_logs" :key="item.id">
                    <div class="lightArrow" v-if="index % 2 === 0">
                      <div
                        :style="`border-color:${returnColor(item)} `"
                        style="text-align: end; width: 371px"
                        class="dateContainer"
                      ></div>
                      <div
                        style="
                          height: 79px;
                          border: 1px dashed #e1d9e1;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        "
                      >
                        <div
                          :style="`background:${returnColor(item)}`"
                          style="
                            width: 40px;
                            height: 40px;
                            position: absolute;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding-top: 4px;
                          "
                        >
                          <OrderIcons :item="item" />
                        </div>
                      </div>

                      <div style="width: 42px; display: flex; justify-content: end">
                        <div
                          class="side__arrow"
                          :style="`border-left:8px solid ${returnColor(item)}; margin-lef: 4px`"
                        ></div>
                      </div>

                      <div class="leftBox" :style="`border-color:${returnColor(item)} `">
                        <div style="display: flex; justify-content: space-between">
                          <div class="customerName" style="font-size: 14px; color: #3f0e40">
                            {{ item.status }}

                            <button
                              class="mobile-link"
                              v-if="item.status.includes('أضافة تذكرة برقم')"
                              @click="to_task_content(item.status.match(/\b\d+\b/g))"
                            >
                               التذكرة
                            </button>

                            <!-- {{ item.text }} -->
                          </div>
                          <div
                            style="
                              display: flex;
                              justify-content: space-between;
                              font-size: 12px;
                              font-weight: 600;
                              color: #a4a3a3;
                            "
                          >
                            {{ item.raw_date.split("T")[0] }} -
                            {{ item.raw_date.split("T")[1].split(".")[0] }}
                          </div>
                        </div>

                        <v-tooltip bottom color="#451646">
                          <template v-slot:activator="{ on }">
                            <v-card-text
                              v-on="on"
                              class="pb-0 pt-0 px-0"
                              style="font-size: 14px"
                              v-if="!item.text.includes('مكالمة جديده')"
                            >

                              <div v-if="currentRole == 0 && sources_text.includes(item.text)">
                                  {{ "" }}
                              </div>
                              <div v-else>
                                {{
                                  item.value
                                    ? item.value.length > 50
                                      ? item.value.substring(0, 50) + "..."
                                      : item.value
                                    : ""
                                }}
                              </div>

                              <!-- {{
                                item.value
                                  ? item.value.length > 50
                                    ? item.value.substring(0, 50) + "..."
                                    : item.value
                                  : ""
                              }} -->
                            </v-card-text>
                            <audio controls v-else>
                                                <source :src="item.value" type="audio/ogg">
                                              Your browser does not support the audio element.
                                              </audio>
                          </template>
                          <div style="font-size: 14px">
                            {{ item.value }}
                          </div>
                        </v-tooltip>
                      </div>
                    </div>

                    <div class="darkArrow" v-else>
                      <div class="leftBox" :style="`border-color:${returnColor(item)} `">
                        <div class="customerName">
                          <div style="display: flex; justify-content: space-between">
                            <div class="customerName" style="font-size: 14px; color: #3f0e40">
                              {{ item.text }}
                            </div>
                            <div
                              style="
                                display: flex;
                                justify-content: space-between;
                                font-size: 12px;
                                font-weight: 600;
                                color: #a4a3a3;
                              "
                            >
                              {{ item.raw_date.split("T")[0] }} -
                              {{ item.raw_date.split("T")[1].split(".")[0] }}
                            </div>
                          </div>
                        </div>
                        <v-tooltip bottom color="#451646">
                          <template v-slot:activator="{ on }">
                            <v-card-text
                              v-on="on"
                              class="pb-0 pt-0 px-0"
                              style="font-size: 14px; margin-top: -4px"
                              v-if="!item.text.includes('مكالمة جديده')"
                            >

                              <div v-if="currentRole == 0 && sources_text.includes(item.text)">
                                  {{ "" }}
                              </div>
                              <div v-else>
                                {{
                                  item.value
                                    ? item.value.length > 50
                                      ? item.value.substring(0, 50) + "..."
                                      : item.value
                                    : ""
                                }}
                              </div>

                              <!-- {{
                                item.value
                                  ? item.value.length > 50
                                    ? item.value.substring(0, 50) + "..."
                                    : item.value
                                  : ""
                              }} -->
                            </v-card-text>
                            <audio controls v-else>
                                                <source :src="item.value" type="audio/ogg">
                                              Your browser does not support the audio element.
                                              </audio>
                          </template>
                          <div style="font-size: 14px">
                            {{ item.value }}
                          </div>
                        </v-tooltip>
                      </div>
                      <div style="width: 42px; display: flex; justify-content: start">
                        <div
                          class="side__arrow"
                          style="margin-right: -4px"
                          :style="`border-right:8px solid ${returnColor(item)}`"
                        ></div>
                      </div>
                      <div
                        style="
                          height: 79px;
                          border: 1px dashed #e1d9e1;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        "
                      >
                        <div
                          :style="`background:${returnColor(item)}`"
                          style="
                            width: 40px;
                            height: 40px;
                            position: absolute;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding-top: 4px;
                          "
                        >
                          <OrderIcons :item="item" />
                        </div>
                      </div>
                      <div class="dateContainer" style="width: 371px"></div>
                    </div>
                  </div>
                  <div v-if="request_logs.length == 0">
                      
                    <v-alert
                      color="blue"
                      dense
                      outlined
                      type="info"
                    >
                    لا توجد بيانات في <strong>سجل الطلب</strong>
                    </v-alert>
                  </div>
                </div>
              </div>
            </div>
      </v-sheet>
    </template>
  </page-component>
  <TasksDetailsDialog v-if="this.$store.state.TaskDetailsDialog" />
  </div>
</template>

<script>
import Axios from "axios";
import OrderIcons from "../RequestDetailsForDefaultVue/OrderIcons.vue";
import TasksDetailsDialog from "./TasksDetailsDialog.vue";

export default {
  props: ["agent"],
  components: { OrderIcons,TasksDetailsDialog },
  data() {
    return {
      request_logs: [],
      date: {
        birth_date: "",
        birth_date_higri: "",
      },
      birth_date: true,
      loading: false,
      sources_text : [
        'إضافة الطلب من عطارد',
        'إضافة الطلب من الموقع الإلكتروني',
        'إضافة الطلب من تمويلك',
        'إضافة الطلب من قبل مدير النظام',
        'إضافة الطلب من موقع الحاسبة',
        'طلب مضاف من قبل مدير النظام',
        'إضافة الطلب من واتس اب'
      ],
      currentRole : window.localStorage.getItem("role"),
    };
  },
  created() {
    this.get_request_logs();
    this.$store.state.TaskDetailsDialog = false;

  },

  methods: {
    returnColor(item) {
      if (
        item.english_status == "sended_request" ||
        item.english_status == "created_request" ||
        item.english_status == "completed_request"
      ) {
        return "#25D366";
      } else if (
        item.english_status == "rejected_request" ||
        item.english_status == "moved_request"
      ) {
        return "#EE0000";
      } else if (item.english_status == "agent_notes") {
        return "#E5B5E5;";
      } else if (item.english_status == "sarvice_rating") {
        return "#FF9A6C";
      } else if (item.english_status == "pulled_request") {
        return "#E00";
      } else if (item.english_status == "duplicated_request") {
        return "#047D87";
      } else if (item.english_status == "ask_for_real_estate") {
        return "#DBB622";
      } else {
        return "#3F0E40";
      }
    },
    getAlertColor(index) {
      return index % 2 === 0 ? "#90DBAE" : "#e6b5e6";
    },

    get_request_logs() {
      this.loading = true;
      Axios.get(
        `https://api.alwsatarealestate.com/api/agent/Request/History-With-Notes/${this.agent.id}`,
        {
          headers: this.request_headers(),
        }
      )
        .then((response) => {
          console.log("done");
          this.request_logs = response.data.payload;
          this.loading = false;
          this.setBreadCrumbs(["الرئيسية", "الطلبات", this.agent.customer.name]);
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
          this.loading = false;
        });
    },

    to_task_content(taskId) {
      this.$store.state.tasksDetailsRequestId = taskId;
      this.$store.state.TaskDetailsDialog = true;
    },
  },
  watch: {
    agent_data: {
      handler(val) {
        val.customer.birth_date = this.date.birth_date;
        val.customer.birth_date_higri = this.date.birth_date_higri;
        val.source = parseInt(val.source);
        val.customer.work = parseInt(val.customer.work);
        this.setBreadCrumbs(["الرئيسية", "الطلبات", val.customer.name]);
      },
    },
  },
};
</script>
<style lang="scss">
.leftBox {
  width: 329px;
  /*height: 72px;*/
  height: unset;
  border: 1px solid #714d72;
  padding: 10px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 8px;
}

.side__arrow {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;

  transform: translateX(-50%);
  margin-left: 4px;
}
.log-page {
  .v-timeline-item__divider {
    z-index: 1;
  }

  .theme--light.v-timeline::before {
    border: 2px solid #3f0e40;
    margin-top: 23px;
    height: calc(100% - 63px);
    opacity: 0.5;
  }

  .logDate {
    position: absolute;
    right: -45px;
    color: #444b5a;
    font-size: 12px;
  }

  .info-dad {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .v-application--is-rtl .v-timeline--dense:not(.v-timeline--reverse)::before {
    //background-color: #4516466c !important;
    z-index: 1;
    width: 3px !important;
    margin-right: 1px;
  }

  .theme--light.v-timeline .v-timeline-item__dot {
    //border: 2px solid #3F0E40 !important;
    margin-top: 18px;

    & > div {
      background-color: white !important;
    }
  }
}

@media (max-width: 578px) {
  .log-page {
    .v-alert {
      width: 110% !important;
      transform: translateX(28px) !important;
    }

    .v-alert__content {
      span:not(:first-child) {
        font-size: 10px !important;
      }

      span:first-child {
        font-size: 13px !important;
      }
    }

    .v-timeline-item__dot {
      height: 25px !important;
      width: 25px !important;
      z-index: 1;
    }

    .v-timeline-item__inner-dot {
      display: inline-block !important;
    }
  }
}
</style>
