<template>
  <page-component style="position: relative">
    <template #content>
      <!-- eslint-disable -->
      <!-- بيانات العميل -->
      <v-sheet class="px-5 py-5">
        <span class="font-weight-bold" style="display: inline"
          >بيانات الدفعة و الالتزامات</span
        >
        <v-form class="mt-10" :disabled="!for_agent && agent.type != 'رهن-شراء'">
          <v-row>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                
                قيمة الشيك
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.real_estate.cost"
                class="mr-1"
                outlined
                dense
                readonly
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5" v-if="agent.type != 'رهن-شراء'">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('realCost')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                القيمة الفعلية للعقار
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.realCost"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
                
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('incValue')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                قيمة الزيادة
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.incValue"
                class="mr-1"
                outlined
                dense
                :readonly="prepaymentFieldsDisbled || agent.type == 'رهن-شراء'"
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('prepaymentVal')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                قيمة الدفعة
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.prepaymentVal"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('prepaymentPre')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                نسبة الدفعة
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.prepaymentPre"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('prepaymentCos')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                المبلغ
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.prepaymentCos"
                class="mr-1"
                outlined
                :readonly="prepaymentFieldsDisbled || agent.type == 'رهن-شراء'"
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('netCustomer')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                الصافي للعميل
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.netCustomer"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('deficitCustomer')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                عجز العميل
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.deficitCustomer"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
        </v-row>
        </v-form>
        <span class="font-weight-bold" style="display: inline" >بيانات تساهيل</span>
        <v-form class="mt-10" :disabled="!for_agent && agent.type != 'رهن-شراء'">
          <v-row>

            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('visa')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                بطاقة الفيزا
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.visa"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('carLo')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                قرض سيارة
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.carLo"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('personalLo')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                قرض شخصي
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.personalLo"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('realLo')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                قرض عقاري
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.realLo"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('credit')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                بنك التسليف
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.credit"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5" v-if="agent.type == 'رهن-شراء'">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('Real_estate_disposition_value')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                التصرف العقاري
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.Real_estate_disposition_value"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5" v-if="agent.type == 'رهن-شراء'">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('purchase_tax_value')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                ضريبة الشراء
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.purchase_tax_value"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('other')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                اخرى
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.other"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('debt')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                اجمالي المديونية
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.debt"
                class="mr-1"
                outlined
                :readonly="prepaymentFieldsDisbled || agent.type == 'رهن-شراء'"
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('mortPre')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                نسبة الرهن
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.mortPre"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('mortCost')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                المبلغ
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.mortCost"
                class="mr-1"
                outlined
                dense
                type="number"
                :disabled="agent.type == 'رهن-شراء'"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('proftPre')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                نسبة السعي
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.proftPre"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('profCost')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                المبلغ
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.profCost"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('addedVal')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                القيمة المضافة
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.addedVal"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('adminFee')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                الرسوم الادارية
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.adminFee"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            
          </v-row>
        </v-form>
        <span class="font-weight-bold" style="display: inline" >حاسبة الرهن العقاري</span>
        <v-form class="mt-10" :disabled="!for_agent">
          <v-row>
            <v-col cols="12"  class="py-0 mb-5" v-if="agent.type == 'رهن-شراء'">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('realCost')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                القيمة الفعلية للعقار
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.realCost"
                class="mr-1"
                outlined
                dense
                disabled
                type="number"
                hide-spin-buttons
                
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 mb-5" v-if="agent.type != 'رهن-شراء'">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('real_property_cost')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                عرض السعر ( قيمة العقار )
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="Mortgage.realCost"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="py-0 mb-5" v-if="agent.type != 'رهن-شراء'">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('personalFun_cost')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                مبلغ التمويل الشخصي
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="Mortgage.fundPers"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <span>الوضع الإئتماني للعميل</span>
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="2" xs="2" class="py-0 mb-5">نوع الالتزام</v-col>
            <v-col cols="12" lg="5" md="5" sm="5" xs="5" class="py-0 mb-5">المبلغ</v-col>
            <v-col cols="12" lg="5" md="5" sm="5" xs="5" class="py-0 mb-5" v-show="agent.type != 'رهن-شراء'">النسبة</v-col>
            <v-col cols="12" lg="5" md="5" sm="5" xs="5" class="py-0 mb-5" v-show="agent.type == 'رهن-شراء'"></v-col>
            <!-- row -->
            <v-col cols="12" lg="2" md="2" sm="2" xs="2" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('personalLo')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                عقاري
              </div>
            </v-col>
            <v-col cols="12" lg="5" md="5" sm="5" xs="5" class="py-0 mb-5">
              
              <v-text-field
                color=""
                hide-details
                v-model="Mortgage.realLo"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="5" md="5" sm="5" xs="5" class="py-0 mb-5">
              <v-autocomplete
                v-show="agent.type != 'رهن-شراء'"
                :items="ListOptions"
                hide-details
                v-model="Mortgage.mortgaged_percentage"
                item-text="value"
                item-value="key"
                class="mr-1"
                outlined
                dense
              />
            </v-col>
            <!-- row -->
            <v-col cols="12" lg="2" md="2" sm="2" xs="2" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('personalLo')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                التصرف العقاري
              </div>
            </v-col>
            <v-col cols="12" lg="5" md="5" sm="5" xs="5" class="py-0 mb-5">
              
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.Real_estate_disposition_value"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="5" md="5" sm="5" xs="5" class="py-0 mb-5">
              <v-autocomplete
                v-show="agent.type != 'رهن-شراء'"
                :items="ListOptions"
                hide-details
                v-model="Mortgage.Real_estate_disposition_percentage"
                item-text="value"
                item-value="key"
                class="mr-1"
                outlined
                dense
              />
            </v-col>
            <!-- row -->
            <v-col cols="12" lg="2" md="2" sm="2" xs="2" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('personalLo')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                ضريبة الشراء
              </div>
            </v-col>
            <v-col cols="12" lg="5" md="5" sm="5" xs="5" class="py-0 mb-5">
              
              <v-text-field
                color=""
                hide-details
                v-model="agent.prepayments.purchase_tax_value"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="5" md="5" sm="5" xs="5" class="py-0 mb-5">
              <v-autocomplete
                v-show="agent.type != 'رهن-شراء'"
                :items="ListOptions"
                hide-details
                v-model="Mortgage.purchase_tax_percentage"
                item-text="value"
                item-value="key"
                class="mr-1"
                outlined
                dense
              />
            </v-col>
            <!-- row -->
            <v-col cols="12" lg="2" md="2" sm="2" xs="2" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('personalLo')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                الدفعة الأولى
              </div>
              
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="2" xs="2" class="py-0 mb-5">
              <v-select
                v-show="agent.type != 'رهن-شراء'"
                :items="ListOptions"
                hide-details
                v-model="Mortgage.first_batch_from_realValue"
                item-text="value"
                item-value="key"
                class="mr-1"
                outlined
                dense
              />
              <v-checkbox 
                v-show="agent.type != 'رهن-شراء'" v-model="Mortgage.presonal_funding_discount" style="position: relative; top: -40px; display: inline; right: 155px;" label=""></v-checkbox>

            </v-col>
            <v-col cols="12" lg="3" md="3" sm="3" xs="3" class="py-0 mb-5">
              <v-text-field
                color=""
                hide-details
                v-model="Mortgage.preValue"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="5" md="5" sm="5" xs="5" class="py-0 mb-5" >
              <v-autocomplete
                v-show="agent.type != 'رهن-شراء'"
                :items="ListOptions"
                hide-details
                v-model="Mortgage.first_batch_percentage"
                item-text="value"
                item-value="key"
                class="mr-1"
                outlined
                dense
              />
            </v-col>
            <!-- row -->
            <v-col cols="12" lg="2" md="2" sm="2" xs="2" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('personalLo')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                شخصي
              </div>
            </v-col>
            <v-col cols="12" lg="5" md="5" sm="5" xs="5" class="py-0 mb-5">
              
              <v-text-field
                color=""
                hide-details
                v-model="Mortgage.personalLo"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="5" md="5" sm="5" xs="5" class="py-0 mb-5">
              <v-autocomplete
                v-show="agent.type != 'رهن-شراء'"
                :items="ListOptions"
                hide-details
                v-model="Mortgage.perlo_percentage"
                item-text="value"
                item-value="key"
                class="mr-1"
                outlined
                dense
              />
            </v-col>
            <!-- row -->
            <v-col cols="12" lg="2" md="2" sm="2" xs="2" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('personalLo')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                سيارة
              </div>
            </v-col>
            <v-col cols="12" lg="5" md="5" sm="5" xs="5" class="py-0 mb-5">
              
              <v-text-field
                color=""
                hide-details
                v-model="Mortgage.carLo"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="5" md="5" sm="5" xs="5" class="py-0 mb-5">
              <v-autocomplete
                v-show="agent.type != 'رهن-شراء'"
                :items="ListOptions"
                hide-details
                v-model="Mortgage.car_percentage"
                item-text="value"
                item-value="key"
                class="mr-1"
                outlined
                dense
              />
            </v-col>
            <!-- row -->
            <v-col cols="12" lg="2" md="2" sm="2" xs="2" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('visa_mortgage')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                فيزا
              </div>
            </v-col>
            <v-col cols="12" lg="5" md="5" sm="5" xs="5" class="py-0 mb-5">
              
              <v-text-field
                color=""
                hide-details
                v-model="Mortgage.preVisa"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="5" md="5" sm="5" xs="5" class="py-0 mb-5">
              <v-autocomplete
                v-show="agent.type != 'رهن-شراء'"
                :items="ListOptions"
                hide-details
                v-model="Mortgage.visa_percentage"
                item-text="value"
                item-value="key"
                class="mr-1"
                outlined
                dense
              />
            </v-col>
            <!-- row -->
            <v-col cols="12" lg="2" md="2" sm="2" xs="2" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('personalLo')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                غير ذلك
              </div>
            </v-col>
            <v-col cols="12" lg="5" md="5" sm="5" xs="5" class="py-0 mb-5">
              
              <v-text-field
                color=""
                hide-details
                v-model="Mortgage.otherLo"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="5" md="5" sm="5" xs="5" class="py-0 mb-5">
              <v-autocomplete
                v-show="agent.type != 'رهن-شراء'"
                :items="ListOptions"
                hide-details
                v-model="Mortgage.beside_percentage"
                item-text="value"
                item-value="key"
                class="mr-1"
                outlined
                dense
              />
            </v-col>
            <!-- row -->
            <v-col cols="12" lg="4" md="4" sm="4" xs="4" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('personalLo')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                رسوم أخري
              </div>
            </v-col>
            <v-col cols="12" lg="8" md="8" sm="8" xs="8" class="py-0 mb-5">
              
              <v-text-field
                color=""
                hide-details
                v-model="Mortgage.otherFees"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <!-- bottom -->
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('mortgage_debt')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                إجمالية المديونية
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="Mortgage.mortgage_debt"
                class="mr-1"
                outlined
                readonly
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('mortgage_debt_with_tax')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                إجمالي المبلغ
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="Mortgage.mortgage_debt_with_tax"
                class="mr-1"
                outlined
                dense
                readonly
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('net_to_customer')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                الصافي للعميل
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="Mortgage.net_to_customer"
                class="mr-1"
                outlined
                readonly
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="justify-center" v-if="agent.type != 'رهن-شراء'">
              <v-btn color="primary" @click="saveMortgageData()" class="font-weight-bold" >حفظ النتيجة</v-btn>
            </v-col>

          </v-row>
        </v-form>
      </v-sheet>
    </template>
  </page-component>
</template>

<script>
import Axios from "axios";
export default {
  props: ["agent"],
  data() {
    return {
      name: "اهلا اهلا",
      productTest: null,
      agent_data: {},
      date: {
        birth_date: "",
        birth_date_higri: "",
      },
      birth_date: true,
      ListOptions: [ ],
      ListOptions2: [
        {
          key: 5,
          value: 5
        },
        {
          key: 10,
          value: 10
        },
        {
          key: 30,
          value: 30
        }
      ],
      Mortgage:{
        realCost : 0,
        fundPers: 0,
        realLo: 0,
        mortgaged_percentage: 0,
        realDisposition: 0,
        Real_estate_disposition_percentage:0,
        purchaseTax: 0,
        purchase_tax_percentage: 0,
        presonal_funding_discount: false,
        preValue: 0,
        first_batch_from_realValue: 0,
        first_batch_percentage:0,
        personalLo: 0,
        perlo_percentage:0,
        carLo:0,
        car_percentage: 0,
        preVisa:0,
        visa_percentage: 0,
        otherLo: 0,
        beside_percentage: 0,
        otherFees: 0,
        mortgage_debt: 0,
        mortgage_debt_with_tax: 0,
        net_to_customer: 0,

      },
    };
  },
  computed: {
    for_agent() {
      return (
        this.$store.state.request_info.statusReq == 0 ||
        this.$store.state.request_info.statusReq == 1 ||
        this.$store.state.request_info.statusReq == 2 ||
        this.$store.state.request_info.statusReq == 4
      );
    },
    prepaymentFieldsDisbled() {
      return (
        this.$store.state.request_info.statusReq == 0 ||
        this.$store.state.request_info.statusReq == 1 ||
        this.$store.state.request_info.statusReq == 2 ||
        this.$store.state.request_info.statusReq == 4 ||
        this.$store.state.request_info.statusReq == 31
      );
    },
  },
 
  mounted() {
    
    this.ListOptions = [
        {
          key: 0,
          value: 0
        },
      ]
    for (let index = 10; index <= 50; index++) {
      this.ListOptions.push({key: index, value: index});
      
    }
  },
  watch: {
    agent: {
      handler(val) {
        if (val) {
          this.$emit("update:agent", val);
          this.setMortgage(val);
          this.$store.state.request_info = val;
          this.date.birth_date = val.customer.birth_date;
          this.date.birth_date_higri = val.customer.birth_date_higri;
          val.customer.work = parseInt(val.customer.work);
          this.setBreadCrumbs(["الرئيسية", "الطلبات", val.customer.name]);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setMortgage(agentRow){
        this.Mortgage.realCost  = agentRow.real_estate.cost;
        this.Mortgage.fundPers = agentRow.funding.personalFun_cost;
        this.Mortgage.realLo = agentRow.prepayments.realLo;
        this.Mortgage.mortgaged_percentage = this.CheckNull(agentRow.prepayments.mortgaged_percentage);
        this.Mortgage.realDisposition = this.agent.prepayments.Real_estate_disposition_value;
        // this.agent.prepayments.realDisposition = agentRow.prepayments.Real_estate_disposition_value;
        this.Mortgage.Real_estate_disposition_percentage = this.CheckNull(agentRow.prepayments.Real_estate_disposition_percentage);
        // this.agent.prepayments.purchaseTax = agentRow.prepayments.purchase_tax_value;
        this.Mortgage.purchaseTax = this.agent.prepayments.purchase_tax_value;
        this.Mortgage.purchase_tax_percentage = this.CheckNull(agentRow.prepayments.purchase_tax_percentage);
        this.Mortgage.presonal_funding_discount = agentRow.prepayments.presonal_funding_discount;
        this.Mortgage.first_batch_from_realValue = agentRow.prepayments.first_batch_from_realValue;
        if(this.Mortgage.first_batch_from_realValue != 5 && this.Mortgage.first_batch_from_realValue != 30){
          this.Mortgage.first_batch_from_realValue = 10;
        }
        // this.Mortgage.first_batch_value = agentRow.prepayments.prepaymentVal;
        this.Mortgage.preValue = agentRow.prepayments.prepaymentVal;
        // 
        if (agentRow.real_estate.cost != null && agentRow.real_estate.cost != 0 && agentRow.real_estate.cost != '' && (agentRow.prepayments.prepaymentVal == '' || agentRow.prepayments.prepaymentVal == null))
            this.Mortgage.preValue = agentRow.real_estate.cost * (this.Mortgage.first_batch_from_realValue / 100);
        else if (agentRow.prepayments.prepaymentVal != 0)
            this.Mortgage.preValue = agentRow.prepayments.prepaymentVal;
        else
            this.Mortgage.preValue = 0;

        // 
        this.Mortgage.first_batch_percentage = this.CheckNull(agentRow.prepayments.first_batch_percentage);
        this.Mortgage.personalLo = agentRow.prepayments.personalLo;
        this.Mortgage.perlo_percentage = this.CheckNull(agentRow.prepayments.perlo_percentage);
        this.Mortgage.carLo = agentRow.prepayments.carLo;
        this.Mortgage.car_percentage = this.CheckNull(agentRow.prepayments.car_percentage);
        this.Mortgage.preVisa = agentRow.prepayments.visa;
        this.Mortgage.visa_percentage = this.CheckNull(agentRow.prepayments.visa_percentage);
        this.Mortgage.otherLo = agentRow.prepayments.other;
        this.Mortgage.beside_percentage = this.CheckNull(agentRow.prepayments.beside_percentage);
        this.Mortgage.otherFees = agentRow.prepayments.other_fees;
        this.Mortgage.mortgage_debt = agentRow.prepayments.mortgage_debt;
        this.Mortgage.mortgage_debt_with_tax = agentRow.prepayments.mortgage_debt_with_tax;
        this.Mortgage.net_to_customer = agentRow.prepayments.net_to_customer;
        this.mortgageDebtCalculate();
    },
    mortgageDebtCalculate() {
            let real_property_cost = this.Mortgage.realCost;
            let mortgaged_value = this.Mortgage.realLo;
            let Real_estate_disposition_value = this.agent.prepayments.Real_estate_disposition_value;
            let purchase_tax_value = this.agent.prepayments.purchase_tax_value;
            let first_batch_value = this.Mortgage.preValue;
            let personal_mortgage = this.Mortgage.personalLo;
            let car_mortgage = this.Mortgage.carLo;
            let visa_mortgage = this.Mortgage.preVisa;
            let beside_value = this.Mortgage.otherLo;
            let other_fees = this.Mortgage.otherFees;
            // start (get the percentage value for every commitment)
            let mortgaged_percentage = this.Mortgage.mortgaged_percentage;
            let Real_estate_disposition_percentage = this.Mortgage.Real_estate_disposition_percentage;
            let purchase_tax_percentage = this.Mortgage.purchase_tax_percentage;
            let first_batch_percentage = this.Mortgage.first_batch_percentage;
            let perlo_percentage = this.Mortgage.perlo_percentage;
            let car_percentage = this.Mortgage.car_percentage;
            let visa_percentage = this.Mortgage.visa_percentage;
            let beside_percentage = this.Mortgage.beside_percentage;
            // End (get the value for every commitment)
            /////////////////////////////////////////
            // get total commitment without tax (0.15)
            let total_commitment_without_percentage_tax = ((mortgaged_value) + ((mortgaged_value) * (mortgaged_percentage / 100))) +
                ((Real_estate_disposition_value) + ((Real_estate_disposition_value) * (Real_estate_disposition_percentage / 100))) +
                ((purchase_tax_value) + ((purchase_tax_value) * (purchase_tax_percentage / 100))) +
                ((first_batch_value) + ((first_batch_value) * (first_batch_percentage / 100))) +
                ((personal_mortgage) + ((personal_mortgage) * (perlo_percentage / 100))) +
                ((car_mortgage) + ((car_mortgage) * (car_percentage / 100))) +
                ((visa_mortgage) + ((visa_mortgage) * (visa_percentage / 100))) +
                ((beside_value) + ((beside_value) * (beside_percentage / 100)));

            let total_commitment = mortgaged_value +
                Real_estate_disposition_value +
                purchase_tax_value +
                first_batch_value +
                personal_mortgage +
                car_mortgage +
                visa_mortgage +
                beside_value;
            // +other_fees;

            this.Mortgage.mortgage_debt = Math.ceil(total_commitment);
            this.Mortgage.mortgage_debt_with_tax = Math.ceil(total_commitment_without_percentage_tax);
            this.Mortgage.net_to_customer  = Math.ceil(real_property_cost - (total_commitment_without_percentage_tax + other_fees));
            // realEstateDispositionCalculate()
        },
    saveMortgageData(){
      let BodyData = {};
      BodyData.request_id = this.agent.id;
      for (const prop in this.Mortgage) {
        if (this.Mortgage[prop] != null) {
          if(prop == 'realDisposition' || prop == 'purchaseTax' || prop == 'mortgage_debt' || prop == 'mortgage_debt_with_tax' || prop == 'net_to_customer'){
            BodyData[prop] = parseFloat(this.Mortgage[prop]);
          }else{
            BodyData[prop] = parseInt(this.Mortgage[prop]);
          }
        }
      }
      Axios.post(
        "https://api.alwsatarealestate.com/api/agent/Save/Mortgage/Data",
        BodyData,
        { headers: this.request_headers() }
      )
        .then((res) => {
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          this.showPopupError("success", err.response.data.message.split(","));
        });
    },
    CheckNull(val){
      if(val == null || val == undefined){
        return 0;
      }else{
        return val; 
      }
    }
  },
};
</script>
