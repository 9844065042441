<template>
  <div>
    <HeaderIcon :selectedItems="selectedItems" />
    <div v-if="!this.$store.state.freezeLoading" style="margin-top: 20px">
      <div v-if="$store.state.showCards === true">
        <div v-if="this.$store.state.freezeData.length > 0">
          <RequestsCard
            :CardData="this.$store.state.freezeData"
            v-if="!this.$store.state.freezeLoading"
            @pageCount="handlePageNumber"
            @back="handleBack"
          />
          <div>
            <LoadingMore v-if="loadMoreData" style="margin: 40px" />
          </div>
        </div>
        <div class="noData" v-else>لا يوجد بيانات</div>
      </div>
      <div v-else class="table__view">
        <table-component
          :showSelect="true"
          itemKey="id"
          :selectedRows.sync="selected_requests"
          :headers="headers"
          :pagination="this.$store.state.pagination"
          :limit="$store.state.counterOfShow"
          :items="this.$store.state.tableData"
          :loading="loading"
          @refresh="getTableData"
          v-model="page"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="#3f0e40" small depressed dark v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <FreezingSingleProcess :item="item.id" />
              </v-list>
            </v-menu>
          </template>
        </table-component>
        <OrderRequestSection
          v-if="this.$store.state.openOrderRequestSection"
          style="margin-top: 50px"
        />
      </div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
      "
      v-else
    >
      <LoadingMore />
    </div>
    <QualityDialog :selectedItems="selectedItems" />
    <FreezingSearch />
    <OrderRequestDialog />
    <CallsDialog />
  </div>
</template>

<script>
import Axios from "axios";
import CallsDialog from "@/components/Charts/CallsDialog.vue";
import RequestsCard from "@/components/Charts/RequestsCard.vue";
import LoadingMore from "@/components/Charts/LoadingMore.vue";
import { SourceValue } from "@/mixins/sourceValue";
import { formatDate } from "@/mixins/formatDate";
import QualityDialog from "@/components/AdminComponent/QualityDialog.vue";
import HeaderIcon from "@/views/admin/Requests/Freezing/HeaderIcon.vue";
import FreezingSearch from "@/views/admin/Requests/Freezing/FreezingSearch.vue";
import FreezingSingleProcess from "./FreezingSingleProcess.vue";
import OrderRequestDialog from "@/components/AdminComponent/OrderRequestDialog.vue";
import OrderRequestSection from "@/components/AdminComponent/OrderRequestSection.vue";

export default {
  name: "AdminSupport",
  components: {
    FreezingSearch,
    HeaderIcon,
    QualityDialog,
    RequestsCard,
    LoadingMore,
    OrderRequestSection,
    OrderRequestDialog,
    FreezingSingleProcess,
    CallsDialog,
  },
  data() {
    return {
      allRequests: [],
      selectedItems: [],
      copySupportRequests: [],
      loadMoreData: false,
      loadingData: false,
      selected_requests: [],
      tableData: [],
      pagination: {},
      qualityUsers: [],
      loading: false,
      page: 0,
      allGetData: [],

      headers: [
        { align: "center", value: "data-table-select" },
        { text: "رقم الطلب", value: "رقم الطلب", align: "center" },
        { text: "تصنيف ما قبل التجميد", value: "تصنيف ما قبل التجميد", align: "center" },
        { text: "تاريخ الطلب", value: "تاريخ الطلب", align: "center" },
        { text: "مصدر المعامله", value: "مصدر المعامله", align: "center" },
        { text: "عميل", value: "عميل", align: "center" },
        { text: "الجوال", value: "الجوال", align: "center" },
        { text: "العمليات", value: "action", align: "center" },
      ],
    };
  },

  beforeDestroy() {
    this.$store.state.openOrderRequestSection = false;
  },

  watch: {
    selected_requests(val) {
      this.selectedItems = val;
      this.$emit("requests", val);
    },

    page(val) {
      if (this.$store.state.isSearch === false) {
        this.getTableData(val);
      } else {
        this.searchRequest(val, "table");
      }
    },

    "$store.state.counterOfShow": {
      handler(newval, oldvalue) {
        if (newval != oldvalue) {
          this.getTableData(this.page);
        }
      },
    },

    $route: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.state.counterOfShow = 10;
        }
      },
    },
  },

  methods: {
    handleBack() {
      this.$store.state.freezeData = [];
      this.$store.state.tableData = [];
      this.$store.state.freezeLoading = true;
      this.$store.state.isSearch = false;
      this.$store.state.back = false;
      this.getAllRequests(1, "back");
    },

    handlePageNumber(val) {
      if (this.$store.state.isSearch === false) {
        this.getAllRequests(val);
      } else {
        this.searchRequest(val);
      }
    },

    sendToQuality() {
      if (this.selectedItems.length > 0) {
        this.$store.state.dialogQuality = true;
      }
    },

    searchRequest(page, type) {
      this.loading = true;

      if (type === "table") {
        this.$store.state.tableData = [];
      }

      if (page > 1 || type === "table") {
        this.$store.state.bodySearch.delete("page");
        this.$store.state.bodySearch.append("page", page);

        let url = `${this.$store.state.url}/v2/employee/Requests/search?type=freez`;
        Axios.post(url, this.$store.state.bodySearch, {
          headers: this.request_headers(),
        }).then((res) => {
          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.searched,
          };
          let response = res.data.payload.data;
          response.map((item) => {
            if (item.length === 0) {
              this.loading = false;
            }
            this.setTbData(item);
            item["starChecked"] = false;
            return item;
          });

          this.$store.state.freezeSearch.push(...response);
          this.removeDublicate(this.$store.state.freezeSearch);
          this.loading = false;
        });
      }
    },

    removeDublicate(array) {
      let uniqueArray = array.filter((item, index) => {
        return (
          index ===
          array.findIndex((obj) => {
            return obj.id === item.id; // Compare based on the 'id' property
          })
        );
      });
      this.$store.state.freezeData = uniqueArray;
      this.lodingToGetData = false;
    },

    setTbData(items) {
      const newItem = {
        id: items.id,
        "رقم الطلب": items.id,
        "تصنيف ما قبل التجميد": items.classification_before_to_freeze,
        "تاريخ الطلب": items.created_at ? formatDate(items.created_at) : "",
        "مصدر المعامله": SourceValue(items.source),
        عميل: items.customer.name,
        الجوال: items.customer.mobile,
      };

      // Check if the object with the same id exists in tableData array
      const exists = this.$store.state.tableData.some((item) => item.id === newItem.id);

      if (!exists) {
        this.$store.state.tableData.push(newItem);
      }
    },

    getTableData(count) {
      this.$store.state.tableData = [];
      this.loading = true;
      Axios.get(
        `${this.$store.state.url}/v2/employee/Requests?type=freez&page=${count}&per_page=${this.$store.state.counterOfShow}`,
        { headers: this.request_headers() }
      ).then((res) => {
        let response = res.data.payload.data;
        response.map((item) => {
          this.setTbData(item);
        });
        this.loading = false;
      });
    },

    getAllRequests(count) {
      if (
        this.$store.state.freezeData.length === 0 &&
        this.copySupportRequests.length === 0
      ) {
        this.$store.state.freezeLoading = true;
      }

      this.loadMoreData = true;
      Axios.get(
        `https://api.alwsatarealestate.com/api/v2/employee/Requests?type=freez&page=${count}&per_page=${this.$store.state.counterOfShow}`,
        { headers: this.request_headers() }
      )
        .then((res) => {
          let response = res.data.payload.data.map((item) => {
            this.setTbData(item);
            item["starChecked"] = false;
            return item;
          });
          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.count_of_freez,
          };
          this.$store.state.freezeData.push(...response);
          this.copySupportRequests.push(...response);
        })
        .catch((err) => {
          this.$store.state.freezeLoading = false;
          this.loadMoreData = false;
        })
        .finally(() => {
          this.loadMoreData = false;
          this.$store.state.freezeLoading = false;
        });
    },
  },

  created() {
    this.$store.state.freezeData = [];
    this.$store.state.tableData = [];
    this.$store.state.isSearch = false;
    this.$store.state.back = false;
    this.$store.state.bodySearch = new FormData();
    this.getAllRequests(1);
    this.$store.state.startElement = [];
  },
};
</script>

<style>
.noData {
  display: flex;
  justify-content: center;
  font-size: 22px;
  color: #3f0e40;
  margin-top: 70px;
  font-weight: bold;
}
</style>
