<template>
  <div style="box-shadow: 0 0 0 0 !important" class="adminGraph">
    <v-row justify="center" class="mt-5 graphContent" style="margin: 0px">
      <DailyReportFilter @handleFilter="handleFilter" />
    </v-row>
    <h1 v-html="قريباً"></h1>
    <div class="loader">
      <h1 v-html="قريباً"></h1>
      <!--      <img src="../../../assets/Comp-2.gif" />-->
    </div>
  </div>
</template>
<style>
.bg-primary {
  background-color: rgb(63, 14, 64) !important;
  color: #fff !important;
  border: 1px solid #ddd !important;
}
.bg-primary * {
  color: #fff !important;
}
</style>
<script>
import Axios from "axios";
import DailyReportFilter from "./DailyReportSalesFilter";

export default {
  components: {
    DailyReportFilter,
  },

  data() {
    return {
      chartData: [],
      tableData: [],
      options: [],
      showArray: [],
      filterObj: {},
      body: {},
      page: 1,
      getDataDone: false,
      loading: false,
      tableHeaders: [
        { text: "الاستشاري", value: "الاستشاري", class: "bg-primary", width: "170px" },
        { text: "المستقبلة", value: "المستقبلة", class: "bg-primary" },
        { text: "المتابعه", value: "المتابعه", class: "bg-primary" },
        { text: "%", value: "%المتابعه", class: "bg-primary" },
        { text: "مرفوعه مبيعات", value: "مرفوعه مبيعات", class: "bg-primary" },
        { text: "مرفوضه مبيعات", value: "مرفوضه مبيعات", class: "bg-primary" },
        { text: "%", value: "%مرفوضه مبيعات", class: "bg-primary" },
        { text: "مرفوعه تمويل", value: "مرفوعه تمويل", class: "bg-primary" },
        { text: "مرفوضة تمويل", value: "مرفوضة تمويل", class: "bg-primary" },
        { text: "%", value: "%مرفوضة تمويل", class: "bg-primary" },
        { text: "مرفوعه رهن", value: "مرفوعه رهن", class: "bg-primary" },
        { text: "مرفوضة رهن", value: "مرفوضة رهن", class: "bg-primary" },
        { text: "%", value: "%مرفوضة رهن", class: "bg-primary" },
        { text: "مفرغة", value: "مفرغة", class: "bg-primary" },
        { text: "%", value: "%مفرغة", class: "bg-primary" },

        // { text: "التحكم", value: "التحكم" },
      ],
    };
  },

  watch: {
    page(val) {
      this.getData(val, "loading");
    },
  },

  methods: {
    removeRow(headerValue) {
      let findIndex = this.tableHeaders.findIndex((item) => item.text === headerValue);

      if (findIndex !== -1) {
        this.tableHeaders.splice(findIndex, 1);
        this.showArray.splice(findIndex, 0, {
          id: findIndex,
          value: headerValue,
        });
      } else {
        let foundInsertedItem = this.options.findIndex((item) => item === headerValue);

        this.showArray.sort((a, b) => a.id - b.id);
        this.showArray.map((res, index) => {
          if (res.value === headerValue) {
            this.tableHeaders.splice(foundInsertedItem - (index - 1), 0, {
              text: headerValue,
              value: headerValue,
            });
            this.showArray.splice(index, 1);
          }
        });
      }
    },

    handleFilter(val) {
      this.body = new FormData();

      if (val.startDate) {
        this.body.append("start_date", val.startDate);
      }

      if (val.endDate) {
        this.body.append("end_date", val.endDate);
      }

      if (val.status === "استشاري نشط") {
        this.body.append("status", 1);
      }

      if (val.status === "استشاري مؤرشف") {
        this.body.append("status", 0);
      }

      if (val.range) {
        this.body.append("range", val.range);
      }

      if (val.managerId.length) {
        const managerProperties = {};

        val.managerId.forEach((item, index) => {
          this.body.append([`manager_id[${index}]`], item.id);
        });
      }

      if (val.agentId.length) {
        const managerProperties = {};

        val.agentId.forEach((item, index) => {
          this.body.append([`adviser_id[${index}]`], item.id);
        });
      }

      if (val.dayNumber) {
        this.body.append("range", val.dayNumber);
      }

      this.getData(1, "getDataDone");
    },

    getData(page, loader) {
      if (loader === "getDataDone") {
        this.getDataDone = true;
      } else if (loader === "loading") {
        this.loading = true;
      }
      this.options = [];
      this.tableData = [];
      let url = `${this.$store.state.url}/employee/admin/getProductivityUsers?page=${page}`;

      Axios.post(url, this.body, { headers: this.request_headers() }).then((res) => {
        this.setTableData(res.data.payload.data);
        this.setOptions();
        this.chartData = res.data.payload;
        this.$store.state.pagination = {
          limit: 3,
          count: res.data.payload.total_agents,
        };
        this.loading = false;
        this.getDataDone = false;
      });
    },

    setOptions() {
      this.tableHeaders.map((item, index) => {
        if (item.text !== "الاستشاري" && item.text !== "التحكم") {
          this.options.push(item.text);
        }
      });
    },

    setTableData(data) {
      data.map((res) => {
        this.tableData.push({
          "الاستشاري": res.name,
          "المستقبلة": res.total_received_request?.count,
          "المتابعه": res.followed_basket_possible_to_sell?.count,
          "%المتابعه": res.followed_basket_possible_to_sell?.percentage,
          "مرفوعه مبيعات": res.sent_basket?.count,
          "مرفوضه مبيعات": res.sent_basket_reject?.count,
          "%مرفوضه مبيعات": res.sent_basket?.percentage,
          "مرفوعه تمويل": res.sent_basket_funding?.count,
          "مرفوضة تمويل": res.sent_basket_funding_reject?.count,
          "%مرفوضة تمويل": res.sent_basket_funding?.percentage,
          "مرفوعه رهن": res.sent_basket_mortgage?.count,
          "مرفوضة رهن": res.sent_basket_mortgage_reject?.count,
          "%مرفوضة رهن": res.sent_basket_mortgage?.percentage,
          "مفرغة": res.completed_request?.count,
          "%مفرغة": res.completed_request?.percentage,
        });
      });
    },
  },

  created() {
    this.getData(1, "getDataDone");
    this.$store.state.pagination = {};
  },
};
</script>
