<template>
  <v-container>
    <v-row class="mt-7 pa-7 white rounded-lg">
      <v-col class="py-0" cols="12">
        <p class="font-weight-bold">اعدادات الزاميات تصنيفات طلبات الرهن</p>
      </v-col>

      <v-col cols="12" sm="12" md="12">
        <v-select
          label="فلترة الاعدادات"
          class="my-0"
          :items="mortgageSettingItems"
          v-model="mortgage_settings_ids"
          item-text="value"
          item-value="id"
          outlined
          multiple
          @change="startFilter"
        ></v-select>
      </v-col>

      
      <v-col cols="12" sm="12" v-for="(item, index) in responseData" :key="index">
        <h4>{{ item.value }}</h4>
        <v-row class="white rounded-lg">
          <!-- old version -->

          <!-- <v-col v-for="(classification, cIndex) in classifications" :key="cIndex" cols="3">
            <v-checkbox
              style="padding: 0; margin: 0; height: 16px"
              v-model="selected[index]"
              :label="classification.value"
              :value="classification.id"
            ></v-checkbox>
          </v-col> -->

          <!-- new Version -->
          <v-col cols="12">

            <!-- <v-select
              label="التصنيفات"
              class="my-0"
              :items="classifications"
              v-model="selected[index]"
              item-text="value"
              item-value="id"
              outlined
              multiple
            ></v-select> -->

         
            <v-col cols="12">
              <div class="selectInput">
                <div class="my-5">التصنيفات</div>
                <v-combobox
                  label="التصنيفات"
                  class="my-0"
                  :items="classifications"
                  v-model="completeSelected[index]"
                  item-text="value"
                  item-value="id"
                  multiple
                  solo
                >
                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <v-chip
                      v-if="item === Object(item)"
                      v-bind="attrs"
                      :color="`${item.color} lighten-3`"
                      :input-value="selected"
                      label
                      small
                    >
                      <span class="pr-2">
                        {{ item.value }}
                      </span>
                      <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                    </v-chip>
                  </template>
                </v-combobox>
              </div>
            </v-col>

          </v-col>
          <!-- <v-col v-for="(classification, cIndex) in completeSelected[index]" :key="cIndex" cols="3">
            <v-checkbox
              style="padding: 0; margin: 0; height: 16px"
              v-model="selected[index][cIndex]"
              :label="classification.value"
              :value="classification.id"
              disabled
            ></v-checkbox>
          </v-col> -->

        </v-row>

        <v-col
          cols="12"
          style="
            margin-top: 30px;
            margin-bottom: 15px;
            padding-top: 0;
            padding-bottom: 0;
            height: 0;
          "
        >
          <hr style="margin: 0; padding: 0" />
        </v-col>

        <v-col class="py-5 text-left" cols="12">
          <v-btn class="primary px-6" @click="updateClassificationSetting(item.id,index)">تحديث </v-btn>
        </v-col>

      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import Axios from "axios";
import { axiosInstance } from "@/axios/config";


export default {
  name: "RequestSetting",
  data() {
    return {
      responseData: [], 
      selected: [], 
      classifications:[],
      mortgageSettingItems : [],
      mortgage_settings_ids : [],
      completeSelected : [],

    };
  },
  mounted() {
    this.getRequestSettings();
    this.getClassifications();
    this.getMainMortgageSettings();
  },
  methods: {
    async getRequestSettings() {
      await Axios.post("https://api.alwsatarealestate.com/api/employee/admin/all-mortgage-settings-data",{} ,{
        headers: this.request_headers(),
      }).then((response) => {
        this.responseData = response.data.payload.data; 
        this.selected = this.responseData.map(item => item.classifications.map(c => c.id)); 
        this.completeSelected = this.responseData.map(item => item.classifications);
      });
    },

    getSelectedSettings(val) {
      this.selected = val;
    },

    getMainMortgageSettings() {
      axiosInstance
        .post(`employee/admin/all-mortgage-settings-data`,{})
        .then((res) => {
          this.mortgageSettingItems = res.data.payload.data;
          this.mortgageSettingItems.map((item) => {
            item["color"] = "red";
            item["text"] = item["value"];
            return item;
          });
        })
        .catch((error) => {
          console.log(error.message);
        });
    },

    startFilter(){
      let body = new FormData();
      if (this.mortgage_settings_ids.length) {
        this.mortgage_settings_ids.forEach((item, index) => {
          body.append(`mortgage_settings_ids[${index}]`, item);
        });
      }

      Axios.post("https://api.alwsatarealestate.com/api/employee/admin/all-mortgage-settings-data",body,{
        headers: this.request_headers(),
      }).then((response) => {
        this.responseData = response.data.payload.data; 
        this.selected = this.responseData.map(item => item.classifications.map(c => c.id)); 
        this.completeSelected = this.responseData.map(item => item.classifications);
      });
    },

    
    async getClassifications() {
      await Axios.get("https://api.alwsatarealestate.com/api/employee/admin/all-agent_classification", {
        headers: this.request_headers(),
      }).then((response) => {
        this.classifications = response.data.payload.data; 
        this.classifications.map((item) => {
            item["color"] = "red";
            item["text"] = item["value"];
            return item;
          });
      });
    },

    async updateClassificationSetting(id,selectedIndex) {
      let body = new FormData();
      // if (this.selected.length) {
      //   this.selected[selectedIndex].forEach((item, index) => {
      //     if(item != null){
      //       body.append(`classification_ids[${index}]`, item);
      //     }
      //   });
      // }

      if (this.completeSelected.length) {
        this.completeSelected[selectedIndex].forEach((item, index) => {
          if(item != null){
            body.append(`classification_ids[${index}]`, item['id']);
          }
        });
      }

      await Axios.post(
        `https://api.alwsatarealestate.com/api/employee/admin/update-mortgage-settings-data/${id}`,
        body,
        {
          headers: this.request_headers(),
        }
      ).then((response) => {
        this.getRequestSettings();
        this.showPopup("success", response.data.message);
      });
    },
  },
};
</script>

<style lang="scss">
.v-input__control {
  min-height: 40px !important;
}
</style>
