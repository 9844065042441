<template>
  <page-component :loading="fetched || found">
    <template #content>
      <div class="app">
        <v-btn
          fab
          :color="$vuetify.theme.themes.light.dialogActionBTN"
          @click="addNewRequestDialog = true"
          class="my-10"
          style="position: fixed; left: 20px; bottom: 0px; z-index: 1"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25.425"
            height="21.166"
            viewBox="0 0 25.425 21.166"
          >
            <g
              id="Icon_feather-user-plus"
              data-name="Icon feather-user-plus"
              transform="translate(1 1)"
            >
              <path
                id="Path_2554"
                data-name="Path 2554"
                d="M17.471,28.889v-2.13A4.259,4.259,0,0,0,13.212,22.5H5.759A4.259,4.259,0,0,0,1.5,26.759v2.13"
                transform="translate(-1.5 -9.723)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
              <path
                id="Path_2555"
                data-name="Path 2555"
                d="M15.268,8.759A4.259,4.259,0,1,1,11.009,4.5,4.259,4.259,0,0,1,15.268,8.759Z"
                transform="translate(-3.023 -4.5)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
              <path
                id="Path_2556"
                data-name="Path 2556"
                d="M30,12v6.389"
                transform="translate(-9.77 -6.676)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
              <path
                id="Path_2557"
                data-name="Path 2557"
                d="M31.889,16.5H25.5"
                transform="translate(-8.464 -7.982)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </g>
          </svg>
        </v-btn>
        <OrderRequestDialog />
        <add-new-request v-model="addNewRequestDialog" width="350px" />
        <!-- <div
            class="sidebar__chat font-weight-bold"
            style="font-size: 36px"
            v-if="no_chats"
          >
            لا توجد محادثات
          </div> -->
        <div class="app__body">
          <!-- Chat sidebar start -->
          <div class="sidebar">
            <div class="sidebar__search" style="height: 59px">
              <div
                class="sidebar__searchContainer"
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  border-bottom: 1px solid #dfdfdfcf;
                  padding: 0px 20px;
                  border-radius: unset;
                  height: 100%;
                "
              >
                <v-autocomplete
                  :items="allAgents"
                  label="المحادثات"
                  style="margin-top: 25px"
                  v-model="selectedStatusFilter"
                  solo
                  dense
                >
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-title
                        :id="attrs['aria-labelledby']"
                        v-text="item"
                      ></v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <span>
                  <img src="@/assets/feather-search.svg" />
                </span>
              </div>
            </div>
            <div class="sidebar__chat" v-if="rooms.length">
              <div v-if="!searchLoad">
                <div v-for="room in rooms" :key="room.id">
                  <!-- Sidebar chat list start -->
                  <div
                    class="sidebarChat"
                    :style="`align-items: center; ${
                      room.data.ureadMsgsCount
                        ? 'background: #d9cfd9;'
                        : 'background: unset;'
                    }`"
                    @click="get_chat(room.data, room.id)"
                  >
                    <v-avatar
                      class="mx-2"
                      style="width: 53px; height: 53px; filter: saturate(0.5)"
                    >
                      <v-img src="@/assets/purple-avatar.png" />
                    </v-avatar>
                    <div
                      class="sidebarChat__info"
                      style="width: 100%; margin-right: 10px"
                    >
                      <div
                        style="
                          width: 100%;
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                        "
                      >
                        <h3 style="font-size: 16px">
                          {{ room.data.UserName }}
                        </h3>
                        <p
                          style="
                            font-size: 11px;
                            font-weight: bold;
                            color: grey;
                          "
                        >
                          {{
                            new Date(
                              parseInt(room.data.LastMessageTime)
                            ).toLocaleDateString() ==
                            new Date().toLocaleDateString()
                              ? new Date(
                                  parseInt(room.data.LastMessageTime)
                                ).toLocaleTimeString("en-US")
                              : new Date(
                                  parseInt(room.data.LastMessageTime)
                                ).toLocaleDateString()
                          }}
                        </p>
                      </div>
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          margin-top: -14px;
                          margin-bottom: 7px;
                        "
                      >
                        <p style="font-size: 15px">
                          {{
                            room.data.lastMessage
                              ? room.data.lastMsgSenderId == agent["agent_id"]
                                ? `انت: ${room.data.lastMessage}`
                                : `العميل: ${room.data.lastMessage}`
                              : "انقر هنا لبدء المحادثة"
                          }}
                        </p>
                        <p
                          class="notifs"
                          style="
                            font-size: 14px;
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            background-color: indianred;
                            color: white;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-weight: bold;
                            padding-bottom: 5px;
                          "
                          v-if="
                            room.data.ureadMsgsCount &&
                            room.data.ureadMsgsCount > 0
                          "
                        >
                          {{
                            room.data.ureadMsgsCount > 9
                              ? "9+"
                              : room.data.ureadMsgsCount
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- Sidebar chat list end -->
                </div>
              </div>

              <div v-else>
                <LoadingMore />
              </div>
            </div>
            <div
              v-else
              class="sidebar__chat"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                font-weight: bold;
              "
            >
              لايوجد محادثات
            </div>
          </div>
          <!-- Chat sidebar end -->
          <!-- Chat view start -->
          <div class="chat" v-if="active_room">
            <div class="chat__header">
              <div class="chat__headerInfo">
                <div style="display: flex; justify-content: space-between">
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      width: fit-content;
                      direction: ltr;
                    "
                  >
                    <div style="text-align: end; margin-right: 5px">
                      <h3 style="font-size: 16px" class="mb-0">
                        {{ active_room.UserName }}
                      </h3>
                      <p
                        class="chat__timestamp mb-0"
                        v-if="active_room.UserLastActive"
                      >
                        اخر ظهور في
                        {{ active_room.UserLastActive.split(".")[0] }}
                      </p>
                    </div>
                    <v-avatar
                      class="mx-2"
                      style="width: 57px; height: 57px; filter: saturate(0.5)"
                    >
                      <v-img src="@/assets/purple-avatar.png" />
                    </v-avatar>
                  </div>
                  <div style="display: flex; align-items: center">
                    <span style="font-size: 14px; color: #0f5b94"
                      >({{ returnActiveAgentChat }})</span
                    >
                    <ChatDetailsIcon :clientId="active_room.RequestId" />
                  </div>
                </div>
              </div>
            </div>
            <div class="chat__body" id="container" ref="container">
              <div v-for="chat in chats" :key="chat.created_at">
                <div
                  :class="`${isMe(chat) ? 'chat__reciever' : 'chat__message'}`"
                  style="max-width: 60%"
                >
                  <template v-if="chat.message_type == 'text'">
                    <span
                      v-for="(word, index) in chat.text.split(' ')"
                      :key="index"
                      style="font-weight: bold"
                    >
                      <button
                        class="mobile-link"
                        v-if="word.match(/\b\d{9}\b/g)"
                        @click="showSupportSingleProcessComponent(word)"
                      >
                        <v-menu v-if="word">
                          <template v-slot:activator="{ on, attrs }">
                            <button
                              color=""
                              small
                              depressed
                              dark
                              v-bind="attrs"
                              v-on="on"
                              @click="showSupportSingleProcessComponent(word)"
                            >
                              {{ word }}
                            </button>
                          </template>
                          <v-list style="overflow-y: auto; height: 130px">
                            <ChatSingleProcess
                              v-if="
                                showSupportSingleProcess &&
                                selectedMobile == word
                              "
                              :item="selectedMobile.match(/\b\d+\b/g)"
                            />
                          </v-list>
                        </v-menu>
                      </button>
                      <span v-else>
                        {{ word }}
                        <span v-if="index < chat.text.split(' ').length - 1">
                        </span>
                      </span>
                    </span>
                  </template>

                  <div
                    v-else
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      background: #fff;
                      padding: 0 5px 5px;
                      border-radius: 6px 6px 0 0;
                    "
                  >
                    <v-icon size="40" v-if="chat.fileType">mdi-image</v-icon>
                    <div class="pr-2 pt-2" v-else>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24.5"
                        height="31.719"
                        viewBox="0 0 24.5 31.719"
                      >
                        <g id="pdf" transform="translate(-15.592 -5.255)">
                          <path
                            id="Path_4092"
                            data-name="Path 4092"
                            d="M51.276,51.414a2.081,2.081,0,0,0-.438.038v2.8a1.813,1.813,0,0,0,.336.019,1.332,1.332,0,0,0,1.46-1.5A1.222,1.222,0,0,0,51.276,51.414Z"
                            transform="translate(-22.824 -29.891)"
                            fill="#ed1c24"
                          />
                          <path
                            id="Path_4093"
                            data-name="Path 4093"
                            d="M34.81,12.255a1.718,1.718,0,0,1-1.718-1.717V5.255H17.309a1.718,1.718,0,0,0-1.717,1.717V35.256a1.718,1.718,0,0,0,1.717,1.718H38.374a1.718,1.718,0,0,0,1.717-1.717v-23Zm-12.759,12.9h-.006a.574.574,0,1,1,.006,0Zm3.934-2.03a2.033,2.033,0,0,1-1.4.45,1.663,1.663,0,0,1-.33-.025v1.536H23.3v-4.22a7.923,7.923,0,0,1,1.307-.089,2.032,2.032,0,0,1,1.314.349,1.224,1.224,0,0,1,.45.984,1.343,1.343,0,0,1-.387,1.015Zm4.142,1.453a3.089,3.089,0,0,1-1.98.552,8.214,8.214,0,0,1-1.1-.064V20.876a7.79,7.79,0,0,1,1.3-.1,2.751,2.751,0,0,1,1.758.444,1.909,1.909,0,0,1,.724,1.625A2.187,2.187,0,0,1,30.127,24.581Zm4.015-2.976H32.5v.977h1.535v.787H32.5v1.72h-.965V20.812h2.608v.793Z"
                            fill="#ed1c24"
                          />
                          <path
                            id="Path_4094"
                            data-name="Path 4094"
                            d="M40.581,21.516a1.733,1.733,0,0,0-.4.032v1.263a1.483,1.483,0,0,0,.324.025c.514,0,.831-.26.831-.691,0-.393-.266-.628-.755-.628Zm10.152-9.261h5.282l-7-7v5.283a1.718,1.718,0,0,0,1.718,1.718Z"
                            transform="translate(-15.923)"
                            fill="#ed1c24"
                          />
                          <path
                            id="Path_4095"
                            data-name="Path 4095"
                            d="M66.964,12.255h5.282l-7-7v5.283a1.718,1.718,0,0,0,1.718,1.718Z"
                            transform="translate(-32.154)"
                            fill="#fff"
                            opacity="0.6"
                          />
                          <path
                            id="Path_4096"
                            data-name="Path 4096"
                            d="M32.887,52.512a.587.587,0,0,0-.012,1.174h.006a.587.587,0,0,0,.006-1.174Zm3.864-2.855a2.032,2.032,0,0,0-1.314-.349,7.923,7.923,0,0,0-1.307.089v4.22h.958V52.081a1.634,1.634,0,0,0,.33.025,2.033,2.033,0,0,0,1.4-.45,1.344,1.344,0,0,0,.387-1.015,1.223,1.223,0,0,0-.451-.984Zm-1.339,1.707a1.507,1.507,0,0,1-.324-.025V50.076a1.733,1.733,0,0,1,.4-.032c.489,0,.755.235.755.628C36.243,51.1,35.926,51.364,35.412,51.364Zm5.525-1.612a2.751,2.751,0,0,0-1.758-.444,7.787,7.787,0,0,0-1.3.1V53.6a8.215,8.215,0,0,0,1.1.064,3.091,3.091,0,0,0,1.98-.552,2.187,2.187,0,0,0,.7-1.732A1.909,1.909,0,0,0,40.937,49.752ZM39.18,52.906a1.814,1.814,0,0,1-.336-.019v-2.8a2.057,2.057,0,0,1,.438-.038,1.222,1.222,0,0,1,1.358,1.358A1.332,1.332,0,0,1,39.18,52.906Zm3.184.71h.965V51.9h1.536V51.11H43.329v-.977h1.643v-.793H42.364Z"
                            transform="translate(-10.83 -28.527)"
                            fill="#fff"
                          />
                        </g>
                      </svg>
                    </div>
                    <div
                      style="display: flex; flex-direction: column; gap: 4px"
                    >
                      <!--Download Menu-->
                      <v-menu transition="slide-y-transition" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            style="margin-right: -5px !important"
                            v-bind="attrs"
                            v-on="on"
                            class="mx-2"
                            >mdi-dots-vertical</v-icon
                          >
                        </template>
                        <v-list>
                          <v-list-item @click="openFile(chat.file)">
                            <v-list-item-title>
                              <v-icon class="ml-1" size="18">mdi-eye</v-icon>
                              فتح المرفق
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="attachFile(chat.fileId)">
                            <v-list-item-title>
                              <v-icon class="ml-1" size="18"
                                >mdi-file-plus</v-icon
                              >
                              اضافة الي مرفقات الطلب
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="downloadFile(chat.file)">
                            <v-list-item-title>
                              <v-icon title="تحميل">mdi-download</v-icon>
                              تحميل المرفق
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <!--Download Menu-->
                    </div>
                  </div>
                  <span
                    v-if="chat.file"
                    style="
                      font-size: 10px;
                      margin-bottom: 12px;
                      display: block;
                      font-weight: bold;
                      background: #fff;
                      padding: 0 5px 5px;
                      border-radius: 0px 0px 6px 6px;
                    "
                    dir="ltr"
                    >{{ chat.file }}</span
                  >
                  <div class="chat__timestamp">
                    <!-- {{ format_date(chat.created_at) }} -->
                    <v-icon
                      v-if="isMe(chat)"
                      :style="chat.is_read ? 'color: #3268b7' : 'color: gray'"
                      small
                      >{{
                        chat.is_read ? "mdi-check-all" : "mdi-check"
                      }}</v-icon
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="dis-files text-center pt-2" v-if="fileData">
              <v-btn
                depressed
                color="transparent"
                style="min-width: unset !important; pointer-events: none"
                class="pa-0"
                :loading="sending"
              >
                <v-icon
                  v-if="fileData.type.split('/')[0] == 'image'"
                  size="40"
                  color="#517551"
                  bordered
                  >mdi-image
                </v-icon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24.5"
                  height="31.719"
                  viewBox="0 0 24.5 31.719"
                  v-else
                >
                  <g id="pdf" transform="translate(-15.592 -5.255)">
                    <path
                      id="Path_4092"
                      data-name="Path 4092"
                      d="M51.276,51.414a2.081,2.081,0,0,0-.438.038v2.8a1.813,1.813,0,0,0,.336.019,1.332,1.332,0,0,0,1.46-1.5A1.222,1.222,0,0,0,51.276,51.414Z"
                      transform="translate(-22.824 -29.891)"
                      fill="#ed1c24"
                    />
                    <path
                      id="Path_4093"
                      data-name="Path 4093"
                      d="M34.81,12.255a1.718,1.718,0,0,1-1.718-1.717V5.255H17.309a1.718,1.718,0,0,0-1.717,1.717V35.256a1.718,1.718,0,0,0,1.717,1.718H38.374a1.718,1.718,0,0,0,1.717-1.717v-23Zm-12.759,12.9h-.006a.574.574,0,1,1,.006,0Zm3.934-2.03a2.033,2.033,0,0,1-1.4.45,1.663,1.663,0,0,1-.33-.025v1.536H23.3v-4.22a7.923,7.923,0,0,1,1.307-.089,2.032,2.032,0,0,1,1.314.349,1.224,1.224,0,0,1,.45.984,1.343,1.343,0,0,1-.387,1.015Zm4.142,1.453a3.089,3.089,0,0,1-1.98.552,8.214,8.214,0,0,1-1.1-.064V20.876a7.79,7.79,0,0,1,1.3-.1,2.751,2.751,0,0,1,1.758.444,1.909,1.909,0,0,1,.724,1.625A2.187,2.187,0,0,1,30.127,24.581Zm4.015-2.976H32.5v.977h1.535v.787H32.5v1.72h-.965V20.812h2.608v.793Z"
                      fill="#ed1c24"
                    />
                    <path
                      id="Path_4094"
                      data-name="Path 4094"
                      d="M40.581,21.516a1.733,1.733,0,0,0-.4.032v1.263a1.483,1.483,0,0,0,.324.025c.514,0,.831-.26.831-.691,0-.393-.266-.628-.755-.628Zm10.152-9.261h5.282l-7-7v5.283a1.718,1.718,0,0,0,1.718,1.718Z"
                      transform="translate(-15.923)"
                      fill="#ed1c24"
                    />
                    <path
                      id="Path_4095"
                      data-name="Path 4095"
                      d="M66.964,12.255h5.282l-7-7v5.283a1.718,1.718,0,0,0,1.718,1.718Z"
                      transform="translate(-32.154)"
                      fill="#fff"
                      opacity="0.6"
                    />
                    <path
                      id="Path_4096"
                      data-name="Path 4096"
                      d="M32.887,52.512a.587.587,0,0,0-.012,1.174h.006a.587.587,0,0,0,.006-1.174Zm3.864-2.855a2.032,2.032,0,0,0-1.314-.349,7.923,7.923,0,0,0-1.307.089v4.22h.958V52.081a1.634,1.634,0,0,0,.33.025,2.033,2.033,0,0,0,1.4-.45,1.344,1.344,0,0,0,.387-1.015,1.223,1.223,0,0,0-.451-.984Zm-1.339,1.707a1.507,1.507,0,0,1-.324-.025V50.076a1.733,1.733,0,0,1,.4-.032c.489,0,.755.235.755.628C36.243,51.1,35.926,51.364,35.412,51.364Zm5.525-1.612a2.751,2.751,0,0,0-1.758-.444,7.787,7.787,0,0,0-1.3.1V53.6a8.215,8.215,0,0,0,1.1.064,3.091,3.091,0,0,0,1.98-.552,2.187,2.187,0,0,0,.7-1.732A1.909,1.909,0,0,0,40.937,49.752ZM39.18,52.906a1.814,1.814,0,0,1-.336-.019v-2.8a2.057,2.057,0,0,1,.438-.038,1.222,1.222,0,0,1,1.358,1.358A1.332,1.332,0,0,1,39.18,52.906Zm3.184.71h.965V51.9h1.536V51.11H43.329v-.977h1.643v-.793H42.364Z"
                      transform="translate(-10.83 -28.527)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </v-btn>
              <v-icon
                size="20"
                @click="deleteFile"
                style="cursor: pointer; margin-top: -5px"
                >mdi-close</v-icon
              >
            </div>
            <div
              class="chat__footer"
              style="position: relative"
              v-if="active_room"
            >
              <div
                :style="`position: absolute;
                    left: 25px;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    ${
                      isDisabled
                        ? 'opacity: 0.7'
                        : 'opacity: 1; cursor: pointer;'
                    }`"
                @click="send_message"
                class="mx-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                >
                  <g
                    id="Group_11855"
                    data-name="Group 11855"
                    transform="translate(-65 -706)"
                  >
                    <g id="Group_11863" data-name="Group 11863">
                      <circle
                        id="Ellipse_126"
                        data-name="Ellipse 126"
                        cx="20"
                        cy="20"
                        r="20"
                        transform="translate(65 706)"
                        fill="rgba(63,14,64,0.1)"
                      />
                      <path
                        id="send"
                        d="M17.577.217a.746.746,0,0,0-.8-.162L.465,6.726a.746.746,0,0,0,.178,1.42l7.9,1.1,1.1,7.9a.746.746,0,0,0,1.42.178L17.739,1.022a.741.741,0,0,0-.162-.8Z"
                        transform="translate(74.982 717.999)"
                        fill="#3f0e40"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <v-text-field
                v-model="message"
                outlined
                dense
                class="ma-0 pa-0"
                hide-details
                rounded
                placeholder="أكتب رسالتك ..."
                @keyup.enter="send_message"
                :disabled="disableText"
                style="margin: 0 16px !important; border-radius: 4px !important"
              />
              <!-- هنا الأكشن بتاع ارفاق ملف -->
              <div
                style="
                  position: absolute;
                  left: 75px;
                  top: 50%;
                  transform: translateY(-50%);
                "
                class="file-inp-div"
              >
                <!--File Type Menu-->
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="mx-2"
                      >mdi-paperclip</v-icon
                    >
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title style="position: relative">
                        تحميل صورة
                        <v-icon class="mr-2">mdi-image</v-icon>
                        <input
                          type="file"
                          name="sendfile"
                          id="sendfile"
                          accept="image/*"
                          @change="selectFile"
                          style="
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            left: 0;
                            opacity: 0;
                          "
                        />
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-title style="position: relative">
                        تحميل ملف
                        <v-icon class="mr-2">mdi-file</v-icon>
                        <input
                          type="file"
                          name="sendfile"
                          id="sendfile"
                          accept=".doc,.pdf,.csv,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          @change="selectFile"
                          style="
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            left: 0;
                            opacity: 0;
                          "
                        />
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--File Type Menu-->
              </div>
            </div>
          </div>
          <!-- Chat view end -->
        </div>
      </div>
    </template>
  </page-component>
</template>

  <script>
//   import firebase from "../../Firebase";
import firebase from "../../../Firebase";
import Axios from "axios";
import { mapActions } from "vuex";
import LoadingMore from "@/components/Charts/LoadingMore.vue";

import ChatDetailsIcon from "@/components/Charts/ChatDetailsIcon.vue";
import ChatSingleProcess from "@/components/Charts/ChatSingleProcess.vue";
import OrderRequestDialog from "@/components/AdminComponent/OrderRequestDialog.vue";

var moment = require("moment");
export default {
  name: "ChatComponent",
  components: {
    LoadingMore,
    ChatDetailsIcon,
    ChatSingleProcess,
    OrderRequestDialog,
  },
  // components : {ChatSidebar, ChatView},
  data() {
    return {
      selectedStatusFilter: "المحادثات",
      activeAgentChat: "",
      getAgentMassage: [],
      allAgents: [],
      active: null,
      no_chats: false,
      isDisabled: true,
      addNewRequestDialog: false,
      chat_id: 1,
      fetched: false,
      searchLoad: false,
      found: true,
      chats: [],
      email: "",
      rooms: [],
      active_room: null,
      active_room_id: "",
      firstRoom: {},
      message: "",
      fileData: "",
      disableText: false,
      viewFile: "",
      showSupportSingleProcess: false,
      selectedMobile: null,
      sending: false,
      agent: JSON.parse(
        document.cookie
          .split(";")
          .map((coki) => coki.split("="))
          .reduce(
            (acc, [key, val]) => ({
              ...acc,
              [key.trim()]: decodeURIComponent(val),
            }),
            {}
          )["wsataJwt"]
      ),
    };
  },
  watch: {
    message(val) {
      if (val) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
    selectedStatusFilter(val) {
      this.getAgentMassage.map((res) => {
        if (res.name === this.selectedStatusFilter) {
          this.filter_rooms(res.id);
        }
      });

      if (this.selectedStatusFilter === null) {
        this.filter_rooms(null);
      }
    },
    fileData(val) {
      if (val) {
        this.isDisabled = false;
        this.disableText = true;
      } else {
        this.disableText = false;
        if (!this.message) {
          this.isDisabled = true;
        }
      }
    },
    "rooms.length"(val) {
      setTimeout(() => {
        document
          .querySelectorAll(".sidebar__chat .sidebarChat")
          .forEach((el) => {
            el.addEventListener("click", (e) => {
              document
                .querySelectorAll(".sidebar__chat .sidebarChat")
                .forEach((el) => {
                  el.classList.remove("active");
                });
              e.currentTarget.classList.add("active");
            });
          });
      }, 2000);
    },
    showChatFile(val) {
      if (!val) {
        URL.revokeObjectURL(this.viewFile);
        this.fileData = "";
      }
    },
  },
  computed: {
    returnActiveAgentChat() {
      return this.activeAgentChat;
    },
  },
  methods: {
    showSupportSingleProcessComponent(number) {
      this.showSupportSingleProcess = true;
      this.selectedMobile = number;
    },

    ...mapActions(["setNavChatAction", "emptyNavAction"]),
    getAllAgent(val) {
      this.allAgents = [];
      Axios.post(
        `https://api.alwsatarealestate.com/api/employee/admin/filterUsers`,
        {},
        { headers: this.request_headers() }
      ).then((res) => {
        this.getAgentMassage.push(...res.data.payload);
        res.data.payload.map((res) => {
          this.allAgents.push(res.name);
          if (res.id === val) {
            this.activeAgentChat = res.name;
          }
        });
      });

      Axios.post(
        `https://api.alwsatarealestate.com/api/employee/admin/filterUsers`,
        { status: 0 },
        { headers: this.request_headers() }
      ).then((res) => {
        this.getAgentMassage.push(...res.data.payload);
        res.data.payload.map((res) => {
          this.allAgents.push(res.name);
          if (res.id === val) {
            this.activeAgentChat = res.name;
          }
        });
      });
    },

    filter_rooms(val) {
      // this.fetched = true;
      this.searchLoad = true;
      let id = val;
      let chat_rooms = {};

      if (val !== null) {
        chat_rooms = firebase
          .firestore()
          .collection("rooms")
          .where("AgentId", "==", val)
          .orderBy("LastMessageTime", "desc");
      } else {
        chat_rooms = firebase
          .firestore()
          .collection("rooms")
          .orderBy("LastMessageTime", "desc");
      }

      chat_rooms.onSnapshot((res) => {
        this.rooms = [];
        res.forEach(async (docOne) => {
          if (docOne.data().RequestId) {
            let obj = { id: docOne.id, data: docOne.data() };
            obj.data.ureadMsgsCount = 0;
            let chat_ref = firebase
              .firestore()
              .collection("messages")
              .where("roomId", "==", `${docOne.id}`);
            chat_ref.onSnapshot((res) => {
              obj.data.ureadMsgsCount = 0;
              res.forEach((docTwo) => {
                if (docTwo.data().senderId != id) {
                  if (docTwo.data().is_read == 0) {
                    obj.data.ureadMsgsCount += 1;
                  }
                }
                obj.data.lastMsgSenderId = docTwo.data().senderId;
                obj.data.lastMessage = docTwo.data().text
                  ? docTwo.data().text.length > 30
                    ? docTwo.data().text.split("").slice(0, 29).join("") +
                      " " +
                      "..."
                    : docTwo.data().text
                  : "تم ارسال مرفق ..";
              });
            });
            this.rooms.push(obj);
          }
        });
        this.getAllAgent();
        if (
          !this.rooms.length &&
          !this.$route.query &&
          !this.$route.query.customer_id
        ) {
          this.no_chats = true;
        }
      });

      setTimeout(() => {
        this.searchLoad = false;
      }, 1000);
      setTimeout(() => {
        if (this.$route.query && this.$route.query.customer_id) {
          var filtered_room = this.rooms.filter((room) => {
            return (
              room["data"]["UserId"] == this.$route.query.customer_id.toString()
            );
          });

          if (filtered_room.length) {
            this.get_chat(filtered_room[0]["data"], filtered_room[0]["id"]);
          } else {
            const doc_id = Date.now().toString();
            let doc = {
              AgentId: this.agent["agent_id"].toString(),
              AgentIsLogout: false,
              AgentLastActive: new Date().toISOString(),
              AgentStatus: "متاح الآن",
              LastMessageTime: Date.now().toString(),
              RequestId: this.$route.query.request_id.toString(),
              UserId: this.$route.query.customer_id.toString(),
              UserIsLogout: false,
              UserLastActive: "",
              UserName: this.$route.query.customer_name,
              UserStatus: "غير متاح",
            };

            firebase.firestore().collection("rooms").doc(doc_id).set(doc);
            this.active_room = doc;
            this.active_room_id = doc_id;
          }
          this.$router.push("/agent/chat");
        }
        this.found = false;
      }, 2000);
    },

    async get_rooms() {
      this.fetched = true;
      const id = JSON.parse(
        document.cookie
          .split(";")
          .map((coki) => coki.split("="))
          .reduce(
            (acc, [key, val]) => ({
              ...acc,
              [key.trim()]: decodeURIComponent(val),
            }),
            {}
          )["wsataJwt"]
      )["agent_id"];

      let chat_rooms = firebase
        .firestore()
        .collection("rooms")
        // .where("AgentId", "==", 34)
        .orderBy("LastMessageTime", "desc");

      chat_rooms.onSnapshot((res) => {
        this.rooms = [];
        res.forEach(async (docOne) => {
          if (docOne.data().RequestId) {
            let obj = { id: docOne.id, data: docOne.data() };
            obj.data.ureadMsgsCount = 0;
            let chat_ref = firebase
              .firestore()
              .collection("messages")
              .where("roomId", "==", `${docOne.id}`);
            chat_ref.onSnapshot((res) => {
              obj.data.ureadMsgsCount = 0;
              res.forEach((docTwo) => {
                if (docTwo.data().senderId != id) {
                  if (docTwo.data().is_read == 0) {
                    obj.data.ureadMsgsCount += 1;
                  }
                }
                obj.data.lastMsgSenderId = docTwo.data().senderId;
                obj.data.lastMessage = docTwo.data().text
                  ? docTwo.data().text.length > 30
                    ? docTwo.data().text.split("").slice(0, 29).join("") +
                      " " +
                      "..."
                    : docTwo.data().text
                  : "تم ارسال مرفق ..";
              });
            });
            this.rooms.push(obj);
          }
        });
        this.getAllAgent();
        if (
          !this.rooms.length &&
          !this.$route.query &&
          !this.$route.query.customer_id
        ) {
          this.no_chats = true;
        }
      });

      setTimeout(() => {
        this.fetched = false;
      }, 1000);
      setTimeout(() => {
        if (this.$route.query && this.$route.query.customer_id) {
          var filtered_room = this.rooms.filter((room) => {
            return (
              room["data"]["UserId"] == this.$route.query.customer_id.toString()
            );
          });

          if (filtered_room.length) {
            this.get_chat(filtered_room[0]["data"], filtered_room[0]["id"]);
          } else {
            const doc_id = Date.now().toString();
            let doc = {
              AgentId: this.agent["agent_id"].toString(),
              AgentIsLogout: false,
              AgentLastActive: new Date().toISOString(),
              AgentStatus: "متاح الآن",
              LastMessageTime: Date.now().toString(),
              RequestId: this.$route.query.request_id.toString(),
              UserId: this.$route.query.customer_id.toString(),
              UserIsLogout: false,
              UserLastActive: "",
              UserName: this.$route.query.customer_name,
              UserStatus: "غير متاح",
            };
            firebase.firestore().collection("rooms").doc(doc_id).set(doc);
            this.active_room = doc;
            this.active_room_id = doc_id;
          }
          this.$router.push("/agent/chat");
        }
        this.found = false;
      }, 2000);
    },
    async send_message() {
      if (!this.isDisabled) {
        this.isDisabled = true;
        const id = JSON.parse(
          document.cookie
            .split(";")
            .map((coki) => coki.split("="))
            .reduce(
              (acc, [key, val]) => ({
                ...acc,
                [key.trim()]: decodeURIComponent(val),
              }),
              {}
            )["wsataJwt"]
        )["agent_id"];
        const name = JSON.parse(
          document.cookie
            .split(";")
            .map((coki) => coki.split("="))
            .reduce(
              (acc, [key, val]) => ({
                ...acc,
                [key.trim()]: decodeURIComponent(val),
              }),
              {}
            )["wsataJwt"]
        )["agent_name"];
        const msg = this.message;
        ///////////
        let fileRes = "";
        // هنا هنشوف لو اللي هيتبعت ملف هنخلي التكست فاضي و نبعت الملف في فايل باراميتر و نغير نوع المسج ل فايل
        if (this.fileData) {
          this.sending = true;
          fileRes = await this.sendFile();
          this.deleteFile();
        }

        const currentTime = Date.now().toString();
        const message_info = {
          created_at: currentTime,
          fileId: fileRes ? fileRes.id : "",
          file: fileRes ? fileRes.file_name : "", //here
          from_type: "App\\User",
          is_read: 0,
          message_type: fileRes ? "file" : "text", //file
          receiverId: this.active_room.UserId,
          roomId: this.active_room_id,
          senderId: id,
          targeted_device_token: "",
          text: msg, //""
          to_type: "App\\Customer",
          updated_at: currentTime,
          userName: name,
          fileUrl: fileRes ? fileRes.file_url : "",
        };

        // Update Room Last Msg Update
        await firebase
          .firestore()
          .collection("rooms")
          .doc(this.active_room_id)
          .update({ LastMessageTime: currentTime });
        // Update Room Last Msg Update

        await firebase
          .firestore()
          .collection("messages")
          .doc(Date.now().toString())
          .set(message_info);

        this.message = "";
        this.fileData = "";
        this.sending = false;
        this.get_chat(this.active_room, this.active_room_id);
        Axios.get(
          `https://api.alwsatarealestate.com/api/agent/AgentReplay/${this.active_room.RequestId}`,
          { headers: this.request_headers() }
        )
          .then((res) => {
            if (this.$route.query.customer_name) {
              this.$router.push({ name: "chat_component" });
            }
            this.readChatMsgs();
          })
          .catch((err) => {
            this.showPopup("error", err.response.data.message);
          });
      }
    },
    selectFile(e) {
      e.target.files[0]
        ? (this.fileData = e.target.files[0])
        : (this.fileData = "");
    },
    async sendFile() {
      let data = "";
      const formData = new FormData();
      formData.append("chat_file", this.fileData);
      formData.append("customer_id", this.active_room.UserId);
      await Axios.post(
        `https://api.alwsatarealestate.com/api/agent/chat/file`,
        formData,
        { headers: this.request_headers() }
      ).then((res) => {
        data = res.data.payload;
      });
      return data;
    },
    async openFile(fileName) {
      await fetch(
        `https://api.alwsatarealestate.com/api/agent/download/chat/file/${fileName}`,
        { headers: this.request_headers() }
      )
        .then((res) => res.blob())
        .then((data) => {
          const link = document.createElement("a");
          let urlCreator = window.URL || window.webkitURL;
          let fileUrl = urlCreator.createObjectURL(data);
          link.href = fileUrl;
          link.setAttribute("target", "_blank");
          document.documentElement.appendChild(link);
          link.click();
          link.remove();
          URL.revokeObjectURL(fileUrl);
        });
    },
    async downloadFile(fileName) {
      await fetch(
        `https://api.alwsatarealestate.com/api/agent/download/chat/file/${fileName}`,
        { headers: this.request_headers() }
      )
        .then((res) => res.blob())
        .then((data) => {
          const tempUrl = URL.createObjectURL(data);
          const theLink = document.createElement("a");
          theLink.href = tempUrl;
          theLink.download = fileName;
          document.documentElement.appendChild(theLink);
          theLink.click();
          theLink.remove();
          URL.revokeObjectURL(tempUrl);
        })
        .catch((err) => this.showPopup("error", "فشل عملية التحميل"));
    },
    async attachFile(fileId) {
      await Axios.get(
        `https://api.alwsatarealestate.com/api/agent/Add/File/Document/${fileId}`,
        { headers: this.request_headers() }
      )
        .then((res) => this.showPopup("success", res.data.message))
        .catch((err) => this.showPopup("error", err.response.data.message));
    },
    deleteFile() {
      this.fileData = "";
      document.querySelectorAll("input[type='file']").forEach((inp) => {
        inp.value = "";
      });
    },
    get_chat(room, room_id) {
      this.active_room = room;
      this.active_room_id = room_id;

      this.getAgentMassage.map((res) => {
        if (this.active_room.AgentId == res.id) {
          this.activeAgentChat = res.name;
        }
      });

      this.chats = [];
      Axios.get(
        `https://api.alwsatarealestate.com/api/customerRequest/${room.UserId}`,
        { headers: this.request_headers() }
      )
        .then((res) => {
          this.active_room.RequestId = res.data.payload.req_id;
        })
        .catch((err) =>
          this.showPopup("error", err.response.data.payload.message)
        );
      // Make sure to change messages & rooms to messages & rooms when going live
      let chat_ref = firebase
        .firestore()
        .collection("messages")
        .where("roomId", "==", `${room_id}`);
      chat_ref.onSnapshot((res) => {
        this.emptyNavAction();
        this.chats = [];
        res.forEach((doc) => {
          if (doc.data().message_type != "transferring") {
            let msg_exist = this.chats.filter((msg) => {
              return msg["created_at"] == doc.data().created_at;
            });

            if (msg_exist.length == 0) {
              this.chats.push(doc.data());
            }
          }
        });
        this.chats.forEach((chat) => {
          if (chat.file) {
            const fileType = chat.file.split(".")[1];
            const imgTypes = [
              "jpg",
              "jpeg",
              "png",
              "apng",
              "avif",
              "jfif",
              "pjpeg",
              "pjp",
            ];
            imgTypes.forEach((type) => {
              if (fileType == type) {
                chat.fileType = "image";
              }
            });
          }
        });
        this.chats.sort((a, b) => {
          if (a.created_at < b.created_at) return -1;
          if (a.created_at > b.created_at) return 1;
          return 0;
        });
        setTimeout(() => {
          var container = document.getElementById("container");
          container.scrollTop = container.scrollHeight;
        }, 5);
      });
      setTimeout(() => {
        this.$refs["container"].scrollIntoView({ behavior: "smooth" });
      }, 10);
      this.readChatMsgs(room_id);
    },
    readChatMsgs(room_id) {
      let chat_ref = firebase
        .firestore()
        .collection("messages")
        .where("roomId", "==", `${room_id}`);
      const unSub = chat_ref.onSnapshot((res) => {
        res.forEach((doc) => {
          if (doc.data().senderId != this.agent["agent_id"]) {
            if (doc.data().is_read == 0) {
              doc.ref.update({ is_read: 1 });
            }
          }
        });
        this.emptyNavAction();
        unSub();
      });
    },
    isMe(chat) {
      return chat.from_type == "App\\User";
    },
    format_date(timestamp) {
      return moment(parseInt(timestamp)).format("HH:mm DD-MM-YYYY");
    },
  },
  mounted() {
    this.get_rooms();
    this.$root.$on("triggerChatRoomsData", () => {
      this.get_rooms();
    });
  },
};
</script>
  
  <style scoped lang="scss">
.app {
  display: grid;
  place-items: center;
  background-color: transparent;
  height: 80vh;
}

.mobile-link {
  color: blue !important;
}

.app__body {
  display: flex;
  background-color: transparent;
  height: 80vh;
  width: 97%;
  margin-top: 20px;
}
.chat__separator {
  border-radius: 2px;
  font-size: 12px;
}
.chat__message {
  position: relative;
  margin-right: auto;
  width: fit-content;
  font-size: 16px;
  padding: 10px;
  background-color: #ebeef7;
  color: #6c7995;
  border-radius: 10px;
  margin-bottom: 20px;
}
.chat__reciever {
  position: relative;
  width: fit-content;
  font-size: 16px;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-left: auto;
  background-color: #f4f4f5;
  color: #6c7995;
}
.md-progress-spinner {
  place-self: center;
}
.sidebar {
  display: flex;
  flex-direction: column;
  flex: 0.25;
  border-radius: 7px 7px 0 0 !important;
  overflow: hidden !important;
}
.sidebar__header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-right: 1px solid lightgrey;
}
.sidebar__headerRight {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 10vw;
}
.sidebar__headerRight > i {
  margin-right: 2vw;
  font-size: 24px !important;
}
.sidebar__search {
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  height: 30px;
  border-radius: 8px 8px 0 0;
}
.sidebar__searchContainer {
  display: flex;
  align-items: center;
  background-color: white;
  width: 100%;
  height: 50px;
}
.sidebar__searchContainer .v-text-field.v-text-field--solo ::placeholder {
  color: #2c2c2c;
  font-size: 16px;
}
.sidebar__searchContainer > input {
  border: none;
  margin-left: 10px;
}
.sidebar__searchContainer > .v-icon {
  margin: unset;
  color: gray;
}
.sidebar__chat {
  flex: 1;
  background-color: white;
  overflow-y: hidden;
}
.sidebar__chat:hover {
  overflow-y: auto;
}

.sidebarChat {
  display: flex;
  border: 1px solid #dfdfdfcf;
  margin: 18px 18px 0px;
  padding: 10px 0;
  cursor: pointer;
  position: relative;
  border-radius: 6px;
  &::after {
    position: absolute;
    content: "";
    width: 10px;
    height: 100%;
    right: 0;
    top: 0;
    background-color: #3f0e40;
    display: none;
    border-radius: 0 6px 6px 0;
  }
}

.sidebarChat > .md-avatar {
  margin: unset;
}

.sidebarChat:hover,
.sidebarChat.active {
  outline: 2px solid #3f0e40;
  border: none;
  padding-right: 10px;
  &::after {
    display: block;
  }
}

.sidebarChat__info > h2 {
  font-size: 16px;
  margin-bottom: 8px;
}

.sidebarChat__info {
  margin-left: 15px;
}
.chat {
  flex: 0.75;
  display: flex;
  flex-direction: column;
  margin-right: 25px;
}

.chat__header {
  padding: 10px;
  display: flex;
  border-bottom: 1px solid lightgray;
  background-color: #d9cfd9;
  border-radius: 7px 7px 0 0;
}

.chat__headerInfo {
  flex: 1;
}

.chat__headerInfo > h3 {
  margin-bottom: 3px;
  font-weight: 500;
}

.chat__headerInfo > p {
  color: gray;
}

.chat__body {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  background-color: #fff;
}

.chat__name {
  position: absolute;
  top: -15px;
  font-weight: 800;
  font-size: xx-small;
}

.chat__timestamp {
  margin-left: 10px;
  font-size: x-small;
}

.chat__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
  background: white;
}

.chat__footer > .v-form {
  flex: 1;
  display: flex;
}

.chat__footer input:not([type="file"]) {
  flex: 1;
  border-radius: 30px;
  padding: 10px;
  border: none;
}
.chat__footer > md-icon {
  padding: 10px;
  color: gray;
}
fieldset {
  margin: 0 18px;
  border-radius: 4px;
}
.v-input__control {
  margin: 0 16px !important;
  border-radius: 4px !important;
}
@media (max-width: 990px) {
  .app__body {
    flex-direction: column;
  }
  .sidebar {
    height: 20vh;
    border-bottom: 3px solid #c4c4c4;
    margin-bottom: 0 !important;
  }
  .sidebar__chat {
    overflow-y: auto;
    width: 100% !important;
    & > div {
      width: 33.33%;
    }
  }
  .sidebar__chat {
    display: flex;
    flex-wrap: wrap;
  }
  .v-avatar {
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;
  }
  .sidebarChat__info {
    h3 {
      font-size: 16px !important;
    }
  }
  .chat {
    height: 30vh;
    margin: 0;
  }
}
@media (max-width: 767px) {
  .sidebar__chat {
    & > div {
      width: 50%;
    }
  }
}
@media (max-width: 576px) {
  .sidebar__chat {
    & > div {
      width: 100%;
    }
  }
  .sidebarChat {
    padding: 10px 16px;
  }
}
</style>
  