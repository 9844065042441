<template>
  <v-row justify="center">
    <v-dialog
        v-model="$store.state.NeedToMoveQualityNewSearch"
        persistent
        width="824"
    >
      <v-card id="adminFilter">
        <v-card-title>
          <span class="text-h5">البحث</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
              >
                <input type="checkbox" id="unActive" name="unActive" @change="handleChecked" value="غير نشط" :checked="mangerChecked">
                <label for="unActive"> غير نشط </label><br>

              </v-col>
              <v-col
                  cols="12"
                  sm="6"
              >
                <input type="checkbox" id="active" name="active" value="نشط" :checked="agentChecked" @change="handleCheckedAgent">
                <label for="unActive"> نشط</label><br>

              </v-col>

              <v-col cols="12" sm="6" md="6">
                <SelectInput
                    @selectedValues="getSelectedValueManger"
                    :originalItems="getManagerNames"
                    label="مدير المبيعات"
                />
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <SelectInput
                    @selectedValues="getSelectedValueAgent"
                    :originalItems="getAgentsName"
                    label="استشاري المبيعات"
                />
              </v-col>

              <v-col cols="12" lg="6" md="6">
                <SelectInput
                    @selectedValues="getSelectedAgentType"
                    :originalItems="agentsType"
                    label="تصنيف استشاري المبيعات"
                />
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <SelectInput
                    @selectedValues="getSelectedValueQuality"
                    :originalItems="qualityUsers"
                    label="الجوده"
                />
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue-darken-1"
              variant="text"
              @click="$store.state.NeedToMoveQualityNewSearch = false"
          >
            الغاء
          </v-btn>
          <v-btn
              color="blue-darken-1"
              variant="text"
              @click='searchRequest'
          >
            بحث
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import Axios from "axios";
import {removeDuplicated} from "@/mixins/removeDublicated";
import SelectInput from "@/components/Charts/SelectInput.vue";
import {SelectItems} from "@/mixins/selectItems";
import {formatDate} from "@/mixins/formatDate";
import {qualityUser} from "@/mixins/qualityUser";

export default {
  components: {SelectInput},
  props: ['type'],
  data: () => ({
    dialog: false,
    product_code: [],
    selectedProductType: [],
    allMangers:[],
    productType: [],
    agentsType: [],
    processType: [],
    allAgents:[],
    request_types: [],
    allSelectedAgent: [],
    mangerChecked: false,
    agentChecked: false,
    selectedManger: [],
    selectedAgent: [],
    processSelect: [],
    AllSelectedTable: [],
    allProcessType: [],
    selectedMang: [],
    active: false,
    message: "",
    color: '',
    body: {},
    qualityUsers: [],
    selectedQuality: [],

  }),
  computed:{
    getManagerNames() {
      return this.allMangers;
    },

    getAgentsName(){
      return this.allAgents;
    }
  },

  watch: {
    mangerChecked(val) {
      if (val === false) {
        this.getAllManger('manager' , 1)
      } else if (val === true) {
        this.getAllManger('manager' , 0)
      }
    },

    agentChecked(val) {
      if (val === false) {
        this.getAllAgents('agent' , 0)
      } else if (val === true) {
        this.getAllAgents('agent' , 1)
      }
    },
  },

  mounted() {
    this.getAllAgents('agent' , 0);
    this.getAllManger('manager');
    this.getAgentClarification();
    this.getProductType();
    this.convertRequest()
  },


  methods:{
    getSelectedValueQuality(val){
      this.selectedQuality = val
    },

    async convertRequest() {
      this.dialogQuality = true;
      await qualityUser().then(res => {
        this.qualityUsers = res.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["name_for_admin"] || item['name'];
          return item;
        })
      })

    },

    searchRequest(){
      this.$store.state.isSearch = true;
      this.$store.state.NeedToMoveQualityNewSearch = false
      this.body = new FormData();
      
      this.$store.state.needTurnNewSearch = [];
      this.$store.state.needTurnNewData = [];
      this.$store.state.allRequests = [];
      this.$store.state.tableData = [];
      this.$store.state.InfinityPageNumber = 1;
      this.$store.state.back = true
      this.$store.state.startElement = [];
      this.$store.state.bodySearch = {}
      this.counter++

      
      this.$store.state.needTurnNewLoading = true;
      this.$store.state.loadingData = true;

      this.body.append('page', 1);

      if (this.selectedAgent.length) {
        this.selectedAgent.forEach((item, index) => {
          if(window.location.pathname === "/admin/requests/need-to-turn-new"){
            this.body.append(`quality_request_need_turned_sales_agents[${index}]`, item["id"]);
          }else if (window.location.pathname === "/admin/requests/need-to-turn-done"){
            this.body.append(`quality_request_need_turned_sales_agent[${index}]`, item["id"]);
          }
        });
      }

      if (this.allSelectedAgent.length > 0) {
        this.allSelectedAgent.forEach((item, index) => {
          this.body.append(`quality_request_need_turned_agent_classification[${index}]`, item["id"]);
        });
      }

      if (this.selectedQuality.length) {
        this.selectedQuality.forEach((item, index) => {
          this.body.append(`quality_request_need_turned_quality_user[${index}]`, item["id"]);
        });
      }


      let url = `${this.$store.state.url}/v2/employee/Requests/search?type=${this.type}`
      Axios.post(url, this.body, {headers: this.request_headers()}).then(res => {
        this.$store.state.pagination = {
          limit: 3,
          count: res.data.payload.searched
        }
        let response = res.data.payload.data

        this.$store.state.needTurnNewData.push(...response);
        this.$store.state.needTurnNewSearch.push(...response);
        this.$store.state.allRequests.push(...response);

     
        res.data.payload.data.map((item) => {
          this.setTbData(item);
          item["starChecked"] = false;
          return item;
        });

        this.$store.state.needTurnNewLoading = false
        this.$store.state.loadingData = false;
        this.$store.state.pagination.count = res.data.payload.searched

      }).catch(err => {
        this.$store.state.needTurnNewLoading = false;
        this.$store.state.loadingData = false;
      })
      this.$store.state.bodySearch = this.body;

    },

    setTbData(items) {
      this.$store.state.tableData.push({
        "id": items.id,
        'تاريخ الطلب': formatDate(items.quality_request.request?.created_at),
        'حاله الطلب':  items.status,
        "الجوده": items.qualityUser.name,
        "استشاري المبيعات": items.quality_request.request.user?.name,
        'عميل': items.quality_request.request.customer.name,
        'الجوال': items.quality_request.request.customer.mobile,
        'تصيف الاستشاري': items.quality_request.request.class_id_agent_text,
        "agent_notes": items.quality_request.request.agent_notes,
        "quality_notes": items.quality_request.request.agent_notes,
      })
    },


    getSelectedAgentType(val) {
      this.allSelectedAgent = val
    },

    getAgentClarification() {
      let url = `${this.$store.state.url}/agent/Request/Classification?type_of_classification=0`
      Axios.get(url, {headers: this.request_headers()}).then(res => {
        let response = res.data.payload
        this.agentsType = SelectItems(response)
      })
    },

    getSelectedProductType(val) {
      this.selectedProductType = val
    },


    getProductType() {
      let url = 'https://api.alwsatarealestate.com/api/customer/SelectedProduct'
      Axios.get(url, {headers: this.request_headers()}).then(res => {
        this.productType = res.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["value"];
          return item;
        });
      })
    },

    getProcessType(val){
      this.allProcessType = val
    },


    getSelectedValueAgent(val){
      this.selectedAgent = val
    },

    getSelectedValueManger(val){
      this.selectedMang = val
      if (this.agentChecked === false) {
        this.getAllAgents('agent' , 0 ,val);
      } else if (this.agentChecked === true) {
        this.getAllAgents('agent' , 1 , val)
      }
    },

    handleChecked(){
      this.mangerChecked = !this.mangerChecked;
    },

    handleCheckedAgent(){
      this.agentChecked = !this.agentChecked
    },

    getAllManger(role ,status) {
      this.allMangers = [];
      let body = {'role': role}
      if (status === 0) {
        body = {
          status: status,
          role: role
        }
      }

      let url = 'https://api.alwsatarealestate.com/api/employee/admin/filterUsers';
      Axios.post(url, body, {headers: this.request_headers()}).then(res => {
        this.allMangers = (res.data.payload);
        this.allMangers.map((item) => {
          item["color"] = "red";
          item["text"] = item["name"];
          return item;
        });
      })
    },

    getAllAgents(role, status , val) {
      this.allAgents = [];
      let body = new FormData();
      body.append('role',role);
      if (status === 0 && !val) {
        body.append('status', status);
        body.append('role',role);
      }else if (status === 1 && !val){
        body.append('status', status);
        body.append('role',role);
      }
      if (val) {
        val.forEach((item, index) => {
          this.selectedManger.push(`'manager_id[${index}]': '${item["id"]}'`);
        });
      }

      let removeDublicate = removeDuplicated(this.selectedManger)


      if(removeDublicate.length){
        val.forEach((item, index) => {
          body.append(`manager_id[${index}]`, item["id"]);
        });
        body.append('status', status);
        body.append('role',role);
      }

      let url = 'https://api.alwsatarealestate.com/api/employee/admin/filterUsers';
      Axios.post(url, body, {headers: this.request_headers()}).then(res => {
        this.allAgents = (res.data.payload);
        this.allAgents.map((item) => {
          item["color"] = "red";
          item["text"] = item["name"];
          return item;
        });
      })
    },
  }
}
</script>