<template>
  <div class="rangePicker">
    <span style="position: absolute; z-index: 2; right: 50px; top: 32px">
      <span v-if="dateRange.startDate === null">المدة</span>
      <span v-else></span>
      <span  v-if="dateRange.startDate != null" style="position: absolute;
    top: -28px;
    right: -2rem;
    background: red;
    color: #fff;
    padding: 0px 7px;
    border-radius: 3px;
    cursor: pointer;" @click="clearDateRange"> clear</span>
    </span>

    <date-range-picker
      :format="format"
      ref="picker"
      v-model="dateRange"
      @start-selection="handleDateRangeChange($event, 'start')"
      @finish-selection="handleDateRangeChange($event, 'end')"
      outlined
      dense
    >
    </date-range-picker>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
var moment = require("moment");
export default {
  components: { DateRangePicker },
  data() {
    return {
      direction: "ltr",
      // picker: null,
      dateRange: { startDate: null, endDate: null },
      format: "mm/dd/yyyy",
      applyLabel: "Apply",
      cancelLabel: "Cancel",
      firstDay: 0,
    };
  },

  methods: {
    handleDateRangeChange(val, flag) {
      if (flag == "end") {
        this.dateRange.endDate = moment(this.$refs.picker.endText).format("YYYY-MM-DD");
      } else {
        this.dateRange.startDate = moment(this.$refs.picker.startText).format("YYYY-MM-DD");
      }
      this.$emit("sendDate", this.dateRange);
    },
    clearDateRange() {
      this.dateRange.startDate = null;
        this.dateRange.endDate = null;
      this.$emit("sendDate", this.dateRange);
    },
  },
};
</script>

<style>
.rangePicker .reportrange-text[data-v-1ebd09d2] {
  height: 48px;
  padding: 12px;
  border-radius: 4px;
  background: #f3f5f7;
  display: flex;
  justify-content: end;
  align-items: center;
}

.rangePicker .vue-daterange-picker {
  width: 100%;
}

.rangePicker .daterangepicker.show-calendar .ranges {
  display: none;
}

.rangePicker .daterangepicker.show-ranges[data-v-1ebd09d2] {
  min-width: 510px;
}

.rangePicker .daterangepicker.openscenter[data-v-1ebd09d2] {
  left: 0px;
}
</style>
