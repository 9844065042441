import AllRequests from "@/components/Requests/AllRequests.vue";
import RecievedRequests from "@/components/Requests/RecievedRequests.vue";
import FollowRequests from "@/components/Requests/FollowRequests.vue";
import SpecialRequests from "@/components/Requests/SpecialRequests.vue";
import ArchievedRequests from "@/components/Requests/ArchievedRequests.vue";
import ArchievedInQualityRequests from "@/components/Requests/ArchievedInQualityRequests.vue";
import CompletedRequests from "@/components/Requests/CompletedRequests.vue";
import BuyingRequests from "@/components/Requests/BuyingRequests.vue";
import ExtraRequests from "@/components/Requests/ExtraRequests.vue";
import FundHistory from "@/views/agent2/FundHistory.vue";
import RequestTicket from "@/components/RequestDetails/RequestTicket.vue";
import Vacations from "@/views/agent2/Vacations/index.vue";
import SentTickets from "@/components/Tickets/SentTickets.vue";
import RecievedTickets from "@/components/Tickets/RecievedTickets.vue";
import CompletedTickets from "@/components/Tickets/CompletedTickets.vue";
import TicketDetails from "@/components/Tickets/TicketDetails.vue";
import TheNotifc from "@/views/agent2/TheNotifc.vue";
import ChatComponent from "@/components/ChatComponent.vue";
import TheCalender from "@/views/agent2/TheCalendar.vue";
import TheCharts from "@/views/agent2/TheCharts.vue";
import TheAnnouncements from "@/views/agent2/TheAnnouncements.vue";
import RateServices from "@/views/agent2/RateServices.vue";
import ProfilePage from "@/views/agent2/ProfilePage.vue";
import AnnouncementContent from "@/views/agent2/AnnouncementContent.vue";
import AgentSupport from "@/views/agent2/AgentSupport.vue";
import SupportContent from "@/views/agent2/SupportContent.vue";
import SupportContentNewDesign from "@/views/agent2/SupportContentNewDesign.vue";
import EditVacation from "@/views/agent2/Vacations/EditVacation.vue";
import AddNewVacation from "@/views/agent2/Vacations/AddNewVacation.vue";

export const agentRouter = [
    {
        path: "/",
        name: "agentHome",
        meta: {
            icon: "",
            listed: false,
            page_name: "جميع الطلبات",
            breadcrumb: "",
        },
        redirect: { name: "all" },
    },
    {
        path: "/agent/requests/all",
        name: "all",
        component: AllRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات",
            page_name: "جميع الطلبات",
            breadcrumb: "",
        },
    },
    {
        path: "/agent/requests/recieved",
        name: "recieved",
        component: RecievedRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات",
            page_name: "الطلبات المستلمة",
        },
    },
    {
        path: "/agent/requests/follow",
        name: "following",
        component: FollowRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات",
            page_name: "الطلبات المتابعة",
        },
    },
    {
        path: "/agent/requests/special",
        name: "special",
        component: SpecialRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات",
            page_name: "الطلبات المميزة",
        },
    },/*
    {
        path: "/agent/requests/archieved",
        name: "archieved",
        component: ArchievedRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات",
            page_name: "الطلبات المؤرشفة",
        },
    },
    {
        path: "/agent/requests/completed",
        name: "completed",
        component: CompletedRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات",
            page_name: "الطلبات المكتملة",
        },
    },
    {
        path: "/agent/requests/prepaid",
        name: "buying",
        component: BuyingRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات",
            page_name: "طلبات الشراء - الدفعة",
        },
    },*/
    {
        path: "/agent/requests/extra",
        name: "extra",
        component: ExtraRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات",
            page_name: "الطلبات الاضافية",
        },
    },

    {
        path: "/agent/requests/archieved-in-quality",
        name: "archievedInQuality",
        component: ArchievedInQualityRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات",
            page_name: "أرشيف الجودة",
        },
    },

    {
        path: "/agent/requests/:id/funding-calculator-history",
        name: "FundHistory",
        component: FundHistory,
        meta: {
            icon: "",
            listed: false,
            group: "الرئيسية / الطلبات",
            page_name: "تاريخ التمويل",
        },
    },
    {
        path: "/agent/requests/:id/tickets/:ticket",
        name: "request_ticket",
        component: RequestTicket,
        meta: { listed: false },
    },
    // {
    //     path: "/agent/vacations",
    //     name: "agent_vacations",
    //     component: Vacations,
    //     meta: {
    //         icon: "",
    //         listed: true,
    //         group: " طلبات الاجازات",
    //         page_name: "طلباتي ",
    //     },
    // },
    // {
    //     path: "/agent/vacations/edit/:id",
    //     name: "edit_agent_vacation",
    //     component: EditVacation,
    //     meta: {},
    // },
    // {
    //     path: "/agent/vacations/add",
    //     name: "agent_add_vacation",
    //     component: AddNewVacation,
    //     meta: {
    //         icon: "",
    //         listed: true,
    //         group: " طلبات الاجازات",
    //         page_name: "اضافة",
    //     },
    // },



    {
        path: "/agent/tickets/sent",
        name: "sent_tickets",
        component: SentTickets,
        meta: {
            icon: "",
            listed: true,
            group: "التذاكر",
            page_name: "التذاكر المرسله",
        },
    },
    {
        path: "/agent/tickets/recieved",
        name: "recieved_tickets",
        component: RecievedTickets,
        meta: {
            icon: "",
            listed: true,
            group: "التذاكر",
            page_name: "التذاكر المستلمه",
        },
    },
    {
        path: "/agent/tickets/completed",
        name: "completed_tickets",
        component: CompletedTickets,
        meta: {
            icon: "",
            listed: true,
            group: "التذاكر",
            page_name: "التذاكر المكتمله",
        },
    },
    {
        path: "/agent/tickets/:ticket",
        name: "ticket_details",
        component: TicketDetails,
        meta: { listed: false },
    },
    {
        path: "/agent/notifications",
        name: "TheNotifc",
        component: TheNotifc,
        meta: {
            icon: `
        <svg xmlns="http://www.w3.org/2000/svg" width="18.552" height="19" viewBox="0 0 20.552 21">
          <g id="message_1_" data-name="message (1)" transform="translate(1 1)">
            <g id="bell">
              <line id="Line_3" data-name="Line 3" x2="3" transform="translate(8 19)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path id="Path_2642" data-name="Path 2642" d="M22.552,19.179H4a3.044,3.044,0,0,0,1.687-2.732V11.59A7.59,7.59,0,0,1,13.276,4h0a7.59,7.59,0,0,1,7.59,7.59v4.857a3.044,3.044,0,0,0,1.687,2.732Z" transform="translate(-4 -4)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </g>
          </g>
        </svg>
        `,
            listed: true,
            page_name: "الإشعارات",
        },
    },
    {
        path: "/agent/chat",
        name: "chat_component",
        component: ChatComponent,
        meta: {
            icon: `
        <svg xmlns="http://www.w3.org/2000/svg" width="18.574" height="16.641" viewBox="0 0 18.574 16.641">
          <g id="Group_3168" data-name="Group 3168" transform="translate(0.5 0.5)">
            <path id="Path_30" data-name="Path 30" d="M14.228,591.566v-2.294a3.022,3.022,0,0,0-3.022-3.022H4.442a3.022,3.022,0,0,0-3.022,3.022v2.294" transform="translate(-1.42 -575.975)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
            <circle id="Ellipse_14" data-name="Ellipse 14" cx="3.505" cy="3.505" r="3.505" transform="translate(2.899 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
            <path id="Path_31" data-name="Path 31" d="M23.58,566.05a3.5,3.5,0,0,1,0,7.01" transform="translate(-12.308 -566.05)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
            <path id="Path_32" data-name="Path 32" d="M30.47,585.3s3.23.727,2.747,5.8" transform="translate(-15.693 -575.508)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
          </g>
        </svg>
        `,
            listed: true,
            page_name: "محادثات العملاء"
        },
    },
    {
        path: "/agent/calender",
        name: "TheCalender",
        component: TheCalender,
        meta: {
            icon: `
        <svg xmlns="http://www.w3.org/2000/svg" width="17.599" height="18.22" viewBox="0 0 17.599 18.22">
          <path id="reminder" d="M19.967,18.29a2.376,2.376,0,0,1-.7-1.278c0-.015,0-.029,0-.043V15.339A2.545,2.545,0,0,0,17.3,12.85V5.3a2.2,2.2,0,0,0-2.2-2.2H13.137V2.587a.44.44,0,0,0-.88,0V3.1H7.544V2.587a.44.44,0,0,0-.88,0V3.1H4.7A2.2,2.2,0,0,0,2.5,5.3v10.41a2.2,2.2,0,0,0,2.2,2.2h9.041a3.559,3.559,0,0,1-.311.377.44.44,0,0,0,.308.754h1.521a1.44,1.44,0,0,0,2.871,0h1.528a.44.44,0,0,0,.308-.754ZM4.7,3.984H6.664v.521a.44.44,0,1,0,.88,0V3.984h4.713v.521a.44.44,0,1,0,.88,0V3.984H15.1a1.32,1.32,0,0,1,1.32,1.32v.9H3.38V5.3A1.32,1.32,0,0,1,4.7,3.984Zm0,13.049a1.32,1.32,0,0,1-1.32-1.32V7.082H16.421V12.8a2.56,2.56,0,0,0-2.282,2.541v1.674c0,.015-.005.014-.005.021Zm12,2.453a.562.562,0,0,1-.543-.44h1.085a.561.561,0,0,1-.542.44Zm.973-1.32H14.643a2.332,2.332,0,0,0,.375-1.1.535.535,0,0,0,0-.054V15.339a1.68,1.68,0,1,1,3.359,0v1.729a2.335,2.335,0,0,0,.375,1.1Z" transform="translate(-2.5 -2.147)" fill="#fff"/>
        </svg>
      `,
            listed: true,
            page_name: "التذكيرات"
        },
    },
    {
        path: "/agent/charts",
        name: "TheCharts",
        component: TheCharts,
        meta: {
            icon: `
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
          <g id="Analytics_2" data-name="Analytics 2" transform="translate(-1 -1)">
            <rect id="Rectangle_1796" data-name="Rectangle 1796" width="18" height="18" rx="1.5" transform="translate(1.5 1.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
            <rect id="Rectangle_1797" data-name="Rectangle 1797" width="2" height="12" transform="translate(9.5 4.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
            <rect id="Rectangle_1798" data-name="Rectangle 1798" width="2" height="6" transform="translate(14.5 10.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
            <rect id="Rectangle_1799" data-name="Rectangle 1799" width="2" height="9" transform="translate(4.5 7.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
          </g>
        </svg>
        `,
            listed: true,
            page_name: "الرسوم البيانية",
        },
    },
    {
        path: "/agent/announcements",
        name: "TheAnnouncements",
        component: TheAnnouncements,
        meta: {
            icon: `
        <svg xmlns="http://www.w3.org/2000/svg" width="17.599" height="17.468" viewBox="0 0 17.599 17.468">
          <g id="_Group_" data-name=" Group " transform="translate(-38 -39.966)">
            <path id="_Compound_Path_" data-name=" Compound Path " d="M53.721,39.966a1.884,1.884,0,0,0-1.864,1.639c-.741.56-4.107,2.964-7.288,2.964H40a2.006,2.006,0,0,0-2,2.008v3.132a2,2,0,0,0,1.99,2h.268l1.309,4.272a2,2,0,1,0,3.843-1.1,13.524,13.524,0,0,1-.432-3.144c3.185.182,6.2,2.409,6.882,2.944a1.876,1.876,0,0,0,3.738-.24V41.85a1.88,1.88,0,0,0-1.876-1.884Zm-9.546,10.94H40.987v-5.53h3.189Zm-5.368-1.2V46.584A1.2,1.2,0,0,1,40,45.376h.184v5.53H40a1.189,1.189,0,0,1-1.2-1.181Zm5.066,6.822a1.183,1.183,0,0,1-.932-.061,1.2,1.2,0,0,1-.6-.722L41.1,51.713h3.069a14.457,14.457,0,0,0,.46,3.375,1.2,1.2,0,0,1-.758,1.443Zm1.109-5.6V45.362a12.081,12.081,0,0,0,5.044-1.622,19.53,19.53,0,0,0,1.818-1.127V53.661A17.7,17.7,0,0,0,50.1,52.554,12.316,12.316,0,0,0,44.983,50.93Zm9.808,3.513a1.07,1.07,0,1,1-2.139,0V41.849a1.07,1.07,0,0,1,2.139,0Z" transform="translate(0)" fill="#fff"/>
          </g>
        </svg>
        `,
            listed: true,
            page_name: "سجل التعميمات",
        },
    },
    {
        path: "/agent/rates",
        name: "RateServices",
        component: RateServices,
        meta: {
            icon: `
        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="21.599" height="21.468" viewBox="0 0 128.000000 128.000000" preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)" fill="#fff" stroke="none">
        <path d="M593 1228 c-11 -13 -51 -96 -88 -186 -38 -90 -72 -165 -76 -168 -4 -2 -95 -13 -200 -23 -176 -17 -194 -20 -211 -41 -40 -50 -23 -72 231 -292 l53 -46 -43 -192 c-40 -177 -42 -194 -28 -218 29 -52 61 -43 240 62 88 53 165 96 171 96 5 0 82 -43 169 -96 176 -106 209 -114 238 -62 14 24 12 41 -28 218 -36 161 -41 194 -30 204 8 7 52 45 99 86 195 170 211 192 172 240 -17 21 -35 24 -212 41 -107 10 -197 22 -201 26 -5 5 -39 81 -77 171 -37 90 -76 172 -85 182 -23 27 -69 25 -94 -2z m122 -248 c42 -102 75 -166 89 -175 13 -8 90 -20 196 -30 96 -9 176 -17 177 -18 1 -1 -61 -58 -138 -125 -118 -105 -139 -127 -139 -152 0 -16 16 -99 36 -185 20 -86 33 -159 31 -162 -3 -2 -74 36 -158 86 -84 50 -159 91 -168 91 -9 0 -87 -43 -173 -95 -87 -52 -158 -88 -158 -82 0 7 16 83 35 168 20 84 35 167 33 184 -2 24 -28 52 -140 150 -75 66 -136 121 -135 122 1 1 81 9 177 18 101 9 184 22 196 30 12 8 47 77 89 174 37 89 71 161 75 161 4 0 37 -72 75 -160z"/>
        </g>
        </svg>
        `,
            listed: true,
            page_name: "تقييمات الخدمة",
        },
    },
    {
        path: "/agent/profile",
        name: "profile",
        component: ProfilePage,
        meta: {
            // icon: "mdi-update",
            listed: false,
            // group: " ",
            page_name: "تعديل البيانات ",
        },
    },
    {
        path: "/agent/announcements/:id",
        name: "AnnouncementContent",
        component: AnnouncementContent,
        meta: {
            icon: "mdi-update",
            listed: true,
            group: "سجل التعميمات",
            page_name: "محتوى التعميمات",
        },
    },
    {
        path: "/agent/support",
        name: "agent-support",
        component: AgentSupport,
        meta: {
            icon: `
        <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.599"
                height="17.599"
                viewBox="0 0 17.599 17.599"
  
              >
                <path
                  id="support"
                  d="M20.746,12.41v-.463a6.947,6.947,0,1,0-13.894,0v.463A1.852,1.852,0,0,0,5,14.262v2.779a1.852,1.852,0,0,0,1.852,1.852h.083a1.389,1.389,0,0,0,2.7-.463V12.873a1.389,1.389,0,0,0-1.852-1.311,6.021,6.021,0,0,1,12,0,1.389,1.389,0,0,0-1.815,1.311v5.557a1.389,1.389,0,0,0,1.389,1.389h.153a3.242,3.242,0,0,1-2.932,1.852H15.189v-.463A1.389,1.389,0,1,0,13.8,22.6h2.779a4.168,4.168,0,0,0,4.14-3.7h.028A1.852,1.852,0,0,0,22.6,17.041V14.262A1.852,1.852,0,0,0,20.746,12.41ZM5.926,17.041V14.262a.926.926,0,0,1,.926-.926v4.631A.926.926,0,0,1,5.926,17.041ZM8.242,12.41a.463.463,0,0,1,.463.463v5.557a.463.463,0,1,1-.926,0V12.873A.463.463,0,0,1,8.242,12.41ZM13.8,21.672a.463.463,0,1,1,.463-.463v.463Zm5.557-2.779a.463.463,0,0,1-.463-.463V12.873a.463.463,0,0,1,.926,0v5.557A.463.463,0,0,1,19.357,18.894Zm2.316-1.852a.926.926,0,0,1-.926.926V13.336a.926.926,0,0,1,.926.926Z"
                  transform="translate(-5 -5)"
                  fill="white"
              
                />
              </svg> 
        `,
            listed: true,
            group: "طلبات الدعم الفني",
            page_name: "طلبات الدعم الفني"
        },
    },
    {
        path: "/agent/support-tickets/:id",
        name: "SupportContent",
        // component: SupportContent,
        component: SupportContentNewDesign,
        meta: {
            icon: "mdi-update",
            listed: true,
            group: "سجل التعميمات",
            page_name: "محتوى التعميمات",
        },
    },
]