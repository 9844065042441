import * as mutations from "./types";
export default {
  get_work_sources(context, work_sources) {
    context.commit(mutations.GET_WORK_SOURCES, work_sources);
  },
  get_salary_sources(context, salary_sources) {
    context.commit(mutations.GET_SALARY_SOURCES, salary_sources);
  },
  setNavChatAction(context, payload) {
    context.commit(mutations.setNavbarChatNotifs, payload);
  },
  emptyNavAction(context) {
    context.commit("emptyNavData");
  },
};
