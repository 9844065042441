<template>
  <v-container>
    <v-row class="mt-7 pa-7 white rounded-lg">
      <v-col class="py-0" cols="12">
        <p class="font-weight-bold">إعدادت السله الحرة (تفعيل سلال السحب)</p>
      </v-col>
      <v-col cols="12" sm="12">
        <p>عدد أيام الطلبات المتأخرة</p>
        <v-text-field type="number" outlined dense v-model="days"></v-text-field>
      </v-col>
      <v-col
        cols="12"
        style="
          margin-top: 25px;
          margin-bottom: 15px;
          padding-top: 0;
          padding-bottom: 0;
          height: 0;
        "
      >
        <hr style="margin: 0; padding: 0" />
      </v-col>
      <v-col cols="12" sm="12">
        <p class="">المنتجات</p>
        <v-row class="white rounded-lg">
          <v-col v-for="(item, index) in products" :key="index" cols="3">
            <v-checkbox
              style="padding: 0; margin: 0; height: 16px"
              v-model="selected"
              :label="item.value"
              :value="item.id"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        style="
          margin-top: 25px;
          margin-bottom: 15px;
          padding-top: 0;
          padding-bottom: 0;
          height: 0;
        "
      >
        <hr style="margin: 0; padding: 0" />
      </v-col>
      <v-col
        cols="12"
        style="
          margin-top: 0px;
          margin-bottom: 15px;
          padding-top: 0;
          padding-bottom: 0;
          height: 0;
        "
      >
        <p>تفعيل السحب من السلال</p>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="6" xs="12">
        <v-checkbox
          style="margin: 0; padding: 0; height: 20px"
          :true-value="1"
          :false-value="0"
          label="السحب من سلة الطلبات المتأخرة"
          v-model="move_lates_by_agent_active"
        />
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="6" xs="12">
        <v-checkbox
          style="margin: 0; padding: 0; height: 20px"
          :true-value="1"
          :false-value="0"
          label="السحب من سلة الطلبات المجمدة"
          v-model="move_freez_by_agent_active"
        />
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="6" xs="12">
        <v-checkbox
          style="margin: 0; padding: 0; height: 20px"
          :true-value="1"
          :false-value="0"
          label="السحب من سلة الطلبات المؤرشفة"
          v-model="move_archives_by_agent_active"
        />
      </v-col>

      <v-col class="py-5" cols="12">
        <v-btn class="primary px-6" @click="updateRequestSettings">تحديث </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Axios from "axios";
export default {
  name: "RequestSetting",
  data() {
    return {
      products: [],
      selected: [],
      days: "",
      move_lates_by_agent_active: "",
      move_freez_by_agent_active: "",
      move_archives_by_agent_active: "",
    };
  },
  mounted() {
    this.getRequestSettings();
  },
  methods: {
    async getRequestSettings() {
      await Axios.get("https://api.alwsatarealestate.com/api/employee/request-settings", {
        headers: this.request_headers(),
      }).then((response) => {
        this.products = response.data.payload.products;
        this.selected = response.data.payload.selected;
        this.days = response.data.payload.days;
        this.move_archives_by_agent_active =
          response.data.payload.move_archives_by_agent_active;
        this.move_freez_by_agent_active =
          response.data.payload.move_freez_by_agent_active;
        this.move_lates_by_agent_active =
          response.data.payload.move_lates_by_agent_active;
      });
    },
    async updateRequestSettings() {
      let body = new FormData();
      if (this.selected.length) {
        this.selected.forEach((item, index) => {
          body.append(`selected_ids[${index}]`, item);
        });
      }

      body.append("days", this.days);
      body.append("move_archives_by_agent_active", this.move_archives_by_agent_active);
      body.append("move_freez_by_agent_active", this.move_freez_by_agent_active);
      body.append("move_lates_by_agent_active", this.move_lates_by_agent_active);

      await Axios.post(
        "https://api.alwsatarealestate.com/api/employee/update-request-settings",
        body,
        {
          headers: this.request_headers(),
        }
      ).then((response) => {
        this.showPopup("success", response.data.message);
      });
    },
  },
};
</script>

<style lang="scss">
.v-input__control {
  min-height: 40px !important;
}
</style>
