<template>
  <div>
    <v-list-item @click="toRequestDetails">
      <v-list-item-icon class="ml-2">
        <v-icon>mdi-eye-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-title>فتح</v-list-item-title>
    </v-list-item>

    <router-link
      style="text-decoration: none"
      target="_blank"
      :to="{ name: 'request_details', params: { id: request_id } }"
    >
      <v-list-item>
        <v-list-item-icon class="ml-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.528"
            height="15.111"
            viewBox="0 0 13.528 10.111"
          >
            <g
              id="Icon_feather-eye-dark"
              data-name="Icon feather-eye-dark"
              transform="translate(-1 -5.5)"
            >
              <path
                id="Path_4040"
                data-name="Path 4040"
                d="M1.5,10.556S3.778,6,7.764,6s6.264,4.556,6.264,4.556-2.278,4.556-6.264,4.556S1.5,10.556,1.5,10.556Z"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
              <path
                id="Path_4041"
                data-name="Path 4041"
                d="M16.917,15.208A1.708,1.708,0,1,1,15.208,13.5,1.708,1.708,0,0,1,16.917,15.208Z"
                transform="translate(-7.444 -4.653)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
          </svg>
        </v-list-item-icon>
        <v-list-item-title> فتح في تاب جديد</v-list-item-title>
      </v-list-item>
    </router-link>


    <v-list-item @click="getRequestCalls">
      <v-list-item-icon class="ml-3">
        <RequestCallsIcon />
      </v-list-item-icon>
      <v-list-item-title>المكالمات</v-list-item-title>
    </v-list-item>

    <v-list-item @click="convertRequest">
      <v-list-item-icon class="ml-2">
        <ConvertRequestIcon />
      </v-list-item-icon>
      <v-list-item-title>تحويل الطلب</v-list-item-title>
    </v-list-item>

    <v-list-item @click="Processed" >
      <v-list-item-icon class="ml-2">
        <ProcessedIcon />
      </v-list-item-icon>
      <v-list-item-title>تمت المعالجه</v-list-item-title>
    </v-list-item>

    <v-list-item @click="toOrderRequest">
      <v-list-item-icon class="ml-2">
        <OrderHistory />
      </v-list-item-icon>
      <v-list-item-title>سجل الطلب</v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
import {ConvertRequestIcon} from "@/assets/adminIcons/convertRequest";
import {OrderHistory} from "@/assets/adminIcons/orderHistory";
import {ProcessedIcon} from "@/assets/adminIcons/processedIcon";
import Axios from "axios";
import { RequestCallsIcon } from "@/assets/adminIcons/requestCalls";

export default {
  name: "AddNewProcess",
  props: ['item','request_id'],
  components: { ConvertRequestIcon, OrderHistory, ProcessedIcon,RequestCallsIcon},

  computed:{
    showWaitingNewProcess(){
      return window.location.pathname === "/admin/requests/wating-new"
    }
  },

  methods: {
    toOrderRequest() {
      this.$store.state.orderRequestId = this.request_id;
      if (this.$store.state.showCards || this.$store.state.showCards == false) {
        this.$store.state.orderRequestDialog = true;
      } else {
        this.$store.state.openOrderRequestSection = true;
      }
    },

    getRequestCalls(){
      this.$store.state.idOfRequestCalls = this.request_id;
      this.$store.state.openCallsDialog = true;
    },

    Processed(){
      let url = `${this.$store.state.url}/v2/employee/Requests/moveReqsNewToDoneArray`
      let body = new FormData();
      body.append('request_ids[]', this.item)


      Axios.post(url, body, {headers: this.request_headers()}).then(res => {
        this.showPopup("success", res.data.message);
      }).catch(err => {
        let error = { err };
        this.showPopup('error' , error.err.response.data.message)
      })
    },

    toRequestDetails(){
      this.$router.push(
          {
            name: "request_details",
            params: {id: this.request_id},
          });
    },


    convertRequest() {
      this.getUserAdmin();
      this.$store.state.openDialog = true;
      // this.$store.state.selectId = this.request_id
      this.$store.state.selectId = this.item;
    },

    getUserAdmin() {
      let url = 'https://api.alwsatarealestate.com/api/v2/employee/sales-agent-users'
      Axios.get(url, {headers: this.request_headers()}).then(res => {
        this.$store.state.allStatusAgent = res.data.payload
      })
    },
  },

}
</script>