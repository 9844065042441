<template>
    <v-sheet style="justify-content: center;
    display: flex;
    padding: 2rem;
    border: 1px solid #cdc4c4;
    border-radius: 9px;">
        <form  style="display: none;">
          <!-- <label>Total: <input name="total" type="range" min="5" max="20" value="20"/></label> -->
          <!-- <label>Score: <input name="score" type="range" min="0" max="20" value="0"/></label> -->
        </form>
        <svg viewBox="0 0 100 100" width="200" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <mask :id="'mask01'+agent.id">
              <circle :id="'c1'+agent.id" cx="50" cy="50" r="40" fill="none" stroke="white" stroke-width="10" stroke-dasharray="95 5" pathLength="1000"/>
            </mask>
          </defs>
          <g transform="rotate(-90 50 50)">
          <circle :mask="'url(#mask01'+agent.id+')'" cx="50" cy="50" r="40" fill="none" stroke="Gainsboro" stroke-width="10" />
          <circle :id="'c2'+agent.id" :mask="'url(#mask01'+agent.id+')'" cx="50" cy="50" r="40" fill="none" :stroke="color" stroke-width="10" stroke-dasharray="500 1000" pathLength="1000" />
          </g>
          <text :id="'t0'+agent.id" x="50" y="50" style="font-size: 10px;" dominant-baseline="middle" text-anchor="middle" font-family="sans-serif" :fill="color">
           {{agent.name}}
          </text>
          <text id="t1" x="50" y="70" dominant-baseline="middle" text-anchor="middle" font-family="sans-serif" :fill="color">
            {{agent.percentage}} %</text>
        </svg>
    </v-sheet>
</template>

<script>
import Axios from "axios";

export default {
    props:
        ["agent", "color"]
    ,
  mounted() {
    const c1 = document.getElementById('c1'+this.agent.id);
    const c2 = document.getElementById('c2'+this.agent.id);
    const t1 = document.getElementById('t1'+this.agent.id);

    const update = (total, score) => {
    c1.setAttribute('stroke-dasharray', `${(1000-total*5)/total} 5`);
    c2.setAttribute('stroke-dasharray', `${1000/total*score} 1000`);
    // t1.textContent = `${score}/${total}`;
    };

    // document.forms.form01.total.addEventListener('change', e => {
    // let total = parseInt(e.target.value);
    // let score = parseInt(e.target.form.score.value);
    // e.target.form.score.setAttribute('max', total);
    // if (score >= total) score = total;
    // update(total, score);
    // });

    // document.forms.form01.score.addEventListener('change', e => {
    // let total = parseInt(e.target.form.total.value);
    // let score = parseInt(e.target.value);
    // update(total, score);
    // });
    let total = 20;
    let score = this.agent.target_percentage;
    if (score >= total) score = total;
    update(total, score);
  },
};
</script>
