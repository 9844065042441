<template>
  <div class="notifs position-absolute">
    <div class="the-container mx-2 my-10">
      <div class="cont-dad">
        <h2 style="margin-bottom: 1rem">تفاصيل طلب اجازة</h2>
        <v-row>
          <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="mb-1 py-0">
            <div class="mb-2">هل هو مدعوم</div>
            <v-radio-group
              row
              class="mr-n4 px-0"
              v-model="payload.period_or_day"
            >
              <v-radio value="day" label="يوم" disabled></v-radio>
              <v-radio value="period" label="فترة" disabled></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
            <div class="mb-2 d-flex justify-space-between align-items-center">
              <div>
                <span v-if="payload.period_or_day == 'day'">تاريخ اليوم</span>
                <span v-else>الفترة من</span>
              </div>
            </div>
            <v-text-field
              type="date"
              hide-details
              v-model="payload.from"
              class="mr-1"
              outlined
              dense
              append-icon
              disabled
            >
              <template v-slot:append>
                <svg
                  id="calendar"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.396"
                  height="16.989"
                  viewBox="0 0 17.396 16.989"
                  style="margin-bottom: -6px"
                >
                  <path
                    id="Path_2784"
                    data-name="Path 2784"
                    d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                    transform="translate(-4.21 -7.917)"
                    fill="#6c757d"
                  />
                  <path
                    id="Path_2785"
                    data-name="Path 2785"
                    d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                    transform="translate(-4.21 -4.86)"
                    fill="#6c757d"
                  />
                </svg>
              </template>
            </v-text-field>
          </v-col>
          <v-col
            v-if="payload.to"
            cols="12"
            lg="3"
            md="4"
            sm="6"
            xs="12"
            class="mb-5 py-0"
          >
            <div class="mb-2 d-flex justify-space-between align-items-center">
              <div>
                <span>الفترة الي</span>
              </div>
            </div>
            <v-text-field
              type="date"
              hide-details
              v-model="payload.to"
              class="mr-1"
              outlined
              dense
              append-icon
              disabled
            >
              <template v-slot:append>
                <svg
                  id="calendar"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.396"
                  height="16.989"
                  viewBox="0 0 17.396 16.989"
                  style="margin-bottom: -6px"
                >
                  <path
                    id="Path_2784"
                    data-name="Path 2784"
                    d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                    transform="translate(-4.21 -7.917)"
                    fill="#6c757d"
                  />
                  <path
                    id="Path_2785"
                    data-name="Path 2785"
                    d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                    transform="translate(-4.21 -4.86)"
                    fill="#6c757d"
                  />
                </svg>
              </template>
            </v-text-field>
          </v-col>
          <template v-if="!returnAgentRole">
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-textarea
                    v-model="note"
                    label="الملاحظه"
                    auto-grow
                    variant="outlined"
                    rows="3"
                    row-height="25"
                    shaped
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <v-col cols="12" lg="12">
            <v-btn
              color="green"
              style="font-size: 16px; margin-left: 10px"
              class="pb-2 receive-btn"
              :loading="Loader"
              @click="accept_vacation(1)"
              dark
            >
              قبول
            </v-btn>
            <v-btn
              color="red"
              style="font-size: 16px"
              class="pb-2 receive-btn"
              :loading="Loader"
              @click="accept_vacation(0)"
              dark
            >
              رفض
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>


<script>
import Axios from "axios";

export default {
  data() {
    return {
      payload: {
        period_or_day: "day",
        from: "",
        to: "",
        alternative_user_id: "",
        vacation_id: Number(this.$route.params.id),
      },
      Loader: false,
      note: "",
    };
  },

  computed: {
    returnAgentRole() {
      return localStorage.getItem("role") == 0;
    },
    userType() {
      if (localStorage.getItem("role") == 0) {
        return "alternative";
      } else if (localStorage.getItem("role") == 1) {
        return "salesmanager";
      } else if (localStorage.getItem("role") == 12) {
        return "hr";
      } else if (localStorage.getItem("role") == 4) {
        return "generalmanager";
      } else {
        return "";
      }
    },

    returnUrl() {
      if (localStorage.getItem("role") == 0) {
        return `${this.$store.state.url}/agent/accept_vacation`;
      } else if (localStorage.getItem("role") == 1) {
        return `${this.$store.state.url}/salesmanager/accept_vacation`;
      } else if (localStorage.getItem("role") == 12) {
        return `${this.$store.state.url}/employee/hr/accept_vacation`;
      } else if (localStorage.getItem("role") == 4) {
        return `${this.$store.state.url}/generalmanager/accept_vacation`;
      } else {
        return "";
      }
    },
  },
  methods: {
    async accept_vacation(status) {
      this.loading = true;
      let body = {
        user_type: this.userType,
        vacation_id: this.$route.params.id,
        accept: status,
      };
      if (localStorage.getItem("role") != 0) {
        body.notes = this.note;
      }
      await Axios.post(this.returnUrl, body, {
        headers: this.request_headers(),
      })
        .then((res) => {
          this.showPopup("success", "تم الحفظ بنجاح");
          this.$router.push({ name: "TheNotifc" });
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
          this.verifing = false;
        });
    },
    getVacationDetails() {
      let url = `https://api.alwsatarealestate.com/api/agent/vacation_details/${this.$route.params.id}`
      Axios.get(url, {headers: this.request_headers(),}).then(res => {
        this.payload.from = res.data.payload[0].from
        this.payload.to = res.data.payload[0].to
      })
    },
  },

  created() {
    this.$store.state.opened = -1;
    this.getVacationDetails();
  },
  watch: {
    "$route.params.id": {
      immediate: true, // This will trigger the watcher immediately when the component is created
      handler(newId, oldId) {
        if (newId !== oldId) {
          this.getVacationDetails();
        }
      },
    },
  },
};
</script>


<style lang="scss">
.errorMassage {
  color: red;
}

.notifs {
  .cont-dad {
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 6px;
    color: #2c2c2c;
    padding: 9px 20px 11px 7px;
    margin-top: 20px;

    .upper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .right-side {
        display: flex;
        align-items: center;

        span {
          font-size: 15px;
          font-weight: 500;
        }
      }
    }

    p {
      margin: 0;
      margin-top: 6px;
    }

    h2 {
      margin: 0;
      font-weight: 900;
      font-size: 18px;
    }
  }
}

@media (max-width: 600px) {
  .notifs {
    .cont-dad {
      h2 {
        font-size: 14px;
      }

      span {
        font-size: 11px !important;
      }

      p {
        font-size: 13px;
      }
    }
  }
}
</style>
