<template>
  <page-component style="position: relative" :loading="loading">
    <template #content>
      <v-sheet>
        <v-card elevation="0">
          <v-card-title class="ma-5 d-flex align-center justify-center"> <v-icon class="ml-5" @click="$router.push({name: 'request_details', params: {id: ticket_details.request.id}})">mdi-eye-outline</v-icon> <div>{{ticket_details.user.name}}</div> </v-card-title>
        </v-card>
      </v-sheet>
      <v-timeline :dense="$vuetify.breakpoint.smAndDown">
        <v-timeline-item fill-dot large icon="mdi-chevron-down-box" v-for="item in ticket_details.task_content_many" :key="item.date_of_content">
          <strong slot="opposite">{{item.date_of_content}}</strong>
          <v-card elevation="2">
            <v-card-title>
              <div>{{item.content}}</div>
            </v-card-title>
            <v-card-subtitle>
              <div>
                {{ticket_details.user.name}}
              </div>
            </v-card-subtitle>
          </v-card>
        </v-timeline-item>
      </v-timeline>
      <v-card elevation="0">
        <v-card-title class="ma-5 d-flex align-center justify-center">الرد علي ملاحظات المستلم داخل المهمة</v-card-title>
        <v-textarea v-model="task_content" class="mx-10 mb-5" outlined></v-textarea>
        <v-card-actions class="d-flex align-center justify-center"><v-btn :loading="reply_loading" color="primary" @click="reply_ticket_content" class="font-weight-bold">إرسال</v-btn></v-card-actions>
      </v-card>
    </template>
  </page-component>
</template>

<script>
import Axios from 'axios'
export default {
  props: ['agent'],
  data() {
    return {
      ticket_details: [],
      loading: false,
      reply_loading: false,
      task_content: '',
      complete_loading: false,
      incomplete_loading: false,
      close_loading: false
    }
  },
  created() {
    this.get_request_tickets()
    this.setBreadCrumbs(['الرئيسية', 'التذاكر', 'تفاصيل التذكرة'])
  },
  methods: {
    get_request_tickets() {
      this.loading = true
      Axios.get(`https://api.alwsatarealestate.com/api/agent/Task/Conversation/${this.$route.params.ticket}`, {headers: this.request_headers()}).then(response=> {
        this.ticket_details = response.data.payload
        this.sent_counter = this.sent_tickets.length
      }).catch(err=> {
        this.showPopup("error", err.response.data.message)
      }).finally(()=> {
        this.loading =false
      })
    },
    to_ticket_details(id) {
      this.$router.push({name: 'request_ticket', params: {ticket: id}})
    },
    reply_ticket_content() {
      let data = {task_content_id: this.$route.params.ticket, task_content: this.task_content}
      this.reply_loading = true
      Axios.post("https://api.alwsatarealestate.com/api/agent/Task/Conversation/AddContent", data, {headers: this.request_headers()}).then(()=> {
        this.showPopup("success", "تم الرد علي محتويات المهمة بنجاح")
        this.task_content = ""
        this.get_request_tickets()
      }).catch(err=> {
        this.showPopup("error", err.response.data.message)
      }).finally(()=> {
        this.reply_loading = false
      })
    },
    close_task() {
      this.close_loading = true
      Axios.put(`https://api.alwsatarealestate.com/api/agent/CancelTask/${this.$route.params.ticket}`, {headers: this.request_headers()}).then(()=> {
        this.showPopup("success", "تم إلغاء المهمة بنجاح")
        this.task_content = ""
        this.get_request_tickets()
      }).catch(err=> {
        this.showPopup("error", err.response.data.message)
      }).finally(()=> {
        this.close_loading = false
      })
    },
    mark_as_complete() {
      this.complete_loading = true
      Axios.put(`https://api.alwsatarealestate.com/api/agent/CompleteTask/${this.$route.params.ticket}`, {headers: this.request_headers()}).then(()=> {
        this.showPopup("success", "تم تحديد المهمة كمهمة مكتمله بنجاح")
        this.task_content = ""
        this.get_request_tickets()
      }).catch(err=> {
        this.showPopup("error", err.response.data.message)
      }).finally(()=> {
        this.complete_loading = false
      })
    },
    mark_as_incomplete() {
      this.incomplete_loading = true
      Axios.put(`https://api.alwsatarealestate.com/api/agent/InCompleteTask/${this.$route.params.ticket}`, {headers: this.request_headers()}).then(()=> {
        this.showPopup("success", "تم تحديد المهمة كمهمة غير مكتمله بنجاح")
        this.task_content = ""
        this.get_request_tickets()
      }).catch(err=> {
        this.showPopup("error", err.response.data.message)
      }).finally(()=> {
        this.incomplete_loading = false
      })
    }
  }
}
</script>
