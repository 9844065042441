<template>
  <div class="announcements">
    <v-btn
      fab
      :color="$vuetify.theme.themes.light.dialogActionBTN"
      @click="addNewRequestDialog = true"
      class="my-10"
      style="position: fixed; left: 20px; bottom: 30px; z-index: 1"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25.425"
        height="21.166"
        viewBox="0 0 25.425 21.166"
      >
        <g
          id="Icon_feather-user-plus"
          data-name="Icon feather-user-plus"
          transform="translate(1 1)"
        >
          <path
            id="Path_2554"
            data-name="Path 2554"
            d="M17.471,28.889v-2.13A4.259,4.259,0,0,0,13.212,22.5H5.759A4.259,4.259,0,0,0,1.5,26.759v2.13"
            transform="translate(-1.5 -9.723)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_2555"
            data-name="Path 2555"
            d="M15.268,8.759A4.259,4.259,0,1,1,11.009,4.5,4.259,4.259,0,0,1,15.268,8.759Z"
            transform="translate(-3.023 -4.5)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_2556"
            data-name="Path 2556"
            d="M30,12v6.389"
            transform="translate(-9.77 -6.676)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_2557"
            data-name="Path 2557"
            d="M31.889,16.5H25.5"
            transform="translate(-8.464 -7.982)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </g>
      </svg>
    </v-btn>
    <add-new-request v-model="addNewRequestDialog" width="350px" />
    <div style="margin-bottom: 40px; margin-top: 20px" class="table__view">
      <table-component
        :showSelect="true"
        itemKey="id"
        :selectedRows.sync="selected_announcements"
        @refresh="getAnnouncements"
        :headers="headers"
        :limit="10"
        :items="items"
        :pagination="pagination"
        :loading="loading"
        v-model="page"
      >
        <!-- eslint-disable -->
        <template v-slot:[`item.action`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#3f0e40" small depressed dark v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                router
                :to="{ name: 'AnnouncementContent', params: { id: item.id } }"
              >
                <v-list-item-icon class="ml-2"
                  ><v-icon>mdi-eye-outline</v-icon></v-list-item-icon
                >
                <v-list-item-title>فتح</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.has_attachment"
                @click="getAnnouncementContent(item.attachment)"
              >
                <v-list-item-icon class="ml-2"
                  ><v-icon>mdi-download</v-icon></v-list-item-icon
                >
                <v-list-item-title>تحميل المرفق</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </table-component>
    </div>
    <agent-chat />
  </div>
</template>

<script>
import Axios from "axios";

export default {
  name: "TheAnnouncements",
  data() {
    return {
      items: [],
      headers: [
        {
          text: "نص التعميمات",
          value: "content",
          width: "60%",
          align: "center",
        },
        { text: "تاريخ النهاية", value: "created_at", width: "31%", align: "center" },
        { text: "العمليات", value: "action", align: "center", width: "2%" },
      ],
      loading: false,
      page: 1,
      selected_announcements: [],
      addNewRequestDialog: false,
      pagination: {
        count: 0,
        limit: 0,
      },
    };
  },

  watch:{
    "$store.state.first_not_processed_task_id": {
      handler(val) {
        if (val > 0 && localStorage["role"] == "0") {
          this.$store.state.showAlertOfTasksDidntProccess = true;
          this.$router.push({ name: 'ticket_details', params: { ticket: this.$store.state.first_not_processed_task_id } });
        }
      },
      immediate: true 
    },
  },
  methods: {
    async getAnnouncements() {
      this.loading = true;
      this.page = 1;
      await Axios.get(
        "https://api.alwsatarealestate.com/api/employee/announcements/all",
        { headers: this.request_headers() }
      )
        .then((res) => {
          this.items = res.data.payload;
          this.items.forEach((el) => {
            let cont = el.content.split(" ");
            if (cont.length > 9) {
              el.content = cont.slice(0, 9).join(" ") + "...";
            }
          });
          this.pagination = {
            limit: 10,
            count: res.data.payload.count_of_received,
          };
          this.loading = false;
        })
        .catch((err) => console.log(err));
    },
    getAnnouncementContent(downloadLink) {
      const theLink = document.createElement("a");
      theLink.href = downloadLink;
      theLink.download = "announcement.pdf";
      document.documentElement.appendChild(theLink);
      theLink.click();
      theLink.remove();
    },
  },
  async created() {
    this.$store.state.opened = -1;
    await this.getAnnouncements();
  },
};
</script>
