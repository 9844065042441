<template>
  <div class="announcement-content">
    <div class="dad" v-if="!loading">
      <h3>تم رؤية التعميم من قبل:</h3>
      <div class="cont">
        <h4>محتوي التعميمات</h4>
        <p>{{ content.content }}</p>
        <div class="btns mt-9">
          <a
            :href="content.attachment"
            target="_blank"
            style="text-decoration: none"
          >
            <v-btn
              color="#28A745"
              dark
              style="font-size: 16px; font-weight: 300; margin-left: 5px"
            >
              <v-icon size="19" class="mt-1">mdi-eye</v-icon>
              فتح المرفق
            </v-btn>
          </a>
          <v-btn
            @click="downloadAttachment"
            v-if="content.attachment"
            color="#007BFF"
            dark
            style="font-size: 16px; font-weight: 300"
          >
            <v-icon size="19" class="mt-1">mdi-download</v-icon>
            تحميل المرفق
          </v-btn>
        </div>
      </div>
    </div>
    <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
      v-else
      style="
        height: 100px;
        position: fixed;
        width: 100px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      "
    ></v-progress-circular>
    <AgentChat />
  </div>
</template>

<script>
import Axios from "axios";
import AgentChat from "@/components/AgentChat/AgentChat.vue";

export default {
  name: "AnnouncementContent",
  components: {AgentChat},
  data() {
    return {
      loading: false,
      content: "",
    };
  },
  methods: {
    getAnnouncementContent() {
      const id = this.$route.params.id;
      this.loading = true;
      Axios.get(
        `https://api.alwsatarealestate.com/api/employee/announcements/show/${id}`,
        { headers: this.request_headers() }
      ).then((res) => {
        this.content = res.data.payload;
        this.loading = false;
      });
    },
    async downloadAttachment() {
      await fetch(this.content.attachment)
        .then((res) => res.blob())
        .then((file) => {
          const tempUrl = URL.createObjectURL(file);
          const theLink = document.createElement("a");
          theLink.href = tempUrl;
          theLink.download = "filename";
          document.documentElement.appendChild(theLink);
          theLink.click();
          theLink.remove();
          this.loading = false;
        })
        .catch(() => this.showPopup("error", "Attachment Not Found"));
    },
  },
  created() {
    this.$store.state.opened = -1
    this.getAnnouncementContent();
  },
};
</script>

<style lang="scss">
.announcement-content {
  .cont {
    margin-top: 50px;
    padding: 12px 12px 17px;
    background-color: #fff;
    border: 2px solid #e3e3e3;
    h4 {
      font-size: 19px;
      font-weight: 600;
      color: #000000ab;
    }
    p {
      font-weight: 300;
      font-size: 20px;
      color: #000000a6;
    }
  }
}
@media (max-width: 600px) {
  .announcement-content {
    .cont {
      h4 {
        font-size: 16px;
      }
      p {
        font-size: 15px;
      }
      button {
        font-size: 10px !important;
        .v-icon {
          font-size: 14px !important;
          margin-left: 5px;
        }
      }
    }
  }
}
</style>
